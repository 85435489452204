import angular from "angular";
import { BaseService } from "../filesfromccqbase/BaseService";
import { BroadcastService } from "../filesfromccqbase/BroadcastService";
import { ContractDTO } from "@js/oldmodels/DTOs/ContractDTO.cs.d";

export class ContractService extends BaseService<ContractDTO, number> {
  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/contract";
    this.$broadcastBusinessNameSingular = "Contract";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }

  convertDatesAndTimes(contract: ContractDTO) {
    if (contract.DateFrom) {
      contract.DateFrom = new Date(contract.DateFrom.toString());
    }
    if (contract.DateTo) {
      contract.DateTo = new Date(contract.DateTo.toString());
    }
    if (contract.DateTermination) {
      contract.DateTermination = new Date(contract.DateTermination.toString());
    }

    if (contract.Rates) {
      for (let i = 0; i < contract.Rates.length; i++) {
        let rate = contract.Rates[i];

        if (rate.DateFrom) {
          rate.DateFrom = new Date(rate.DateFrom.toString());
        }
        if (rate.DateTo) {
          rate.DateTo = new Date(rate.DateTo.toString());
        }

        if (rate.RateFromTime) {
          let rateFromTime: string[] = rate.RateFromTime.split(":");
          rate.RateFromTime = new Date();
          rate.RateFromTime.setHours(
            parseInt(rateFromTime[0]),
            parseInt(rateFromTime[1]),
            parseInt(rateFromTime[2]),
            0,
          );
        }

        if (rate.RateToTime) {
          let rateToTime: string[] = rate.RateToTime.split(":");
          rate.RateToTime = new Date();
          rate.RateToTime.setHours(
            parseInt(rateToTime[0]),
            parseInt(rateToTime[1]),
            parseInt(rateToTime[2]),
            0,
          );
        }
      }
    }

    if (contract.Charges) {
      for (let i = 0; i < contract.Charges.length; i++) {
        let charge = contract.Charges[i];

        if (charge.DateFrom) {
          charge.DateFrom = new Date(charge.DateFrom.toString());
        }
        if (charge.DateTo) {
          charge.DateTo = new Date(charge.DateTo.toString());
        }
      }
    }
  }

  fetchContracts(filter: string = ""): ng.IPromise<ContractDTO[]> {
    let defer = this.$q.defer<ContractDTO[]>();
    this.$http
      .get("/api/contract/state/" + filter)
      .then((response) => {
        let contracts: ContractDTO[] = response.data as ContractDTO[];
        for (let i = 0; i < contracts.length; i++) {
          this.convertDatesAndTimes(contracts[i]);
        }
        defer.resolve(contracts);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  fetchContractsForMeter(id: number): ng.IPromise<ContractDTO[]> {
    let defer = this.$q.defer<ContractDTO[]>();
    this.$http
      .get("/api/contract/meter/" + id.toString())
      .then((response) => {
        let contracts: ContractDTO[] = response.data as ContractDTO[];
        for (let i = 0; i < contracts.length; i++) {
          this.convertDatesAndTimes(contracts[i]);
        }
        defer.resolve(contracts);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  fetchContractsForMeterByDate(
    id: number,
    startDate: Date,
    endDate: Date,
  ): ng.IPromise<ContractDTO[]> {
    let defer = this.$q.defer<ContractDTO[]>();
    this.$http
      .get(
        "/api/contract/meterbydate/" +
          id.toString() +
          "?startDate=" +
          startDate.toISOString() +
          "&endDate=" +
          endDate.toISOString(),
      )
      .then((response) => {
        let contracts: ContractDTO[] = response.data as ContractDTO[];
        for (let i = 0; i < contracts.length; i++) {
          this.convertDatesAndTimes(contracts[i]);
        }
        defer.resolve(contracts);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  fetchCurrentContractForMeter(meterId: number): ng.IPromise<ContractDTO> {
    let defer = this.$q.defer<ContractDTO>();
    this.$http
      .get("/api/contract/state/current/" + meterId.toString())
      .then((response) => {
        this.convertDatesAndTimes(response.data as ContractDTO);
        defer.resolve(response.data as ContractDTO);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "contracts",
          "Unable to get the contract from the  Please try again shortly.",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  countExpiringContracts(days: number): ng.IPromise<number> {
    let defer = this.$q.defer<number>();
    this.$http
      .get("/api/contract/count/expiring?days=" + days.toString())
      .then((response) => {
        defer.resolve(response.data as number);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  validateContractDates(dto: ContractDTO): ng.IPromise<ContractDTO> {
    let defer = this.$q.defer<ContractDTO>();
    this.$http
      .get("/api/contract/validatedates", {
        params: {
          contractId: dto.Id ? dto.Id : 0,
          meterId: dto.AssociatedMeterId,
          contractType: dto.ContractType,
          dateFrom: dto.DateFrom,
          dateTo: dto.DateTo,
          customName: dto.ContractTypeCustomName,
        },
      })
      .then((response) => {
        defer.resolve(response.data as ContractDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  protected processDtoBeforeAddUpdate(dtos: ContractDTO) {
    let tmpContract = angular.copy(dtos);
    if (tmpContract.DateTo) {
      tmpContract.DateTermination = new Date(tmpContract.DateTo.toString());
    }
    // Handle addresses.
    if (tmpContract.LegalEntity && tmpContract.LegalEntity.Address) {
      tmpContract.LegalEntity.Address = JSON.stringify(
        tmpContract.LegalEntity.Address,
      );
    }
    // Convert Rates from Dates to TimeSpans and set dates to match contract.
    if (tmpContract.Rates) {
      for (let i = 0; i < tmpContract.Rates.length; i++) {
        let rate = tmpContract.Rates[i];
        if (rate.RateFromTime != null && rate.RateToTime != null) {
          // rate.RateFromTime = moment.duration((rate.RateFromTime as Date).getHours() + ":" + (rate.RateFromTime as Date).getMinutes());
          rate.RateFromTime =
            "0." +
            (rate.RateFromTime as Date).getHours() +
            ":" +
            (rate.RateFromTime as Date).getMinutes() +
            ":00.0000";
          rate.RateToTime =
            "0." +
            (rate.RateToTime as Date).getHours() +
            ":" +
            (rate.RateToTime as Date).getMinutes() +
            ":00.0000";
        }
      }
    }
    return tmpContract;
  }

  addUpdateReturnByonlyId(dtos: ContractDTO): ng.IPromise<number> {
    let defer = this.$q.defer<number>();

    dtos = this.processDtoBeforeAddUpdate(dtos);

    this.$http
      .post(
        this.$baseAddress + "/addupdatereturnbyonlyid",
        JSON.stringify(dtos, this.jsonPropertyReplacer),
      )
      .then((response) => {
        defer.resolve(response.data as number);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          this.$broadcastScreen,
          "Unable to add update " +
            this.$broadcastBusinessNamePlural +
            " from the  Please try again shortly.",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  addUpdateNew(dtos: ContractDTO): ng.IPromise<ContractDTO> {
    let defer = this.$q.defer<ContractDTO>();

    dtos = this.processDtoBeforeAddUpdate(dtos);

    this.$http
      .post(this.$baseAddress + "/addupdatenew", JSON.stringify(dtos))
      .then((response) => {
        defer.resolve(response.data as ContractDTO);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          this.$broadcastScreen,
          "Unable to add update " +
            this.$broadcastBusinessNamePlural +
            " from the  Please try again shortly.",
        );
        defer.reject(response);
      });
    return defer.promise;
  }
}
