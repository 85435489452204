import { useCCQAuth } from "@ccq/auth-lib";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { FC } from "react";

interface Props {
  authProvider: string;
}

const LoginButton: FC<Props> = ({ authProvider }) => {
  const { signIn } = useCCQAuth();

  return (
    <Box padding={1}>
      <Button
        variant="contained"
        onClick={() => {
          localStorage.setItem("authProvider", authProvider);
          signIn(false);
        }}
      >
        Sign In with {authProvider} account
      </Button>
    </Box>
  );
};

export default LoginButton;
