import angular from "angular";
import { BaseService } from "../filesfromccqbase/BaseService";
import { BroadcastService } from "../filesfromccqbase/BroadcastService";
import { ContractOfferDTO } from "@js/oldmodels/DTOs/ContractOfferDTO.cs.d";

export class ContractOffersService extends BaseService<
  ContractOfferDTO,
  number
> {
  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/contractoffer";
    this.$broadcastBusinessNameSingular = "Contract Offer";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }

  addUpdate(dtos: ContractOfferDTO): ng.IPromise<ContractOfferDTO> {
    let tmpContractOffer = angular.copy(dtos);
    if (tmpContractOffer.QuoteDate) {
      tmpContractOffer.QuoteDate = new Date(
        tmpContractOffer.QuoteDate.toString(),
      );
    }
    if (tmpContractOffer.QuoteExpiryDate) {
      tmpContractOffer.QuoteExpiryDate = new Date(
        tmpContractOffer.QuoteExpiryDate.toString(),
      );
    }
    if (tmpContractOffer.AcceptedDateTime) {
      tmpContractOffer.AcceptedDateTime = new Date(
        tmpContractOffer.AcceptedDateTime.toString(),
      );
    }

    // Convert Rates from Dates to TimeSpans and set dates to match contractoffer.
    if (tmpContractOffer.Rates) {
      for (let i = 0; i < tmpContractOffer.Rates.length; i++) {
        let rate = tmpContractOffer.Rates[i];
        // rate.RateFromTime = moment.duration((rate.RateFromTime as Date).getHours() + ":" + (rate.RateFromTime as Date).getMinutes());
        if (rate.RateFromTime) {
          rate.RateFromTime =
            "0." +
            (rate.RateFromTime as Date).getHours() +
            ":" +
            (rate.RateFromTime as Date).getMinutes() +
            ":00.0000";
        }
        if (rate.RateToTime) {
          rate.RateToTime =
            "0." +
            (rate.RateToTime as Date).getHours() +
            ":" +
            (rate.RateToTime as Date).getMinutes() +
            ":00.0000";
        }
      }
    }
    let defer = this.$q.defer<ContractOfferDTO>();

    tmpContractOffer = this.processDtoBeforeAddUpdate(tmpContractOffer);

    this.$http
      .post(
        this.$baseAddress + "/addupdatenew",
        JSON.stringify(tmpContractOffer),
      )
      .then((response) => {
        defer.resolve(response.data as ContractOfferDTO);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          this.$broadcastScreen,
          "Unable to add update " +
            this.$broadcastBusinessNamePlural +
            " from the  Please try again shortly.",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  acceptOffer(id: number): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.$http
      .get("/api/tender/offer/accept/" + id)
      .then((response) => {
        defer.resolve(true);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "tenders",
          "Unable to accept the offer right now. Please try again shortly. " +
            response.data.Message,
        );
        defer.reject(false);
      });
    return defer.promise;
  }

  declineOffer(id: number): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.$http
      .get("/api/tender/offer/decline/" + id)
      .then((response) => {
        defer.resolve(true);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "tenders",
          "Unable to decline the offer right now. Please try again shortly.",
        );
        defer.reject(false);
      });
    return defer.promise;
  }

  makeliveOffer(id: number, commencementDate: Date): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.$http
      .get(
        "/api/tender/offer/makelive/" +
          id +
          "?commencementDate=" +
          commencementDate.toISOString(),
      )
      .then((response) => {
        defer.resolve(true);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "tenders",
          "Unable to make the offer live right now. Please try again shortly.",
        );
        defer.reject(false);
      });
    return defer.promise;
  }
}
