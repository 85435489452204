import { APIContext } from "@react/contexts/APIContext";
import { useContext } from "react";

export const useAPIs = () => {
  const apis = useContext(APIContext);
  if (Object.values(apis).length == 0) {
    throw new Error("Cannot access CCQ APIs outside an APIProvider");
  }
  return apis;
};
