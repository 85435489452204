import { UBTaskService } from "../services/UBTaskService";
import { CustomerService } from "../services/CustomerService";
import { CustomerDTO } from "@js/oldmodels/DTOs/CustomerDTO.cs.d";
import { UBTaskDTO } from "@js/oldmodels/DTOs/UBTaskDTO.cs.d";

export class UBTaskController {
  selectedSection: string;

  objects: UBTaskDTO[];
  selectedObject: UBTaskDTO;

  customers: CustomerDTO[];

  fetchingObjects: boolean;
  fetchingObject: boolean;

  //addingSubobject: boolean;

  //subobjects: SubobjectDTO[];
  //selectedSubobject: SubobjectDTO;

  //fetchingSubobjects: boolean;
  //fetchingSubobject: boolean;

  ubtaskForm: ng.IFormController;
  //sububtaskForm: ng.IFormController;

  static $inject = [
    "$routeParams",
    "UBTaskService",
    "CustomerService",
    //'SubobjectService',
  ];
  //private $subobjectservice: App.Services.SubobjectService
  constructor(
    private $routeParams: ng.route.IRouteParamsService,
    private $ubtaskservice: UBTaskService,
    private $customerService: CustomerService,
  ) {
    //collect customers
    this.$customerService.ListNew().then((response) => {
      this.customers = response;
    });
    this.updateObjects();
  }

  updateObjects() {
    this.fetchingObjects = true;

    // Fetch all objects.
    this.$ubtaskservice
      .fetchAll()
      .then((response) => {
        this.objects = response;
        this.preSelectedItem();
      })
      .finally(() => {
        this.fetchingObjects = false;
      });
  }

  private preSelectedItem() {
    if (this.$routeParams.Id) {
      let selectedObject = this.objects.find((value, index) => {
        return value.Id === parseInt(this.$routeParams.objectId);
      });

      this.selectObject(selectedObject);
    }
  }

  selectObject(object: UBTaskDTO) {
    this.selectedObject = object;

    //this.fetchingSubobjects = true;
    //this.$subobjectservice.fetchSubobjectsForObject(object.Id).then((response) => {
    //    this.subobjects = response;
    //    this.fetchingSubobjects = false;
    //}).catch((response) => {
    //    this.fetchingSubobjects = false;
    //});

    this.selectedSection = "details";
  }

  createObject() {
    this.selectedSection = "details";
    this.selectedObject = {} as UBTaskDTO;
  }

  save() {
    this.$ubtaskservice.addUpdateTask(this.selectedObject).then((response) => {
      this.selectedObject.Id = response;
      if (!this.objects) {
        this.objects = [];
      }

      let matches: UBTaskDTO[] = this.objects.filter((value, index) => {
        return value.Id == response;
      });

      if (!matches || matches.length === 0) {
        this.objects.push(this.selectedObject);
      }

      this.ubtaskForm.$setPristine();
      this.updateObjects();
    });
  }

  delete() {
    this.$ubtaskservice.delete(this.selectedObject.Id).then((response) => {
      if (response) {
        this.objects.splice(this.objects.indexOf(this.selectedObject), 1);
        this.ubtaskForm.$setPristine();
        delete this.selectedObject;
      }
    });
  }

  //addSubobject() {
  //    this.selectedSubobject = {
  //        AssociatedObjectId: this.selectedObject.Id,
  //        AssociatedObjectName: this.selectedObject.Name
  //    } as SubobjectDTO;
  //}

  //removeSubobject(subobject: SubobjectDTO) {
  //    this.$subobjectservice.deleteSubobject(subobject.Id).then((response) => {
  //        this.subobjects.splice(this.subobjects.indexOf(this.selectedSubobject), 1);
  //        delete this.selectedSubobject;
  //    });
  //}

  //cancelUpdateSubobject() {
  //    delete this.selectedSubobject;
  //}

  //updateSubobject(subobject: SubobjectDTO) {
  //    this.$subobjectservice.addUpdateSubobject(subobject).then((response) => {
  //        if (this.subobjects.indexOf(subobject) === -1) {
  //            this.subobjects.push(subobject);
  //        }
  //        delete this.selectedSubobject;
  //    }).catch((response) => {
  //        delete this.selectedSubobject;
  //    });

  //}
}
