import { AgencyDTO } from "@js/oldmodels/DTOs/AgencyDTO.cs.d";
import { ApplicationUserDTO } from "@js/oldmodels/DTOs/ApplicationUserDTO.cs.d";
import { CustomerDTO } from "@js/oldmodels/DTOs/CustomerDTO.cs.d";
import { LegalEntityDTO } from "@js/oldmodels/DTOs/LegalEntityDTO.cs.d";
import { UserRoleEnum } from "@js/oldmodels/Enums/UserRoleEnum.cs.d";
import { AgencyService } from "../services/AgencyService";
import { AuthService } from "../services/AuthService";
import { CustomerService } from "../services/CustomerService";
import { UserService } from "../services/UserService";

export class ListAgenciesController {
  selectedSection: "details" | "logins" | "customers" = "details";

  agencies: AgencyDTO[] = [];
  selectedAgency: AgencyDTO | null = null;
  selectedParentAgency: AgencyDTO | null = null;

  fetchingAgencies: boolean = false;
  fetchingAgency: boolean = false;

  addingLogin: boolean = false;
  addingConsultant: boolean = false;
  addingClient: boolean = false;

  logins: ApplicationUserDTO[] = [];
  selectedLogin: ApplicationUserDTO | null = null;

  user: ApplicationUserDTO | null = null;

  fetchingLogins: boolean = false;
  fetchingLogin: boolean = false;

  customers: CustomerDTO[] = [];
  selectedCustomer: CustomerDTO | null = null;

  fetchingCustomers: boolean = false;
  fetchingCustomer: boolean = false;

  agencyform: ng.IFormController;
  loginform: ng.IFormController;
  customerform: ng.IFormController;
  subagencyfilter: any;

  static $inject = [
    "AgencyService",
    "UserService",
    "CustomerService",
    "AuthService",
  ];

  constructor(
    private $agencyservice: AgencyService,
    private $userservice: UserService,
    private $customerservice: CustomerService,
    private $auth: AuthService,
  ) {
    this.$auth.getProfile().then((response) => {
      this.user = response;
    });
    this.fetchingAgencies = true;
    this.$agencyservice
      .fetchAll()
      .then((response) => {
        this.agencies = response;
        this.fetchingAgencies = false;
      })
      .catch((e: any) => {
        this.fetchingAgencies = false;
        console.error(e);
      });
    //setup filters as has to take place at this stage
    //this filter verify the sub agency is not itself and doesn't have a parent
    this.subagencyfilter = (subagency: AgencyDTO) => {
      if (subagency.Id === this.selectedAgency?.Id) {
        return false;
      }
      //filter out any that are not super agents
      if (!subagency.SuperAgent) {
        return false;
      }
      if (subagency.AgencyParentId !== null) {
        return false;
      }
      return true;
    };
  }

  selectAgency(agency: AgencyDTO) {
    this.selectedAgency = agency;
    //select the parent aganecy on load
    var templst: AgencyDTO[] = this.agencies.filter(
      (x) => x.Id === agency.AgencyParentId,
    );
    if (templst.length === 1) {
      this.selectedParentAgency = templst[0];
    } else {
      this.selectedParentAgency = null;
    }

    this.fetchingLogins = true;
    this.$userservice
      .fetchLoginsForAgency(agency.Id)
      .then((response) => {
        this.logins = response;
        this.fetchingLogins = false;
      })
      .catch((e: any) => {
        this.fetchingLogins = false;
        console.error(e);
      });

    this.fetchingCustomers = true;
    this.$customerservice
      .fetchCustomersForAgency(agency.Id)
      .then((response) => {
        this.customers = response;
        this.fetchingCustomers = false;
      })
      .catch((e: any) => {
        this.fetchingCustomers = false;
        console.error(e);
      });
  }

  hideParentAgency(): boolean {
    const associatedAgency: AgencyDTO | null = this.getUsersAssociatedAgency();

    return (
      (this.selectedAgency?.SuperAgent && associatedAgency?.SuperAgent) ||
      this.user?.UserType !== UserRoleEnum.GlobalSystemAdmin
    );
  }

  //disabled in all cases except when global admin for now and not a child agency
  enableParentAgency(): boolean {
    return (
      this.user?.UserType === UserRoleEnum.GlobalSystemAdmin &&
      !this.selectedParentAgency
    );
  }

  getUsersAssociatedAgency(): AgencyDTO | null {
    if (this.user?.AssociatedAgencyId !== null) {
      var tempAssociatedAgency: AgencyDTO[] = this.agencies.filter(
        (x) => x.Id === this.user?.AssociatedAgencyId,
      );
      if (tempAssociatedAgency.length === 1) {
        return tempAssociatedAgency[0];
      }
    }

    return null;
  }

  createAgency() {
    this.selectedSection = "details";
    this.selectedAgency = {} as AgencyDTO;
    this.selectedParentAgency = null;
    //if login as an associated agency that is a super ageency then assign all created agencies to that agent
    var associatedAgency: AgencyDTO | null = this.getUsersAssociatedAgency();

    if (associatedAgency && associatedAgency.SuperAgent) {
      this.selectedParentAgency = associatedAgency;
      this.selectedAgency.AgencyParentId = this.selectedParentAgency.Id;
    }

    this.logins = [];
    this.customers = [];
  }

  cancel() {
    if (!this.selectedAgency?.Id) {
      this.selectedAgency = null;
      this.agencyform.$setPristine();
      return;
    }

    this.fetchingAgency = true;
    this.$agencyservice.fetch(this.selectedAgency.Id).then((response) => {
      this.selectedAgency = response;
      this.fetchingAgency = false;
      this.agencyform.$setPristine();
      this.agencies.filter((agency) => agency.Id === response.Id)[0].Name =
        response.Name; //reset name in the list also incase renamed on screen then cancel
    });
  }

  save(agencyToSave: AgencyDTO) {
    if (agencyToSave === null) {
      console.error("The agency to save is null");
      return;
    }

    //update the agency parent
    if (this.selectedParentAgency) {
      agencyToSave.AgencyParentId = this.selectedParentAgency.Id;
    }

    this.$agencyservice
      .addUpdate(agencyToSave)
      .then((response) => {
        agencyToSave.Id = response.Id;

        if (!this.agencies) {
          this.agencies = [];
        }

        const isExistingAgency: boolean = this.agencies.some(
          (value) => value.Id === agencyToSave.Id,
        );

        if (!isExistingAgency) {
          this.agencies.push(agencyToSave);
        }

        this.agencyform.$setPristine();
      })
      .catch((e: any) => {
        this.agencyform.$setPristine();
        console.error(e);
      });
  }

  delete(idToDelete: number) {
    if (idToDelete === undefined) {
      this.agencyform.$setPristine();
      this.selectedAgency = null;
      return;
    }

    this.$agencyservice
      .markasdeleted(idToDelete)
      .then((response) => {
        if (response) {
          this.agencies = this.agencies.filter(
            (value) => value.Id !== idToDelete,
          );
          this.agencyform.$setPristine();
          this.selectedAgency = null;
        }
      })
      .catch((e: any) => {
        this.agencyform.$setPristine();
        this.selectedAgency = null;
        console.error(e);
      });
  }

  // #region Logins
  addLogin() {
    if (this.selectedAgency === null) {
      console.error("The selected agency is null");
      return;
    }

    this.selectedLogin = {
      AssociatedAgencyId: this.selectedAgency.Id,
      AssociatedAgencyName: this.selectedAgency.Name,
      Roles: [] as string[],
      UserType: UserRoleEnum.AgencyUser,
    } as ApplicationUserDTO;
  }

  removeLogin(idToRemove: number) {
    if (idToRemove === undefined) {
      return;
    }

    this.$userservice
      .markasdeleted(idToRemove)
      .then((response) => {
        if (response) {
          this.logins = this.logins.filter((value) => value.Id !== idToRemove);
          this.selectedLogin = null;
        }
      })
      .catch((e: any) => {
        this.selectedLogin = null;
        console.error(e);
      });
  }

  cancelUpdateLogin() {
    this.selectedLogin = null;
  }

  updateLogin(login: ApplicationUserDTO) {
    this.$userservice
      .addUpdate(login)
      .then((response) => {
        login.OrigUserName = response.OrigUserName;
        login.Id = response.Id;
        if (this.logins.indexOf(login) === -1) {
          this.logins.push(login);
        }
        this.selectedLogin = null;
      })
      .catch((e: any) => {
        this.selectedLogin = null;
        console.error(e);
      });
  }

  // #endregion

  // #region Customers
  addCustomer() {
    if (this.selectedAgency === null) {
      console.error("The selected agency is null");
      return;
    }

    this.selectedCustomer = {
      CurrentAgencyId: this.selectedAgency.Id,
      CurrentAgencyName: this.selectedAgency.Name,
      LegalEntity: {} as LegalEntityDTO,
    } as CustomerDTO;
  }

  removeCustomer(idToRemove: number) {
    if (idToRemove === undefined) {
      console.log("The id of the customer to remove is undefined");
      return;
    }

    this.$customerservice
      .markasdeleted(idToRemove)
      .then(() => {
        this.customers = this.customers.filter(
          (value) => value.Id !== idToRemove,
        );

        this.selectedCustomer = null;
      })
      .catch((e: any) => {
        this.selectedCustomer = null;
        console.error(e);
      });
  }

  cancelUpdateCustomer() {
    this.selectedCustomer = null;
  }

  updateCustomer(customer: CustomerDTO) {
    this.$customerservice
      .addUpdate(customer)
      .then(() => {
        if (this.customers.indexOf(customer) === -1) {
          this.customers.push(customer);
        }
        this.selectedCustomer = null;
      })
      .catch((e: any) => {
        this.selectedCustomer = null;
        console.error(e);
      });
  }

  // #endregion
}
