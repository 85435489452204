import { IBillRelatedDTOStub } from "@js/interfaces/IBillRelatedDTOStub.cs.d";
import { BillChargeDTO } from "@js/oldmodels/DTOs/BillChargeDTO.cs.d";
import { BillDTO } from "@js/oldmodels/DTOs/BillDTO.cs.d";
import { BillRateDTO } from "@js/oldmodels/DTOs/BillRateDTO.cs.d";
import { ContractChargeDTO } from "@js/oldmodels/DTOs/ContractChargeDTO.cs.d";
import { ContractDTO } from "@js/oldmodels/DTOs/ContractDTO.cs.d";
import { ContractRateDTO } from "@js/oldmodels/DTOs/ContractRateDTO.cs.d";
import { CreditNoteDTO } from "@js/oldmodels/DTOs/CreditNoteDTO.cs.d";
import { MeterDTO } from "@js/oldmodels/DTOs/MeterDTO.cs.d";
import { SiteDTO } from "@js/oldmodels/DTOs/SiteDTO.cs.d";
import {
  ChargeUsageDTO,
  RateUsageDTO,
  UsageReportDTO,
} from "@js/oldmodels/DTOs/UsageReportDTO.cs.d";
import { RateUnitEnum } from "@js/oldmodels/Enums/RateUnitEnum.cs.d";
import { AuthService } from "../services/AuthService";
import { BillValidationService } from "../services/BillValidationService";
import { ContractService } from "../services/ContractService";
import { CreditNoteService } from "../services/CreditNoteService";
import { MeterService } from "../services/MeterService";
import { ReportService } from "../services/ReportService";
import { SearchService } from "../services/SearchService";
import { SiteService } from "../services/SiteService";

export class ListBillsController {
  selectedSection: string;

  billEditMode: boolean = false;

  bills: IBillRelatedDTOStub[];
  selectedBill: BillDTO;

  fetchingBills: boolean;
  fetchingCreditNotes: boolean;
  fetchingBill: boolean;

  page: number = 0;
  performingAction: boolean;

  meters: MeterDTO[];
  metersSorted: MeterDTO[];
  selectedMeter: MeterDTO;
  selectedMeterForFilter: string;
  fetchingMeters: boolean;

  sites: SiteDTO[];
  site: SiteDTO;

  standingChargeValue: number;
  cclExemptionCertified: boolean;

  public Contract: ContractDTO;
  public Bill: BillDTO;
  public report: UsageReportDTO;
  conflictingBills: number = 0;
  billTotalMatches: boolean = false;

  billform: ng.IFormController;

  isCreditNote: boolean = false;

  static $inject = [
    "$rootScope",
    "$location",
    "$routeParams",
    "AuthService",
    "MeterService",
    "SearchService",
    "ContractService",
    "BillValidationService",
    "ReportService",
    "CreditNoteService",
  ];

  constructor(
    private $rootScope: ng.IRootScopeService,
    private $location: ng.ILocationService,
    private $routeParams: ng.route.IRouteParamsService,
    private $auth: AuthService,
    private $meters: MeterService,
    private $search: SearchService,
    private $contractservice: ContractService,
    private $billservice: BillValidationService,
    private $reportservice: ReportService,
    private $creditnoteservice: CreditNoteService,
    private $siteservice: SiteService,
  ) {
    this.bills = [];
    this.updateMeters();
    this.updateBills();
    this.updateCreditNotes();

    this.$rootScope.$on(
      "selected-customer-updated",
      (event: ng.IAngularEvent) => {
        this.bills = [];
        this.updateBills();
        this.updateCreditNotes();
        this.updateMeters();
      },
    );

    if (this.$location.path() === "/bills/add") {
      this.createBill();
    }
  }

  updateMeters() {
    this.fetchingMeters = true;
    if ((this.$rootScope as any).selectedCustomer) {
      this.$meters
        .fetchMetersForCustomer((this.$rootScope as any).selectedCustomer.Id)
        .then((response) => {
          this.meters = response;
          this.metersSorted = response.sort();
        })
        .finally(() => {
          this.fetchingMeters = false;
        });
    } else {
      this.$meters
        .ListNew()
        .then((response) => {
          this.meters = response;
          this.metersSorted = response.sort(this.compareMeters);
        })
        .finally(() => {
          this.fetchingMeters = false;
        });
    }
  }

  compareMeters(a, b) {
    let comparison = 0;
    if (
      a.AssociatedSiteName.toUpperCase() > b.AssociatedSiteName.toUpperCase()
    ) {
      comparison = 1;
    } else {
      comparison = -1;
    }
    return comparison;
  }

  updateFilters() {
    this.bills = [];
    this.updateBills();
    this.updateCreditNotes();
  }

  updateBills() {
    this.fetchingBills = true;
    if ((this.$rootScope as any).selectedCustomer) {
      this.$billservice
        .fetchBillsForCustomer((this.$rootScope as any).selectedCustomer.Id)
        .then((response) => {
          if (this.selectedMeterForFilter) {
            this.bills = this.bills.concat(
              response.filter((value, index) => {
                return value.MeterId === parseInt(this.selectedMeterForFilter);
              }),
            );
          } else {
            this.bills = this.bills.concat(response);
          }
          this.fetchingBills = false;

          // If we have a bill id passed in via the url, let's select it.
          if (
            this.$routeParams.billId &&
            this.$routeParams.billId.toLowerCase() !== "add"
          ) {
            this.selectBill(
              this.bills.find((value, index) => {
                return value.Id === parseInt(this.$routeParams.billId);
              }) as BillDTO,
            );
          }
        })
        .finally(() => {
          this.fetchingBills = false;
        });
    } else {
      this.$billservice
        .fetchAll()
        .then((response) => {
          if (this.selectedMeterForFilter) {
            this.bills = this.bills.concat(
              response.filter((value, index) => {
                return value.MeterId === parseInt(this.selectedMeterForFilter);
              }),
            );
          } else {
            this.bills = this.bills.concat(response);
          }

          this.fetchingBills = false;

          // If we have a bill id passed in via the url, let's select it.
          if (
            this.$routeParams.billId &&
            this.$routeParams.billId.toLowerCase() !== "add"
          ) {
            this.selectBill(
              this.bills.find((value, index) => {
                return value.Id === parseInt(this.$routeParams.billId);
              }) as BillDTO,
            );
            this.billEditMode = false;
          }
        })
        .finally(() => {
          this.fetchingBills = false;
        });
    }
  }

  updateCreditNotes() {
    this.fetchingCreditNotes = true;
    if ((this.$rootScope as any).selectedCustomer) {
      this.$creditnoteservice
        .fetchCreditNotesForCustomer(
          (this.$rootScope as any).selectedCustomer.Id,
        )
        .then((response) => {
          if (this.selectedMeterForFilter) {
            this.bills = this.bills.concat(
              response.filter((value, index) => {
                return value.MeterId === parseInt(this.selectedMeterForFilter);
              }),
            );
          } else {
            this.bills = this.bills.concat(response);
          }
          this.fetchingCreditNotes = false;
        })
        .finally(() => {
          this.fetchingCreditNotes = false;
        });
    } else {
      this.$creditnoteservice
        .fetchAll()
        .then((response) => {
          if (this.selectedMeterForFilter) {
            this.bills = this.bills.concat(
              response.filter((value, index) => {
                return value.MeterId === parseInt(this.selectedMeterForFilter);
              }),
            );
          } else {
            this.bills = this.bills.concat(response);
          }

          this.fetchingCreditNotes = false;
        })
        .finally(() => {
          this.fetchingCreditNotes = false;
        });
    }
  }

  resetBill() {
    this.Bill = {
      Rates: [] as BillRateDTO[],
      Charges: [] as BillChargeDTO[],
      StandingChargeAmount: 0,
      BillDate: new Date(),
      DateFrom: new Date(),
      DateTo: new Date().addDays(30),
      TotalAmount: 0,
    } as BillDTO;

    this.selectedBill = this.Bill;

    delete this.selectedMeter;

    this.standingChargeValue = 0;
    this.cclExemptionCertified = false;
    this.conflictingBills = 0;
  }

  selectBill(billorcreditnote: IBillRelatedDTOStub) {
    this.page = 0;
    this.billEditMode = false;
    if (billorcreditnote) {
      if (billorcreditnote.TotalAmount >= 0) {
        this.isCreditNote = false;
        this.$billservice.fetch(billorcreditnote.Id).then((billResponse) => {
          this.selectedBill = billResponse;
          this.Bill = billResponse;
          this.Bill.DateFrom = new Date(this.Bill.DateFrom);
          this.Bill.DateTo = new Date(this.Bill.DateTo);
          this.Bill.BillDate = new Date(this.Bill.BillDate);

          this.selectedSection = "validation";

          this.$contractservice
            .fetchContractsForMeterByDate(
              billorcreditnote.MeterId,
              billorcreditnote.DateFrom,
              billorcreditnote.DateTo,
            )
            .then((response) => {
              this.Contract = response[0];
            });

          this.$reportservice
            .fetchUsageReport(
              [billorcreditnote.MeterId],
              billorcreditnote.DateFrom,
              billorcreditnote.DateTo,
              "Month",
              false,
            )
            .then((response) => {
              this.report = response;
            });

          this.$billservice
            .countOtherBillsByDate(this.Bill)
            .then((response) => {
              this.conflictingBills = response;
            });

          if (this.Bill && this.report) {
            this.billTotalMatches = this.areEqual(
              this.report.TotalCost,
              this.Bill.TotalAmount,
              0,
            );
          } else {
            this.billTotalMatches = false;
          }

          this.$meters.fetchNew(billResponse.MeterId).then((meter) => {
            this.selectedMeter = meter;
            this.selectedBill.AssociatedSiteName =
              this.selectedMeter.AssociatedSiteName;
            this.selectedBill.AssociatedCustomerName =
              this.selectedMeter.AssociatedCustomerName;
            this.selectedBill.MeterName = this.selectedMeter.Name;
          });
        });
      } else {
        this.isCreditNote = true;
        this.$creditnoteservice
          .fetch(billorcreditnote.Id)
          .then((creditNoteResponse) => {
            // This isn't particularly clean.
            this.selectedBill = creditNoteResponse as any;
            this.Bill = creditNoteResponse as any;
            this.Bill.DateFrom = new Date(this.Bill.DateFrom);
            this.Bill.DateTo = new Date(this.Bill.DateTo);
            this.Bill.BillDate = new Date(this.Bill.BillDate);

            this.selectedSection = "details";

            this.$meters.fetchNew(creditNoteResponse.MeterId).then((meter) => {
              this.selectedMeter = meter;
              this.selectedBill.AssociatedSiteName =
                this.selectedMeter.AssociatedSiteName;
              this.selectedBill.AssociatedCustomerName =
                this.selectedMeter.AssociatedCustomerName;
              this.selectedBill.MeterName = this.selectedMeter.Name;
            });

            //this.selectedMeter = creditNoteResponse.Meter;
          });
      }
    }
  }

  createBill() {
    this.page = 0;
    this.selectedSection = "details";
    this.resetBill();
    this.billEditMode = true;
    this.isCreditNote = false;
  }

  cancel() {
    if (!this.selectedBill.Id) {
      delete this.selectedBill;
      this.billform.$setPristine();
      return;
    }

    this.fetchingBill = true;
    this.$billservice.fetch(this.selectedBill.Id).then((response) => {
      //this.selectedBill = response;

      this.selectBill(response);

      this.fetchingBill = false;
      this.billform.$setPristine();
    });
  }

  totalChanged() {
    // We can't change an existing Bill or Credit Note into something else.
    if (this.Bill.Id) {
      return;
    }

    if (this.Bill.TotalAmount < 0) {
      this.isCreditNote = true;
    } else {
      this.isCreditNote = false;
    }
  }

  save() {
    this.performingAction = true;
    this.selectedBill.MeterId = this.selectedMeter.Id;
    this.selectedBill.AssociatedCustomerId =
      this.selectedMeter.AssociatedCustomerId;

    this.$billservice
      .addUpdate(this.selectedBill)
      .then((response) => {
        this.selectedBill.Id = response.Id;
        if (!this.bills) {
          this.bills = [];
        }

        let matches: IBillRelatedDTOStub[] = this.bills.filter(
          (value, index) => {
            return value.Id == response.Id;
          },
        );

        if (!matches || matches.length === 0) {
          this.bills.push(this.selectedBill);
        }

        this.billform.$setPristine();
        this.selectBill(this.selectedBill);
      })
      .finally(() => {
        this.performingAction = false;
      });
  }

  saveCreditNote() {
    this.performingAction = true;

    let creditNote: CreditNoteDTO = {
      Id: this.selectedBill.Id,
      BillDate: this.selectedBill.BillDate,
      DateFrom: this.selectedBill.DateFrom,
      InvoiceNumber: this.selectedBill.InvoiceNumber,
      AccountNumber: this.selectedBill.AccountNumber,
      DateTo: this.selectedBill.DateTo,
      TotalAmount:
        this.selectedBill.TotalAmount < 0
          ? this.selectedBill.TotalAmount
          : -this.selectedBill.TotalAmount,
      MeterId: this.selectedBill.MeterId,
      AssociatedCustomerId: this.selectedMeter.AssociatedCustomerId,
      AssociatedCustomerName: this.selectedMeter.AssociatedCustomerName,
      AssociatedSiteName: this.selectedMeter.AssociatedSiteName,

      //Meter: this.selectedMeter
    } as CreditNoteDTO;

    this.$creditnoteservice
      .addUpdate(creditNote)
      .then((response) => {
        creditNote.Id = response.Id;
        if (!this.bills) {
          this.bills = [];
        }

        let matches: IBillRelatedDTOStub[] = this.bills.filter(
          (value, index) => {
            return value.Id == response.Id;
          },
        );

        if (!matches || matches.length === 0) {
          this.bills.push(creditNote);
        }

        this.billform.$setPristine();
      })
      .finally(() => {
        this.performingAction = false;
      });
  }

  delete() {
    this.performingAction = true;
    this.$billservice
      .markasdeleted(this.selectedBill.Id)
      .then((response) => {
        if (response) {
          this.updateFilters();
          //this.bills.splice(this.bills.indexOf(this.selectedBill), 1);
          this.billform.$setPristine();
          delete this.selectedBill;
          delete this.selectedMeter;
          delete this.Contract;
        }
      })
      .finally(() => {
        this.performingAction = false;
      });
  }

  deleteCreditNote() {
    this.performingAction = true;
    this.$creditnoteservice
      .delete(this.selectedBill.Id)
      .then((response) => {
        if (response) {
          this.updateFilters();
          //this.bills.splice(this.bills.indexOf(this.selectedBill), 1);
          this.billform.$setPristine();
          delete this.selectedBill;
          delete this.selectedMeter;
          delete this.Contract;
        }
      })
      .finally(() => {
        this.performingAction = false;
      });
  }

  // #region Adding/Validating Bills
  next() {
    this.performingAction = true;
    //if we are working with an existing bill, consider moving onto the details page (page 1)
    if (this.page === 0 && this.Bill.Id) {
      delete this.Contract;

      this.$contractservice
        .fetchContractsForMeterByDate(
          this.Bill.MeterId,
          this.Bill.DateFrom,
          this.Bill.DateTo,
        )
        .then((response) => {
          this.Contract = response[0];

          if (
            this.Contract &&
            this.Contract.Rates &&
            this.Contract.Rates.length > 0 &&
            (!this.Bill.Rates ||
              (this.Bill.Rates && this.Bill.Rates.length == 0))
          ) {
            this.Contract.Rates.forEach((value, index) => {
              this.Bill.Rates.push({
                ContractId: this.Contract.Id,
                ContractRateId: value.Id,
                Name: value.Name,
                RateFromTime: value.RateFromTime,
                RateToTime: value.RateToTime,
              } as BillRateDTO);
            });
          }

          if (
            this.Contract &&
            this.Contract.Charges &&
            this.Contract.Charges.length > 0 &&
            (!this.Bill.Charges ||
              (this.Bill.Charges && this.Bill.Charges.length == 0))
          ) {
            this.Contract.Charges.forEach((value, index) => {
              this.Bill.Charges.push({
                ContractId: this.Contract.Id,
                ContractChargeId: value.Id,
                Name: value.Name,
                ChargeType: value.ChargeType,
                Unit: value.Unit,
              } as BillChargeDTO);
            });
          }

          this.$billservice
            .getBillComparisonData(
              this.Bill.MeterId,
              this.Bill.DateFrom,
              this.Bill.DateTo,
            )
            .then((response) => {
              if (
                this.areEqual(response.TotalAmount, this.Bill.TotalAmount, 0)
              ) {
                // Totals match, skip to end.
                if (!this.Bill.MeterId) {
                  this.Bill.MeterId = this.selectedMeter.Id;
                }
                this.Bill.AssociatedCustomerId =
                  this.selectedMeter.AssociatedCustomerId;

                this.$billservice
                  .addUpdate(this.Bill)
                  .then((bill) => {
                    this.$location.path("/bills/" + bill.Id);
                  })
                  .finally(() => {
                    this.performingAction = false;
                  });
              } else {
                // Totals do not match, we need to enter more.
                this.page = 1;
              }
            })
            .finally(() => {
              this.performingAction = false;
            });
        });
    } else if (this.page === 1 || !this.Bill.Id) {
      //if we're on the last page or we are working with a new bill, just save
      if (!this.Bill.MeterId) {
        this.Bill.MeterId = this.selectedMeter.Id;
      }
      this.Bill.AssociatedCustomerId = this.selectedMeter.AssociatedCustomerId;
      this.$billservice
        .addUpdate(this.Bill)
        .then((bill) => {
          this.$location.path("/bills/" + bill.Id);
        })
        .finally(() => {
          this.performingAction = false;
        });
    }
  }

  previous() {
    this.performingAction = true;
    if (this.page > 0) {
      this.page--;
    }
    this.performingAction = false;
  }

  addRate() {
    if (!this.Bill.Rates) {
      this.Bill.Rates = [];
    }

    this.Bill.Rates.push({} as BillRateDTO);
  }

  addCharge() {
    if (!this.Bill.Charges) {
      this.Bill.Charges = [];
    }

    this.Bill.Charges.push({
      ChargeType: "Normal",
    } as BillChargeDTO);
  }

  addKVACharge() {
    if (!this.Bill.Charges) {
      this.Bill.Charges = [];
    }

    this.Bill.Charges.push({ ChargeType: "KVA" } as BillChargeDTO);
  }

  search(needle: string): ng.IPromise<any[]> {
    if (!needle) return null;

    return this.$search.search(needle, true, false, false, false);
  }

  selectItem(item: any, type: string): void {
    let meter: MeterDTO = item as MeterDTO;

    this.selectedMeter = meter;
    this.Bill.AssociatedSiteName = this.selectedMeter.AssociatedSiteName;
    this.Bill.AssociatedCustomerName =
      this.selectedMeter.AssociatedCustomerName;
    this.Bill.MeterName = this.selectedMeter.Name;
    this.Bill.MeterId = meter.Id;
  }
  // #endregion Adding/Validating Bills

  //getUsageSumOfRates(rates: BillRateDTO[]): number {
  //    let sum: number = 0;
  //    if (rates) {
  //        rates.forEach((value, index) => {
  //            sum += value.Usage;
  //        });
  //    }
  //    return sum;
  //}

  //getUsageKwHSumOfRates(rates: RateUsageDTO[]): number {
  //    let sum: number = 0;
  //    if (rates) {
  //        rates.forEach((value, index) => {
  //            sum += value.UsageKwH;
  //        });
  //    }
  //    return sum;
  //}

  showSign(num: number, numDecimals: number): string {
    if (num && num > 0) {
      return "+" + num.toFixed(numDecimals).toString();
    } else if (num) {
      return num.toFixed(numDecimals).toString();
    }
    return "";
  }

  getReportChargeCost(chargeName: string): number {
    if (this.report) {
      let reportCharges: ChargeUsageDTO[];

      if (this.report) {
        reportCharges = this.report.CostOtherCharges.filter((value, index) => {
          return value.Name == chargeName;
        });

        if (reportCharges && reportCharges.length > 0) {
          return reportCharges[0].TotalCost;
        }
      }
    }

    return 0;
  }

  getReportUsageCost(rateName: string): number {
    if (this.report) {
      let reportUsages: RateUsageDTO[];

      if (this.report) {
        reportUsages = this.report.UsageCosts.filter((value, index) => {
          return value.Name == rateName;
        });

        if (reportUsages && reportUsages.length > 0) {
          return reportUsages[0].TotalCost;
        }
      }
    }

    return 0;
  }

  getReportUsageKWH(rateName: string): number {
    if (this.report) {
      let reportUsages: RateUsageDTO[];

      if (this.report) {
        reportUsages = this.report.UsageCosts.filter((value, index) => {
          return value.Name == rateName;
        });

        if (reportUsages && reportUsages.length > 0) {
          return reportUsages[0].UsageKwH;
        }
      }
    }

    return 0;
  }

  getRateError(billRate: BillRateDTO): string {
    let reportedUsageCost: number = 0;
    let reportedUsage: number = 0;
    let contractRate: ContractRateDTO;

    if (billRate) {
      reportedUsageCost = this.getReportUsageCost(billRate.Name);
      reportedUsage = this.getReportUsageKWH(billRate.Name);
      contractRate = this.getContractRate(billRate);
    }

    let res: string = "";

    if (
      billRate &&
      contractRate &&
      (billRate.RateChargePerKWHUnit != contractRate.RateChargePerKWHUnit ||
        !this.areEqual(
          billRate.RateChargePerKWH,
          contractRate.RateChargePerKWH,
          2,
        ))
    ) {
      res +=
        "Expected rate:" +
        contractRate.RateChargePerKWH +
        " " +
        contractRate.RateChargePerKWHUnit +
        "/kWh; ";
    }

    if (
      billRate &&
      this.report &&
      !this.areEqual(billRate.Usage, reportedUsage, 2)
    ) {
      res += "Usage " + this.showSign(billRate.Usage - reportedUsage, 2) + "; ";
    }

    if (
      billRate &&
      this.report &&
      !this.areEqual(billRate.BillingAmount, reportedUsageCost, 2)
    ) {
      res +=
        "Cost £" + this.showSign(billRate.BillingAmount - reportedUsageCost, 2);
    }
    return res;
  }

  getContractCharge(billCharge: BillChargeDTO): ContractChargeDTO {
    let contractCharge: ContractChargeDTO;

    if (billCharge && this.Contract) {
      let contractCharges: ContractChargeDTO[] = this.Contract.Charges.filter(
        (value, index) => {
          return value.Name == billCharge.Name;
        },
      );

      if (contractCharges && contractCharges.length > 0) {
        return contractCharges[0];
      }
    }

    return contractCharge;
  }

  getContractRate(billRate: BillRateDTO): ContractRateDTO {
    let contractRate: ContractRateDTO;

    if (billRate && this.Contract) {
      let contractRates: ContractRateDTO[] = this.Contract.Rates.filter(
        (value, index) => {
          return value.Name == billRate.Name;
        },
      );

      if (contractRates && contractRates.length > 0) {
        return contractRates[0];
      }
    }

    return contractRate;
  }

  getChargeError(billCharge: BillChargeDTO): string {
    let reportedChargeCost: number = 0;
    let contractCharge: ContractChargeDTO;

    if (billCharge) {
      reportedChargeCost = this.getReportChargeCost(billCharge.Name);
      contractCharge = this.getContractCharge(billCharge);
    }

    let res: string = "";

    if (
      billCharge &&
      contractCharge &&
      (billCharge.Unit != contractCharge.Unit ||
        !this.areEqual(billCharge.Value, contractCharge.Value, 2))
    ) {
      res +=
        "Expected Charge:" +
        contractCharge.Value +
        " " +
        contractCharge.Unit +
        "; ";
    }

    if (
      billCharge &&
      this.report &&
      !this.areEqual(billCharge.BillingAmount, reportedChargeCost, 2)
    ) {
      res +=
        "Cost £" +
        this.showSign(billCharge.BillingAmount - reportedChargeCost, 2);
    }
    return res;
  }

  getKVAError(billCharge: BillChargeDTO): string {
    let contractCharge: ContractChargeDTO;

    if (billCharge) {
      contractCharge = this.getContractCharge(billCharge);
    }

    let res: string = "";

    if (
      billCharge &&
      contractCharge &&
      (billCharge.Unit != contractCharge.Unit ||
        !this.areEqual(billCharge.Value, contractCharge.Value, 2))
    ) {
      res +=
        "Expected Charge:" +
        contractCharge.Value +
        " " +
        contractCharge.Unit +
        "/KVA; ";
    }

    if (
      billCharge &&
      this.report &&
      !this.areEqual(billCharge.BillingAmount, this.report.CostKVA, 2)
    ) {
      res +=
        "Cost £" +
        this.showSign(billCharge.BillingAmount - this.report.CostKVA, 2);
    }
    return res;
  }

  getUsageCostInPounds(billRate: BillRateDTO): number {
    switch (billRate.RateChargePerKWHUnit) {
      case RateUnitEnum.Pence:
        return (billRate.Usage * billRate.RateChargePerKWH) / 100;
      case RateUnitEnum.Pounds:
        return billRate.Usage * billRate.RateChargePerKWH;
      default:
        throw Error("Invalid value for RateChargePerKWHUnit in billRate");
    }
  }

  getBillError(): string {
    let res: string = "";

    if (this.conflictingBills > 0) {
      res = "Date range includes date already billed for";
    }

    return res;
  }

  getStandingChargeError(): string {
    let res: string = "";

    if (
      this.Contract &&
      this.Bill &&
      (this.Contract.StandingChargeUnit != this.Bill.StandingChargeUnit ||
        !this.areEqual(
          this.Contract.StandingChargeValue,
          this.Bill.StandingChargeValue,
          2,
        ))
    ) {
      res +=
        "Expected Rate:" +
        this.Contract.StandingChargeValue +
        " " +
        this.Contract.StandingChargeUnit +
        "; ";
    }

    if (
      this.Bill &&
      this.report &&
      !this.areEqual(
        this.Bill.StandingChargeAmount,
        this.report.CostStandingCharge,
        2,
      )
    ) {
      res +=
        "Cost £" +
        this.showSign(
          this.Bill.StandingChargeAmount - this.report.CostStandingCharge,
          2,
        );
    }
    return res;
  }

  areEqual(number1: number, number2: number, numDecimals: number): boolean {
    // Some inputs pass through strings.
    if (typeof number2 === "string") {
      number2 = parseInt(number2 as any);
    }
    if (number1 && number2) {
      return number1.toFixed(numDecimals) == number2.toFixed(numDecimals);
    }
    return false;
  }

  getValidationDisplayClass(number1: number, number2: number): string {
    // Some inputs pass through strings.
    if (typeof number2 === "string") {
      number2 = parseInt(number2 as any);
    }
    if (!this.areEqual(number1, number2, 2)) {
      return "negative";
    }
    return "positive";
  }

  getValidationDisplayClassVal(val1: any, val2: any): string {
    if (val1 != val2) {
      return "negative";
    }
    return "positive";
  }

  getValidationDisplayClass2(
    number1: number,
    val1: any,
    number2: number,
    val2: any,
  ): string {
    // Some inputs pass through strings.
    if (typeof number2 === "string") {
      number2 = parseInt(number2 as any);
    }
    if (!this.areEqual(number1, number2, 2) || val1 != val2) {
      return "negative";
    }
    return "positive";
  }

  getValidationDisplayClassError(err: string): string {
    if (err && err.length > 0) {
      return "negative";
    }
    return "positive";
  }

  ExportToExcel() {
    if (this.selectedBill) {
      var contractid: number = null;
      if (this.Contract) {
        contractid = this.Contract.Id;
      }
      var billid: number = null;
      if (this.Bill) {
        billid = this.Bill.Id;
      }
      this.$reportservice.usageexcel(
        [this.selectedBill.MeterId],
        this.selectedBill.DateFrom,
        this.selectedBill.DateTo,
        "Month",
        billid,
        contractid,
      );

      //    .then((response) => {
      //    this.report = response;
      //});
    }
  }
}
