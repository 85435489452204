import { LegalEntityDTO } from "@js/oldmodels/DTOs/LegalEntityDTO.cs.d";

export class FileUploadComponent implements ng.IComponentOptions {
  public transclude: boolean = true;

  public bindings: any;
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.bindings = {
      maxSize: "@", // "10MB"
      fileType: "@", // "*.xlsx"
      uploadUrl: "@", // "/api/lender/import"
      uploadComplete: "&",
    };
    this.controller = FileUploadController;
    this.templateUrl =
      "js/filesfromccqbase/Components/fileupload/fileupload.html";
  }
}

export class FileUploadController {
  public legalentity: LegalEntityDTO;
  public maxSize: string;
  public fileType: string;
  public uploadUrl: string;
  public file: Blob;
  public uploadComplete: (any) => ng.IPromise<any[]>;

  public uploading: boolean;
  public progress: number = 0;

  public error: boolean;

  static $inject = ["$scope", "Upload"];
  constructor(
    private $scope: ng.IScope,
    public $uploadservice: any,
  ) {}

  uploadFile(file: any) {
    if (!file) {
      return;
    }
    this.progress = 0;
    this.uploading = true;
    this.$uploadservice
      .upload({
        url: this.uploadUrl,
        data: { file: file },
      })
      .then(
        (response: any) => {
          this.uploading = false;
        },
        (response: any) => {
          this.uploading = false;
          this.error = true;
        },
        (event: any) => {
          this.progress = Math.round(
            (100.0 * parseInt(event.loaded)) / parseInt(event.total),
          );
        },
      );
  }
}
