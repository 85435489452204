import { FC } from "react";
import { AuthErrorDetails } from "../apis/ModularCCQClient";
import { Stack, Typography } from "@mui/material";

interface Props {
  error: Partial<AuthErrorDetails>;
}

export const ErrorMessage: FC<Props> = ({ error }) => {
  return (
    <Stack mt={3}>
      <Typography color="error">
        An Error Occured: {error.Title ?? "Please try again"}
      </Typography>
      {error.Details && (
        <Typography variant="caption" color="error">
          {error.Details}
        </Typography>
      )}
    </Stack>
  );
};
