import { CCQAuthProvider } from "@ccq/auth-lib";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import AppRoutes from "@react/AppRoutes";
import {
  AuthErrorDetails,
  CCQAuthClient,
  OidcConfigDTO,
} from "@react/apis/ModularCCQClient";
import { IRootScopeService } from "angular";
import { FC, KeyboardEvent, Suspense, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { ErrorMessage } from "./ErrorMessage";
import LoginButton from "./LoginButton";

interface Props {
  $rootScope: IRootScopeService;
}

const CCQAuthWrapper: FC<Props> = ({ $rootScope }) => {
  // get the current auth provider
  let currAuthProvider = localStorage.getItem("authProvider");

  // if it doesn't exist, then set it to local
  if (currAuthProvider === null) {
    currAuthProvider = "Local";
    localStorage.setItem("authProvider", "Local");
  }

  const [userName, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<Partial<AuthErrorDetails>>();
  const authClient = new CCQAuthClient();

  const handleLocalLogin = async () => {
    const inputErrors: string[] = [];

    if (!userName) {
      inputErrors.push("Please enter a username");
    }

    if (!password) {
      inputErrors.push("Please enter a password");
    }

    if (inputErrors.length > 0) {
      alert(inputErrors.join("\n\n"));
      return;
    }

    try {
      let response = await authClient.localLogin(userName, password);

      $rootScope.$emit("logged-in", response.Token);
    } catch (e: any) {
      setError(e);
    }
  };

  const handleOnKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      handleLocalLogin();
    }
  };

  return (
    <Suspense fallback={<CircularProgress />}>
      <BrowserRouter>
        <Box padding={1}>
          <Stack spacing={1}>
            <TextField
              label="User Name"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              onKeyDown={handleOnKeyDown}
              required
            />

            <TextField
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={handleOnKeyDown}
              required
            />

            <Button onClick={handleLocalLogin}>Sign In</Button>
          </Stack>

          {error && <ErrorMessage error={error} />}

          <CCQAuthProvider
            loginCallback={AppRoutes.LoginCallback}
            getOIDCConfig={() =>
              new CCQAuthClient()
                .getOidcConfigs("Microsoft")
                .then((result: OidcConfigDTO) => {
                  const config = {
                    authority: result.Authority ? result.Authority : "",
                    clientId: result.ClientId ? result.ClientId : "",
                    clientSecret: result.ClientSecret
                      ? result.ClientSecret
                      : undefined,
                    scopes: result.Scopes ? result.Scopes : [],
                  };

                  return config;
                })
            }
          >
            <LoginButton authProvider="Microsoft" />
          </CCQAuthProvider>

          <CCQAuthProvider
            loginCallback={AppRoutes.LoginCallback}
            getOIDCConfig={() =>
              new CCQAuthClient()
                .getOidcConfigs("Google")
                .then((result: OidcConfigDTO) => {
                  const config = {
                    authority: result.Authority ? result.Authority : "",
                    clientId: result.ClientId ? result.ClientId : "",
                    clientSecret: result.ClientSecret
                      ? result.ClientSecret
                      : undefined,
                    scopes: result.Scopes ? result.Scopes : [],
                  };

                  return config;
                })
            }
          >
            <LoginButton authProvider="Google" />
          </CCQAuthProvider>
        </Box>
      </BrowserRouter>
    </Suspense>
  );
};

export default CCQAuthWrapper;
