import { MeterDTO } from "@js/oldmodels/DTOs/MeterDTO.cs.d";
import { MeterUtilityTypeEnum } from "@js/oldmodels/Enums/MeterUtilityTypeEnum.cs.d";
import { ContractService } from "../../../services/ContractService";

export class GlobalSearchBarComponent implements ng.IComponentOptions {
  public transclude: boolean = true;

  public bindings: any;
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.bindings = {
      placeholder: "@",
      search: "&",
      selectItem: "&",
      filter: "<",
      multiselect: "<",
    };
    this.controller = GlobalSearchBarController;
    this.templateUrl =
      "js/filesfromccqbase/Components/globalsearchbar/globalsearchbar.html";
  }
}

export class GlobalSearchBarController {
  public placeholder: string;
  public search: (any) => ng.IPromise<any[]>;
  public selectItem: (any) => ng.IPromise<any[]>;

  public needle: string;
  public items: any[];
  public selectedItem: any;
  public selectedIndex: number;

  public meterCount: number = 0;
  public customerCount: number = 0;
  public agencyCount: number = 0;
  public siteCount: number = 0;

  public searching: boolean = false;
  public filter: string = "";

  public multiselect: boolean = false;

  static $inject = ["$parse", "ContractService"];
  constructor(
    private $parse: ng.IParseService,
    public contractService: ContractService,
  ) {}

  highlightItem(index: number) {
    if (!this.items) {
      return;
    }

    if (index <= this.meterCount) {
      this.selectedItem = this.items["Meters"][index];
    } else if (index <= this.meterCount + this.customerCount) {
      this.selectedItem = this.items["Customers"][index - this.meterCount];
    } else if (
      index <=
      this.meterCount + this.customerCount + this.agencyCount
    ) {
      this.selectedItem =
        this.items["Agencies"][index - this.meterCount - this.customerCount];
    } else if (
      index <=
      this.meterCount + this.customerCount + this.agencyCount + this.siteCount
    ) {
      this.selectedItem =
        this.items["Sites"][
          index - this.meterCount - this.customerCount - this.agencyCount
        ];
    }
  }

  keyUp($event: KeyboardEvent) {
    if ($event.keyCode === 13) {
      this.selectByIndex();
    } else if ($event.keyCode === 38) {
      // Up Arrow
      if (this.selectedIndex > 0) {
        this.selectedIndex--;
      }
    } else if ($event.keyCode === 40) {
      // Down arrow
      if (this.selectedIndex === undefined) {
        this.selectedIndex = 0;
      } else if (
        this.selectedIndex <
        this.meterCount + this.customerCount + this.agencyCount + this.siteCount
      ) {
        this.selectedIndex++;
      }
    } else if ($event.keyCode === 27) {
      // Escape key.
      this.cancel();
    }

    this.highlightItem(this.selectedIndex);
  }

  updateSearch() {
    this.searching = true;
    if (!this.needle) {
      delete this.items;
      return;
    }

    this.search({ $term: this.needle }).then((response) => {
      this.items = response;
      if (this.items["Meters"]) {
        this.meterCount += this.items["Meters"].length;
      }
      if (this.items["Customers"]) {
        this.customerCount += this.items["Customers"].length;
      }
      if (this.items["Agencies"]) {
        this.agencyCount += this.items["Agencies"].length;
      }
      if (this.items["Sites"]) {
        this.siteCount += this.items["Sites"].length;
      }
      this.searching = false;
    });
  }

  cancel() {
    delete this.items;
    delete this.needle;
  }

  selectByIndex() {
    if (this.selectedIndex <= this.meterCount) {
      this.select(this.selectedItem, "meter");
    } else if (this.selectedIndex <= this.meterCount + this.customerCount) {
      this.select(this.selectedItem, "customer");
    } else if (
      this.selectedIndex <=
      this.meterCount + this.customerCount + this.agencyCount
    ) {
      this.select(this.selectedItem, "agency");
    } else if (
      this.selectedIndex <=
      this.meterCount + this.customerCount + this.agencyCount + this.siteCount
    ) {
      this.select(this.selectedItem, "site");
    }
  }

  select(item: any, type: string) {
    if (this.multiselect == false) {
      delete this.items;
    }
    delete this.needle;
    this.selectItem({ $type: type, $item: item });
  }

  compareMeters(a, b) {
    let comparison = 0;
    if (
      a.AssociatedCustomerName != null &&
      b.AssociatedCustomerName != null &&
      a.AssociatedCustomerName.toUpperCase() >
        b.AssociatedCustomerName.toUpperCase()
    ) {
      comparison = 1;
    } else if (
      (a.AssociatedCustomerName =
        null &&
        b.AssociatedCustomerName != null &&
        a.AssociatedCustomerName.toUpperCase() <
          b.AssociatedCustomerName.toUpperCase())
    ) {
      comparison = -1;
    } else {
      if (
        a.AssociatedSiteName != null &&
        b.AssociatedSiteName != null &&
        a.AssociatedSiteName.toUpperCase() > b.AssociatedSiteName.toUpperCase()
      ) {
        comparison = 1;
      } else if (
        a.AssociatedSiteName != null &&
        b.AssociatedSiteName != null &&
        a.AssociatedSiteName.toUpperCase() < b.AssociatedSiteName.toUpperCase()
      ) {
        comparison = -1;
      } else {
        if (
          a.Name != null &&
          b.Name != null &&
          a.Name.toUpperCase() > b.Name.toUpperCase()
        ) {
          comparison = 1;
        } else if (
          a.Name != null &&
          b.Name != null &&
          a.Name.toUpperCase() < b.Name.toUpperCase()
        ) {
          comparison = -1;
        }
      }
    }
    return comparison;
  }

  meterUtilityTypeFilter(filter: string) {
    return function (value: MeterDTO): boolean {
      if (!value) {
        return false;
      }

      if (!value.MeterUtilityType) {
        return true;
      }

      return (
        !filter || MeterUtilityTypeEnum[value.MeterUtilityType].includes(filter)
      );
    };
  }
}
