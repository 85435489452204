import {
  CalendarComponent,
  CalendarController,
} from "./filesfromccqbase/Components/calendar/calendar";
import {
  FileUploadComponent,
  FileUploadController,
} from "./filesfromccqbase/Components/fileupload/fileupload";
import {
  GlobalSearchBarComponent,
  GlobalSearchBarController,
} from "./filesfromccqbase/Components/globalsearchbar/globalsearchbar";
import {
  LazyLoadListComponent,
  LazyLoadListController,
} from "./filesfromccqbase/Components/lazyloadlist/lazyloadlist";
import {
  LegalEntityFormComponent,
  LegalEntityFormController,
} from "./filesfromccqbase/Components/legalentityform/legalentityform";

export function registerCCQBaseComponents() {
  angular
    .module("ccqapp")
    .controller("CalendarController", CalendarController)
    .component("calendar", new CalendarComponent())
    .controller("FileUploadController", FileUploadController)
    .component("fileupload", new FileUploadComponent())
    .controller("GlobalSearchBarController", GlobalSearchBarController)
    .component("globalSearchBar", new GlobalSearchBarComponent())
    .controller("LazyLoadListController", LazyLoadListController)
    .component("lazyLoadList", new LazyLoadListComponent())
    .controller("LegalEntityFormController", LegalEntityFormController)
    .component("legalEntityForm", new LegalEntityFormComponent());
}
