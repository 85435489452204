import { UserService } from "../services/UserService";
import { AgencyService } from "../services/AgencyService";
import { CustomerService } from "../services/CustomerService";
import { AgencyDTO } from "@js/oldmodels/DTOs/AgencyDTO.cs.d";
import { ApplicationUserDTO } from "@js/oldmodels/DTOs/ApplicationUserDTO.cs.d";
import { CustomerDTO } from "@js/oldmodels/DTOs/CustomerDTO.cs.d";
import { MeterDTO } from "@js/oldmodels/DTOs/MeterDTO.cs.d";

export class ListUsersController {
  selectedSection: string = "details";

  users: ApplicationUserDTO[] = [];
  selectedUser: ApplicationUserDTO | null = null;

  meters: MeterDTO[] = [];

  fetchingUsers: boolean = false;
  fetchingUser: boolean = false;

  userform: ng.IFormController;

  agencies: AgencyDTO[] = [];
  customers: CustomerDTO[] = [];

  filter: string = "current";

  static $inject = [
    "$routeParams",
    "UserService",
    "AgencyService",
    "CustomerService",
  ];

  constructor(
    private $routeParams: ng.route.IRouteParamsService,
    private $userservice: UserService,
    private $agencyservice: AgencyService,
    private $customerservice: CustomerService,
  ) {
    if ($routeParams.userFilter) {
      this.filter = $routeParams.userFilter;
    }

    this.updateUsers();

    this.$agencyservice
      .fetchAll()
      .then((response) => {
        this.agencies = response;
      })
      .catch((e: any) => {
        console.error(e);
      });

    this.$customerservice
      .ListNew()
      .then((response) => {
        this.customers = response;
      })
      .catch((e: any) => {
        console.error(e);
      });
  }

  updateUsers() {
    this.fetchingUsers = true;
    this.$userservice
      .fetchAll()
      .then((response) => {
        this.users = response;
        this.fetchingUsers = false;
      })
      .catch((e: any) => {
        this.fetchingUsers = false;
        console.error(e);
      });
  }

  createUser() {
    this.selectedSection = "details";
    this.selectedUser = {} as ApplicationUserDTO;
  }

  selectUser(user: ApplicationUserDTO) {
    this.selectedUser = user;
  }

  removeUser(idToDelete: number) {
    if (idToDelete === undefined) {
      this.userform.$setPristine();
      this.selectedUser = null;
      return;
    }

    this.$userservice
      .markasdeleted(idToDelete)
      .then((response) => {
        if (response) {
          this.users = this.users.filter((value) => value.Id !== idToDelete);
          this.userform.$setPristine();
          this.selectedUser = null;
        }
      })
      .catch((e: any) => {
        this.userform.$setPristine();
        this.selectedUser = null;
        console.error(e);
      });
  }

  cancelUpdateUser() {
    this.selectedUser = null;
  }

  cancel() {
    if (!this.selectedUser?.Id) {
      this.selectedUser = null;
      this.userform.$setPristine();
      return;
    }

    this.fetchingUser = true;
    this.$userservice
      .fetch(this.selectedUser.Id)
      .then((response) => {
        this.selectedUser = response;
        this.fetchingUser = false;
        this.userform.$setPristine();
      })
      .catch((e: any) => {
        this.fetchingUser = false;
        this.userform.$setPristine();
        console.error(e);
      });
  }

  save(userToSave: ApplicationUserDTO) {
    if (userToSave === null) {
      console.error("The user to save is null");
    }

    this.$userservice
      .addUpdate(userToSave)
      .then((response) => {
        userToSave.Id = response.Id;

        const isExistingUser = this.users.some(
          (value) => value.Id === userToSave.Id,
        );

        if (!isExistingUser) {
          this.users.push(userToSave);
        }

        this.userform.$setPristine();
      })
      .catch((e: any) => {
        this.userform.$setPristine();
        console.error(e);
      });
  }

  // #endregion
}
