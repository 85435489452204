const AppRoutes = {
  Dashboard: "/dashboard",
  RequestEmail: "/request-email",
  OtpVerification: "/otp-verification",
  OtpSetup: "/otp-setup",
  SMSVerification: "/sms-verification",
  LoginCallback: "/login-callback",
};

export default AppRoutes;
