import { MeterDTO } from "@js/oldmodels/DTOs/MeterDTO.cs.d";
import { MeterService } from "../services/MeterService";
import { MeterUsageManualService } from "../services/MeterUsageManualService";
import { SearchService } from "../services/SearchService";

export class ExportMeterReadingsController {
  meters: MeterDTO[];
  selectedMeters: MeterDTO[];
  fetchingMeters: boolean;

  start: Date = new Date(2017, 8, 1);
  end: Date = Date.today();

  fetchingExport: boolean;
  exportReady: boolean;
  exportData: string;
  exportFileName: string = "MeterExport.csv";

  static $inject = [
    "$rootScope",
    "MeterService",
    "MeterUsageManualService",
    "SearchService",
  ];

  constructor(
    private $rootScope: ng.IRootScopeService,
    private $meters: MeterService,
    private $meterusagemanualservice: MeterUsageManualService,
    private $search: SearchService,
  ) {}

  updateMeters() {
    this.fetchingMeters = true;
    if ((this.$rootScope as any).selectedCustomer) {
      this.$meters
        .fetchMetersForCustomer((this.$rootScope as any).selectedCustomer.Id)
        .then((response) => {
          this.meters = response;
        })
        .finally(() => {
          this.fetchingMeters = false;
        });
    } else {
      this.$meters
        .ListNew()
        .then((response) => {
          this.meters = response;
        })
        .finally(() => {
          this.fetchingMeters = false;
        });
    }
  }

  export() {
    this.fetchingExport = true;
    this.$meterusagemanualservice
      .export(this.selectedMeters, this.start, this.end)
      .then((response) => {
        this.exportData = encodeURIComponent(response);
        this.exportReady = true;
      })
      .finally(() => {
        this.fetchingExport = false;
      });
  }

  // #region Search

  search(needle: string): ng.IPromise<any[]> {
    if (!needle) return null;

    return this.$search.search(needle, true, false, false, false);
  }

  selectItem(item: any, type: string): void {
    if (!this.selectedMeters) {
      this.selectedMeters = [];
    }

    let meter: MeterDTO = item as MeterDTO;
    this.selectedMeters.push(meter);
  }

  removeItem(meter: MeterDTO) {
    this.selectedMeters.splice(this.selectedMeters.indexOf(meter), 1);
  }

  // #endregion Search

  setDates(range: string) {
    if (range === "thismonth") {
      this.start = new Date().moveToFirstDayOfMonth();
      this.end = Date.today();
    } else if (range === "lastmonth") {
      this.start = new Date().moveToFirstDayOfMonth().addMonths(-1);
      this.end = new Date().moveToFirstDayOfMonth().addDays(-1);
    } else if (range === "last30days") {
      this.start = new Date().addDays(-30);
      this.end = Date.today();
    } else if (range === "last6months") {
      this.start = new Date().moveToFirstDayOfMonth().addMonths(-6);
      this.end = new Date().moveToFirstDayOfMonth().addDays(-1);
    } else if (range === "thisyear") {
      this.start = new Date(new Date().getFullYear(), 0, 1);
      this.end = Date.today();
    } else if (range === "lastyear") {
      this.start = new Date(new Date().getFullYear(), 0, 1).addYears(-1);
      this.end = new Date(this.start.getFullYear(), 11, 31);
    } else if (range === "last2years") {
      this.start = new Date(new Date().getFullYear(), 0, 1).addYears(-2);
      this.end = new Date(new Date().getFullYear(), 0, 1).addDays(-1);
    } else if (range === "last5years") {
      this.start = new Date(new Date().getFullYear(), 0, 1).addYears(-5);
      this.end = new Date(new Date().getFullYear(), 0, 1).addDays(-1);
    }

    // Set times to start of first day and end of last day.
    //this.start.setHours(0, 0, 0, 0);
    //this.end.setHours(23, 59, 59, 999);
  }
}
