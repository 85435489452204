export interface OpenStreetMapCoordinates {
  place_id: string;
  licence: string;
  boundingbox: string[];
  lat: string;
  lon: string;
  display_name: string;
  class: string;
  type: string;
  importance: number;
}

export class MapService {
  static $inject = ["$http", "$q"];

  constructor(
    private $http: ng.IHttpService,
    private $q: ng.IQService,
  ) {}

  getCoordinates(location: string): ng.IPromise<OpenStreetMapCoordinates> {
    let defer = this.$q.defer<OpenStreetMapCoordinates>();
    this.$http
      .get(
        "https://nominatim.openstreetmap.org/search?q=" +
          encodeURIComponent(location) +
          "&format=json",
      )
      .then((response) => {
        defer.resolve(response.data[0] as OpenStreetMapCoordinates);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }
}
