import { CustomerDTO } from "@js/oldmodels/DTOs/CustomerDTO.cs.d";
import { MeterDTO } from "@js/oldmodels/DTOs/MeterDTO.cs.d";
import { UBTaskDTO } from "@js/oldmodels/DTOs/UBTaskDTO.cs.d";
import { TaskColourEnum } from "@js/oldmodels/Enums/TaskColourEnum.cs.d";
import { ContractService } from "../services/ContractService";
import { CustomerService } from "../services/CustomerService";
import { MeterService } from "../services/MeterService";
import { ReportService } from "../services/ReportService";
import { UBTaskService } from "../services/UBTaskService";

export class DashboardController {
  contractlessMetersCount: number;
  missingHHDataCount: number;
  zeroValueHHDataCount: number;
  expiringMeters: number;

  redTasks: number;
  amberTasks: number;
  greenTasks: number;
  blueTasks: number;

  showRed: boolean = true;
  showAmber: boolean = true;
  showGreen: boolean = true;
  showBlue: boolean = true;

  taskObjects: UBTaskDTO[];
  selectedTaskObject: UBTaskDTO;

  customers: CustomerDTO[];
  meters: MeterDTO[];

  fetchingTaskObjects: boolean;
  fetchingTaskObject: boolean;

  ubtaskForm: ng.IFormController;
  selectedSection: string;

  static $inject = [
    "$rootScope",
    "$routeParams",
    "MeterService",
    "ReportService",
    "ContractService",
    "UBTaskService",
    "CustomerService",
  ];

  constructor(
    private $rootScope: ng.IRootScopeService,
    private $routeParams: ng.route.IRouteParamsService,
    private $meters: MeterService,
    private $reports: ReportService,
    private $contractservice: ContractService,
    private $ubtaskservice: UBTaskService,
    private $customerservice: CustomerService,
  ) {
    //$meters.countMetersWithoutContracts().then((response) => {
    //    this.contractlessMetersCount = response;
    //});
    //$meters.countMetersWithMissingHHData().then((response) => {
    //    this.missingHHDataCount = response;
    //});
    //$meters.countMetersWithZeroValueHHData().then((response) => {
    //    this.zeroValueHHDataCount = response;
    //});

    //$contractservice.countExpiringContracts(120).then((response) => {
    //    this.expiringMeters = response;
    //});

    this.updateMeters();

    this.updateCustomers();

    this.updateTaskCounts();

    if ($rootScope) {
      $rootScope.$on("UBTask-update", (event: ng.IAngularEvent) => {
        this.updateTaskCounts();
      });
    }

    //this.updateTaskObjects();
  }

  private updateCustomers() {
    this.$customerservice.ListNew().then((response) => {
      this.customers = response;
    });
  }

  private updateMeters() {
    if ((this.$rootScope as any).selectedCustomer) {
      // Fetch meters for a single company.
      this.$meters
        .fetchMetersForCustomer(
          (this.$rootScope as any).selectedCustomer.Id,
          "",
        )
        .then((response) => {
          this.meters = response;
        });
    } else {
      // Fetch all meters available to the user.
      this.$meters.ListNew().then((response) => {
        this.meters = response;
      });
    }
  }

  updateTaskCounts() {
    this.$ubtaskservice.countTasks(TaskColourEnum.Red).then((response) => {
      this.redTasks = response;
    });
    this.$ubtaskservice.countTasks(TaskColourEnum.Amber).then((response) => {
      this.amberTasks = response;
    });
    this.$ubtaskservice.countTasks(TaskColourEnum.Green).then((response) => {
      this.greenTasks = response;
    });
    this.$ubtaskservice.countTasks(TaskColourEnum.Blue).then((response) => {
      this.blueTasks = response;
    });
  }

  updateTaskObjects() {
    this.fetchingTaskObjects = true;

    // Fetch all objects.
    this.$ubtaskservice
      .fetchTasks()
      .then((response) => {
        this.taskObjects = response;
        this.preSelectedItem();
      })
      .finally(() => {
        this.fetchingTaskObjects = false;
      });
  }

  private preSelectedItem() {
    if (this.$routeParams.Id) {
      let selectedObject = this.taskObjects.find((value, index) => {
        return value.Id === parseInt(this.$routeParams.objectId);
      });

      this.selectTaskObject(selectedObject);
    }
  }

  selectTaskObject(object: UBTaskDTO) {
    this.selectedTaskObject = object;

    //this.fetchingSubobjects = true;
    //this.$subobjectservice.fetchSubobjectsForObject(object.Id).then((response) => {
    //    this.subobjects = response;
    //    this.fetchingSubobjects = false;
    //}).catch((response) => {
    //    this.fetchingSubobjects = false;
    //});

    this.selectedSection = "details";
  }

  createTaskObject() {
    this.selectedSection = "details";
    this.selectedTaskObject = {} as UBTaskDTO;
  }

  saveTask() {
    this.$ubtaskservice
      .addUpdateTask(this.selectedTaskObject)
      .then((response) => {
        this.selectedTaskObject.Id = response;
        if (!this.taskObjects) {
          this.taskObjects = [];
        }

        let matches: UBTaskDTO[] = this.taskObjects.filter((value, index) => {
          return value.Id == response;
        });

        if (!matches || matches.length === 0) {
          this.taskObjects.push(this.selectedTaskObject);
        }

        this.ubtaskForm.$setPristine();
        this.updateTaskObjects();
        this.$rootScope.$broadcast("UBTask-update");
      });
  }

  deleteTask() {
    this.$ubtaskservice
      .markasdeleted(this.selectedTaskObject.Id)
      .then((response) => {
        if (response) {
          this.taskObjects.splice(
            this.taskObjects.indexOf(this.selectedTaskObject),
            1,
          );
          this.ubtaskForm.$setPristine();
          delete this.selectedTaskObject;
        }
      });
  }
}
