import { CircularProgress } from "@mui/material";
import {
  CCQClaimClient,
  CCQMagicLinkClient,
  CCQOTPClient,
} from "@react/apis/ModularCCQClient";
import { Context, createContext, useContext, useMemo } from "react";
import { useCCQAuth } from "@ccq/auth-lib";
import { FCC } from "../services/ReactUtils";

interface APIs {
  claimClient: CCQClaimClient;
  magicLinkClient: CCQMagicLinkClient;
  otpClient: CCQOTPClient;
}

const APIContext: Context<APIs> = createContext({}) as any;

export const APIProvider: FCC = ({ children }) => {
  const { accessToken } = useCCQAuth();
  //Creates a fetch function which, if available, uses our access token as Authorization header
  const authorizedFetch = (
    url: RequestInfo,
    init?: RequestInit,
  ): Promise<Response> => {
    const authorizedHeaders = !accessToken
      ? init?.headers
      : {
          ...init?.headers,
          Authorization: `Bearer ${accessToken}`,
        };

    const authorizedInit: RequestInit = {
      ...init,
      headers: authorizedHeaders,
    };

    return fetch(url, authorizedInit);
  };

  const claimClient = useMemo(
    () =>
      new CCQClaimClient(import.meta.env.VITE_BACKEND_ENDPOINT, {
        fetch: authorizedFetch,
      }),
    [accessToken],
  );
  const magicLinkClient = useMemo(
    () =>
      new CCQMagicLinkClient(import.meta.env.VITE_BACKEND_ENDPOINT, {
        fetch: authorizedFetch,
      }),
    [accessToken],
  );
  const otpClient = useMemo(
    () =>
      new CCQOTPClient(import.meta.env.VITE_BACKEND_ENDPOINT, {
        fetch: authorizedFetch,
      }),
    [accessToken],
  );

  const context: APIs = {
    claimClient,
    magicLinkClient,
    otpClient,
  };
  // wait for all clients to load
  if (Object.values(context).some((client) => !client))
    return <CircularProgress />;
  return <APIContext.Provider value={context}>{children}</APIContext.Provider>;
};

export const useAPIs = () => {
  const apis = useContext(APIContext);
  if (Object.values(apis).length == 0)
    throw new Error("Cannot access CCQ APIs outside an APIProvider");
  return apis;
};
