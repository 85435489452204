import { CustomerDTO } from "@js/oldmodels/DTOs/CustomerDTO.cs.d";
import { MeterDTO } from "@js/oldmodels/DTOs/MeterDTO.cs.d";
import { UBTaskDTO } from "@js/oldmodels/DTOs/UBTaskDTO.cs.d";
import { TaskColourEnum } from "@js/oldmodels/Enums/TaskColourEnum.cs.d";
import ng from "angular";
import moment from "moment";
import { UBTaskService } from "../../../services/UBTaskService";

interface IScopeWithTaskColourEnum extends ng.IScope {
  TaskColourEnum: typeof TaskColourEnum;
}

export class CalendarComponent implements ng.IComponentOptions {
  public bindings: any;
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.bindings = {
      showAgenda: "<",
      showWeek: "<",
      showMonth: "<",
      showDay: "<",
      showRed: "<",
      showAmber: "<",
      showGreen: "<",
      showBlue: "<",
      customers: "<",
      meters: "<",
      selectedCustomer: "<",
      defaultView: "@",
    };
    this.controller = CalendarController;
    this.templateUrl = "js/filesfromccqbase/Components/calendar/calendar.html";
  }
}

export class CalendarController {
  public showAgenda: boolean = true;
  public showWeek: boolean = true;
  public showMonth: boolean = true;
  public showDay: boolean = true;

  public showRed: boolean = true;
  public showAmber: boolean = true;
  public showGreen: boolean = true;
  public showBlue: boolean = true;

  public selectedCustomer: CustomerDTO;

  public customers: CustomerDTO[];
  public meters: MeterDTO[];

  role: string;

  public defaultView: string = "week";

  today: Date = Date.today();

  currentMoment: moment.Moment;
  month_weeks_days: Date[][];
  weeksInMonth: number;
  selectedDay: Date;

  tasks: UBTaskDTO[];
  agenda: UBTaskDTO[];
  selectedTask: UBTaskDTO;
  editingTask: boolean;
  removingTask: boolean;

  currentSunday: Date;
  currentDay: Date;
  days: Date[];
  agendaDays: Date[];

  isSaving: boolean;

  taskForm: ng.IFormController;

  static $inject = ["$rootScope", "$scope", "UBTaskService"];

  public $onInit() {
    this.defaultView = this.showWeek
      ? "week"
      : this.showMonth
        ? "month"
        : "agenda";
  }

  constructor(
    private $rootScope: ng.IRootScopeService,
    private $scope: IScopeWithTaskColourEnum,
    private $tasks: UBTaskService,
  ) {
    this.getMonday(Date.today());
    this.setMonth(moment());
    this.setWeek(moment());
    this.setDay(Date.today());
    this.setAgenda();

    this.$rootScope.$on(
      "selected-customer-updated",
      (event: ng.IAngularEvent) => {
        this.selectedCustomer = (this.$rootScope as any).selectedCustomer;
        this.updateTasks();
      },
    );

    $rootScope.$on("UBTask-update", (event: ng.IAngularEvent) => {
      this.updateTasks();
    });

    this.$scope.TaskColourEnum = TaskColourEnum;
  }

  public updateTasks() {
    if (this.defaultView == "week") {
      this.setWeek(moment(this.currentSunday));
    } else if (this.defaultView == "month") {
      this.setMonth(this.currentMoment);
    } else if (this.defaultView == "day") {
      this.setDay(this.currentDay);
    } else {
      this.setAgenda();
    }
  }

  public getMonday(date: Date): Date {
    var monday: Date = date.previous().monday();
    // If today actually is monday, we don't want the previous monday.
    if (date.is().monday()) {
      monday = date;
    }
    return monday;
  }

  public getDays(sunday: Date): Date[] {
    var days: Date[] = [
      sunday,
      sunday.clone().addDays(1),
      sunday.clone().addDays(2),
      sunday.clone().addDays(3),
      sunday.clone().addDays(4),
      sunday.clone().addDays(5),
      sunday.clone().addDays(6),
    ];

    return days;
  }

  public getWeeksInMonth(currentMoment: moment.Moment): number {
    let firstWeekOfMonth = currentMoment.startOf("month").week();
    let lastWeekOfMonth = currentMoment.endOf("month").week();
    return lastWeekOfMonth - firstWeekOfMonth + 1;
  }

  public getWeeksAndDaysInMonth(currentMoment: moment.Moment): Date[][] {
    let weeksInMonth = this.getWeeksInMonth(currentMoment);

    var month_week_days: Date[][] = [];

    for (var i = 0; i < weeksInMonth; i++) {
      var week = currentMoment.clone().startOf("month").add(i, "week").week();

      for (var x = 0; x < 7; x++) {
        var day = currentMoment
          .clone()
          .startOf("month")
          .add(i, "week")
          .startOf("week")
          .add(x, "days")
          .toDate();

        if (!month_week_days[i]) {
          month_week_days[i] = [];
        }

        month_week_days[i].push(day);
      }
    }

    return month_week_days;
  }

  setMonth(m: moment.Moment) {
    this.currentMoment = m.clone();
    this.month_weeks_days = this.getWeeksAndDaysInMonth(m);
    this.weeksInMonth = this.getWeeksInMonth(m);

    this.$tasks.fetchMonthTasks(m.year(), m.month() + 1).then((response) => {
      this.tasks = response.filter((task) => {
        return (
          !this.selectedCustomer || this.selectedCustomer.Id === task.CustomerId
        );
      });
    });
  }

  moveNextMonth() {
    this.setMonth(this.currentMoment.clone().add(1, "month"));
  }

  movePrevMonth() {
    this.setMonth(this.currentMoment.clone().add(-1, "month"));
  }

  setWeek(m: moment.Moment) {
    if (m.toDate().is().sunday()) {
      this.currentSunday = m.clone().toDate();
    } else {
      this.currentSunday = m.clone().toDate().previous().sunday();
    }

    this.days = this.getDays(this.currentSunday);

    this.$tasks.fetchMonthTasks(m.year(), m.month() + 1).then((response) => {
      this.tasks = response.filter((task) => {
        return (
          !this.selectedCustomer || this.selectedCustomer.Id === task.CustomerId
        );
      });
    });
  }

  moveNextWeek() {
    this.setWeek(moment(new Date(this.currentSunday.toString()).addWeeks(1)));
  }

  movePrevWeek() {
    this.setWeek(
      moment(new Date(this.currentSunday.toISOString()).addWeeks(-1)),
    );
  }

  setAgenda() {
    this.$tasks.fetchXTasks().then((response) => {
      this.agendaDays = [];
      this.agenda = response.filter((task) => {
        return (
          !this.selectedCustomer || this.selectedCustomer.Id === task.CustomerId
        );
      });
      this.agenda.forEach((task, index) => {
        //// Force task colour to red for overdue items.
        //if (task.DateTime < new Date()) {
        //    task.TaskColour = "red";
        //}
        task.DateTime = new Date(task.DateTime);

        let match: Date = this.agendaDays.find((day, day_index) => {
          day = new Date(day);
          console.log("THE DAY IS," + day);
          return (
            day.getFullYear() === task.DateTime.getFullYear() &&
            day.getMonth() === task.DateTime.getMonth() &&
            day.getDate() === task.DateTime.getDate()
          );
        });
        if (!match) {
          this.agendaDays.push(task.DateTime);
        }
      });
    });
  }

  isOverdue(date: Date): boolean {
    return date < new Date();
  }

  inCurrentMonth(date: Date): boolean {
    return date.getMonth() === this.currentMoment.month();
  }

  filterByDay(date: Date) {
    return (item: UBTaskDTO) => {
      item.DateTime = new Date(item.DateTime);

      if (
        item.DateTime.getFullYear() == date.getFullYear() &&
        item.DateTime.getMonth() == date.getMonth() &&
        item.DateTime.getDate() == date.getDate()
      ) {
        return true;
      }

      return false;
    };
  }

  hasMatchingTasks(tasks: UBTaskDTO[], date: Date): boolean {
    if (tasks) {
      for (let i = 0; i < tasks.length; i++) {
        let item: UBTaskDTO = tasks[i];
        item.DateTime = new Date(item.DateTime);

        if (
          item.DateTime.getFullYear() == date.getFullYear() &&
          item.DateTime.getMonth() == date.getMonth() &&
          item.DateTime.getDate() == date.getDate() &&
          ((item.TaskColour === TaskColourEnum.Red && this.showRed) ||
            (item.TaskColour === TaskColourEnum.Amber && this.showAmber) ||
            (item.TaskColour === TaskColourEnum.Green && this.showGreen) ||
            (item.TaskColour === TaskColourEnum.Blue && this.showBlue))
        ) {
          return true;
        }
      }
    }

    return false;
  }

  getMatchingTasks(date: Date): UBTaskDTO[] {
    let matchingTasks: UBTaskDTO[] = [];
    if (this.tasks) {
      this.tasks.forEach((item, index) => {
        // Convert Dates from strings.
        item.DateTime = new Date(item.DateTime);
        item.DateTime = new Date(item.DateTime.toISOString());
        //item.End = new Date(item.End);

        if (
          item.DateTime.getFullYear() == date.getFullYear() &&
          item.DateTime.getMonth() == date.getMonth() &&
          item.DateTime.getDate() == date.getDate()
        ) {
          if (
            !this.selectedCustomer ||
            this.selectedCustomer.Id === item.CustomerId
          ) {
            matchingTasks.push(item);
          }
        }
      });
    }

    return matchingTasks;
  }

  getMatchingTasksByHour(date: Date, hour: number): UBTaskDTO[] {
    let matchingTasks: UBTaskDTO[] = [];
    if (this.tasks) {
      this.tasks.forEach((item, index) => {
        // Convert Dates from strings.
        item.DateTime = new Date(item.DateTime);
        item.DateTime = new Date(item.DateTime.toISOString());
        //item.End = new Date(item.End);

        if (
          item.DateTime.getFullYear() == date.getFullYear() &&
          item.DateTime.getMonth() == date.getMonth() &&
          item.DateTime.getDate() == date.getDate() &&
          item.DateTime.getHours() === hour
        ) {
          matchingTasks.push(item);
        }
      });
    }

    return matchingTasks;
  }

  dayIsToday(date: Date): boolean {
    return moment(date).dayOfYear() === moment(new Date()).dayOfYear();
  }

  selectWeek(day: Date): void {
    this.setWeek(moment(day));
    this.defaultView = "week";
  }

  selectDay(day: Date): void {
    let tasks: UBTaskDTO[] = this.getMatchingTasks(day);

    if (!tasks) return;

    if (tasks.length === 1) {
      // We only have one task, let's select it.
      this.selectTask(tasks[0]);
    } else if (tasks) {
      // We have more than one task on this day, so we need to allow the user to select which task.
      this.selectedDay = day;
    }
  }

  setDay(selectedDay: Date) {
    this.currentDay = selectedDay;

    this.$tasks
      .fetchDayTasks(
        selectedDay.getFullYear(),
        selectedDay.getMonth(),
        selectedDay.getDate(),
      )
      .then((response) => {
        this.tasks = response;
      });
    this.defaultView = "day";
  }

  moveNextDay() {
    this.setDay(this.currentDay.addDays(1));
  }

  movePrevDay() {
    this.setDay(this.currentDay.addDays(-1));
  }

  addTask(): void {
    this.selectedTask = {} as UBTaskDTO;
    this.editingTask = true;
  }

  addTaskAtDate(date: Date): void {
    this.selectedTask = {} as UBTaskDTO;
    this.selectedTask.DateTime = date;
    this.editingTask = true;
  }

  cancelSaveTask(): void {
    delete this.selectedTask;
    this.editingTask = false;
  }

  selectTask(task: UBTaskDTO): void {
    if (!task) {
      delete this.selectedTask;
    } else {
      this.selectedTask = task;
      this.editingTask = true;
    }
  }

  removeTaskFromCalendar(task: UBTaskDTO): void {
    this.removingTask = false;
    this.$tasks.delete(task.Id);
  }

  editTask(task: UBTaskDTO): void {
    this.editingTask = true;
  }

  deleteTask(task: UBTaskDTO): void {
    this.$tasks.delete(task.Id).then((response) => {
      delete this.selectedTask;
      this.tasks.splice(this.tasks.indexOf(task), 1);
      this.editingTask = false;
      this.$rootScope.$broadcast("UBTask-update");
    });
  }

  updateDate() {
    // Set end date to match.
    //this.selectedTask.End.setFullYear(this.selectedTask.DateTime.getFullYear(), this.selectedTask.DateTime.getMonth(), this.selectedTask.DateTime.getDate());
    //if (this.selectedTask.End.getTime() < this.selectedTask.DateTime.getTime()) {
    //    this.selectedTask.End = new Date(this.selectedTask.DateTime).addHours(1);
    //}
  }

  saveTask() {
    this.isSaving = true;
    this.$tasks
      .addUpdateTask(this.selectedTask)
      .then((response) => {
        this.editingTask = false;
        if (this.tasks.indexOf(this.selectedTask) < 0) {
          this.tasks.push(this.selectedTask);
        }
        delete this.selectedTask;
        this.isSaving = false;
        this.$rootScope.$broadcast("UBTask-update");
      })
      .catch((response) => {
        // Uh oh, something went wrong.
        console.error(response);
      });
  }
}
