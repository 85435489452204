import { UBTaskDTO } from "@js/oldmodels/DTOs/UBTaskDTO.cs.d";
import { TaskColourEnum } from "@js/oldmodels/Enums/TaskColourEnum.cs.d";
import { BaseService } from "../filesfromccqbase/BaseService";
import { BroadcastService } from "../filesfromccqbase/BroadcastService";

export class UBTaskService extends BaseService<UBTaskDTO, number> {
  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/ubtask";
    this.$broadcastBusinessNameSingular = "ubtask";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }

  countTasks(colour: TaskColourEnum): ng.IPromise<number> {
    let defer = this.$q.defer<number>();
    this.$http
      .get("/api/ubtask/countcolour?colour=" + colour.toString())
      .then((response) => {
        defer.resolve(response.data as number);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  fetchTasks(): ng.IPromise<UBTaskDTO[]> {
    let defer = this.$q.defer<UBTaskDTO[]>();
    this.$http
      .get(this.$baseAddress + "/listforuser")
      .then((response) => {
        defer.resolve(response.data as UBTaskDTO[]);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "dashboard",
          "failed to load tasks",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  fetchXTasks(count: number = 20): ng.IPromise<UBTaskDTO[]> {
    let defer = this.$q.defer<UBTaskDTO[]>();
    this.$http
      .get(this.$baseAddress + "/listforuserlimited", {
        params: { count: count },
      })
      .then((response) => {
        defer.resolve(response.data as UBTaskDTO[]);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "dashboard",
          "failed to load tasks",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  fetchMonthTasks(year: number, month: number): ng.IPromise<UBTaskDTO[]> {
    let defer = this.$q.defer<UBTaskDTO[]>();
    // TODO Needs to fetch just tasks for a particular month.
    this.$http
      .get(this.$baseAddress + "/listforuser")
      .then((response) => {
        defer.resolve(response.data as UBTaskDTO[]);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "dashboard",
          "failed to load tasks",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  fetchDayTasks(
    year: number,
    month: number,
    day: number,
  ): ng.IPromise<UBTaskDTO[]> {
    let defer = this.$q.defer<UBTaskDTO[]>();
    // TODO Needs to fetch just tasks for a particular month.
    this.$http
      .get(this.$baseAddress + "/listforuser")
      .then((response) => {
        let tasks: UBTaskDTO[] = response.data as UBTaskDTO[];
        tasks = tasks.filter((task, index) => {
          task.DateTime = new Date(task.DateTime);
          return (
            task.DateTime.getFullYear() == year &&
            task.DateTime.getMonth() == month &&
            task.DateTime.getDate() == day
          );
        });

        defer.resolve(tasks);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "dashboard",
          "failed to load tasks",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  addUpdateTask(task: UBTaskDTO): ng.IPromise<number> {
    let defer = this.$q.defer<number>();

    this.$http
      .post("/api/ubtask/ubaddupdate", JSON.stringify(task))
      .then((response) => {
        defer.resolve(response.data as number);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "tasks",
          "Unable to save the task. Please try again shortly.",
        );
        defer.reject(response);
      });
    return defer.promise;
  }
}
