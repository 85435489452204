import { BroadcastService } from "../filesfromccqbase/BroadcastService";
import { BaseService } from "../filesfromccqbase/BaseService";
import { SupplierDTO } from "@js/oldmodels/DTOs/SupplierDTO.cs.d";

export class SupplierService extends BaseService<SupplierDTO, number> {
  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/supplier";
    this.$broadcastBusinessNameSingular = "Supplier";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }

  fetchSuppliersForCustomer(id: number): ng.IPromise<SupplierDTO[]> {
    let defer = this.$q.defer<SupplierDTO[]>();
    this.$http
      .get("/api/supplier/customer/" + id.toString())
      .then((response) => {
        defer.resolve(response.data as SupplierDTO[]);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }
}
