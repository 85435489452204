import { AgencyDTO } from "@js/oldmodels/DTOs/AgencyDTO.cs.d";
import { ApplicationUserDTO } from "@js/oldmodels/DTOs/ApplicationUserDTO.cs.d";
import { CustomerDTO } from "@js/oldmodels/DTOs/CustomerDTO.cs.d";
import { LegalEntityDTO } from "@js/oldmodels/DTOs/LegalEntityDTO.cs.d";
import { SiteDTO } from "@js/oldmodels/DTOs/SiteDTO.cs.d";
import { UserRoleEnum } from "@js/oldmodels/Enums/UserRoleEnum.cs.d";
import { AgencyService } from "../services/AgencyService";
import { AuthService } from "../services/AuthService";
import { CustomerService } from "../services/CustomerService";
import { LegalEntityService } from "../services/LegalEntityService";
import { SiteService } from "../services/SiteService";
import { UserService } from "../services/UserService";

export class ListCustomersController {
  selectedSection: "details" | "logins" | "sites" = "details";

  customers: CustomerDTO[] = [];
  selectedCustomer: CustomerDTO | null = null;

  fetchingcustomers: boolean = false;
  fetchingcustomer: boolean = false;

  addingLogin: boolean = false;
  addingSite: boolean = false;

  logins: ApplicationUserDTO[] = [];
  selectedLogin: ApplicationUserDTO | null = null;

  fetchingLogins: boolean = false;
  fetchingLogin: boolean = false;

  sites: SiteDTO[] = [];
  selectedSite: SiteDTO | null = null;

  fetchingSites: boolean = false;
  fetchingSite: boolean = false;

  customerform: ng.IFormController;
  loginform: ng.IFormController;
  siteform: ng.IFormController;

  legalentities: LegalEntityDTO[] = [];
  selectedLegalEntity: LegalEntityDTO | null = null;

  agencies: AgencyDTO[] = [];
  fetchingAgencies: boolean = false;

  consultants: ApplicationUserDTO[] = [];
  fetchingConsultants: boolean = false;

  static $inject = [
    "$rootScope",
    "$routeParams",
    "AuthService",
    "CustomerService",
    "SiteService",
    "UserService",
    "LegalEntityService",
    "AgencyService",
  ];

  constructor(
    private $rootScope: ng.IRootScopeService,
    private $routeParams: ng.route.IRouteParamsService,
    private $auth: AuthService,
    private $customerservice: CustomerService,
    private $siteservice: SiteService,
    private $userservice: UserService,
    private $legalentityservice: LegalEntityService,
    private $agencyservice: AgencyService,
  ) {
    this.$agencyservice
      .fetchAll()
      .then((response) => {
        this.agencies = response;
        this.fetchingAgencies = false;
      })
      .catch((e: any) => {
        this.fetchingAgencies = false;
        console.error(e);
      });

    this.fetchingcustomers = true;
    this.$customerservice
      .ListNew()
      .then((response) => {
        this.customers = response;
        this.fetchingcustomers = false;
        this.preSelectedItem();
      })
      .catch((e: any) => {
        this.customers = [];
        this.fetchingcustomers = false;
        console.log(e);
      });

    this.$legalentityservice
      .fetchAll()
      .then((response) => {
        this.legalentities = response;
        this.legalentities.push({ Name: "---" } as LegalEntityDTO);
        this.legalentities.push({
          Name: "New Entity",
        } as LegalEntityDTO);
      })
      .catch((e: any) => {
        this.legalentities = [];
        console.log(e);
      });
  }

  private preSelectedItem() {
    if (this.$routeParams.customerId) {
      const selectedCustomer = this.customers.find((value) => {
        return value.Id === parseInt(this.$routeParams.customerId);
      });

      if (selectedCustomer !== undefined) {
        this.selectCustomer(selectedCustomer);
      }
    }

    if (this.$routeParams.agencyId) {
      this.selectedCustomer = {
        CurrentAgencyId: this.$routeParams.agencyId,
        Address: {},
      } as CustomerDTO;
      this.selectedSection = "details";
    }
  }

  updateConsultants() {
    if (!this.selectedCustomer) {
      return;
    }

    if (
      this.selectedCustomer.CurrentAgencyId &&
      this.selectedCustomer.CurrentAgencyId > -1
    ) {
      this.$userservice
        .fetchAll()
        .then((response) => {
          if (!this.selectedCustomer) {
            return;
          }

          const customerAgencyId = this.selectedCustomer.CurrentAgencyId;
          this.consultants = response.filter(
            (value) => value.AssociatedAgencyId === customerAgencyId,
          );

          this.fetchingConsultants = false;
        })
        .catch((e: any) => {
          this.fetchingConsultants = false;
          console.error(e);
        });
    }
  }

  selectCustomer(customer: CustomerDTO) {
    this.selectedCustomer = customer;
    this.updateConsultants();
    this.$rootScope.$broadcast("select-customer", customer);
    if (this.selectedCustomer.LegalEntityId) {
      this.selectLegalEntity(this.selectedCustomer.LegalEntityId);
    }

    this.fetchingSites = true;
    this.$siteservice
      .fetchSitesForCustomer(customer.Id)
      .then((response) => {
        this.sites = response;
        this.fetchingSites = false;
      })
      .catch((e: any) => {
        this.fetchingSites = false;
        console.error(e);
      });

    this.fetchingLogins = true;
    this.$userservice
      .fetchLoginsForCustomer(customer.Id)
      .then((response) => {
        this.logins = response;
        this.fetchingLogins = false;
      })
      .catch((e: any) => {
        this.fetchingLogins = false;
        console.error(e);
      });

    this.selectedSection = "details";
  }

  createCustomer() {
    this.selectedSection = "details";
    this.selectedCustomer = {
      LegalEntity: {} as LegalEntityDTO,
      Address: {},
    } as CustomerDTO;

    this.$auth
      .getProfile()
      .then((response) => {
        if (!this.selectedCustomer) {
          return;
        }

        if (
          response.UserType === UserRoleEnum.AgencyAdmin ||
          response.UserType === UserRoleEnum.AgencyUser
        ) {
          this.selectedCustomer.CurrentAgencyId = response.AssociatedAgencyId;
          this.selectedCustomer.ConsultantId = response.Id;
        }
      })
      .catch((e: any) => {
        console.error(e);
      });
  }

  cancel() {
    if (!this.selectedCustomer) {
      console.error("The selected customer is null");
      return;
    }

    if (!this.selectedCustomer.Id) {
      this.customerform.$setPristine();
      return;
    }
    // error handling
    this.fetchingcustomer = true;
    this.$customerservice
      .fetchNew(this.selectedCustomer.Id)
      .then((response) => {
        this.selectedCustomer = response;
        this.fetchingcustomer = false;
        this.customerform.$setPristine();
        this.customers.filter(
          (customer) => customer.Id === response.Id,
        )[0].Name = response.Name; //reset name in the list also incase renamed on screen then cancel
      });
  }

  //TODO: consider add legal entity in one round trip via DTO on server
  save(customerToSave: CustomerDTO) {
    if (customerToSave === null) {
      console.error("The customer to save is null");
      return;
    }

    if (!customerToSave.LegalEntityId) {
      customerToSave.LegalEntity = {
        Name: customerToSave.Name,
      } as LegalEntityDTO;

      this.$legalentityservice
        .addUpdate(customerToSave.LegalEntity)
        .then((response) => {
          customerToSave.LegalEntityId = response.Id;
          this.legalentities.push(response);
          this.saveCustomer(customerToSave);
        })
        .catch((e: any) => {
          console.error(e);
          this.saveCustomer(customerToSave);
        });
    } else {
      this.saveCustomer(customerToSave);
    }
  }

  saveCustomer(customerToSave: CustomerDTO) {
    if (!this.selectedCustomer) {
      console.error("The selected customer is null");
      return;
    }

    this.$customerservice
      .addUpdate(customerToSave)
      .then((response) => {
        customerToSave.Id = response.Id;

        if (!this.customers) {
          this.customers = [];
        }

        const isExistingCustomer: boolean = this.customers.some(
          (value) => value.Id === customerToSave.Id,
        );

        if (!isExistingCustomer) {
          this.customers.push(customerToSave);
        }

        this.customerform.$setPristine();
      })
      .catch((e: any) => {
        this.customerform.$setPristine();
        console.error(e);
      });
  }

  delete(idToDelete: number) {
    this.$customerservice
      .markasdeleted(idToDelete)
      .then((response) => {
        if (response) {
          this.customers = this.customers.filter((value) => {
            value.Id !== idToDelete;
          });

          this.customerform.$setPristine();
          this.selectedCustomer = null;
        }
      })
      .catch((e: any) => {
        this.selectedCustomer = null;
        console.error(e);
      });
  }

  // #region Logins
  addLogin() {
    if (!this.selectedCustomer) {
      return;
    }

    this.selectedLogin = {
      AssociatedCustomerId: this.selectedCustomer.Id,
      AssociatedCustomerName: this.selectedCustomer.Name,
      Roles: [] as string[],
      UserType: UserRoleEnum.CustomerUser,
    } as ApplicationUserDTO;
  }

  removeLogin(idToRemove: number) {
    if (idToRemove === undefined) {
      return;
    }

    this.$userservice
      .markasdeleted(idToRemove)
      .then((response) => {
        if (response) {
          this.logins = this.logins.filter((value) => value.Id !== idToRemove);
          this.selectedLogin = null;
        }
      })
      .catch((e: any) => {
        this.selectedLogin = null;
        console.error(e);
      });
  }

  cancelUpdateLogin() {
    this.selectedLogin = null;
  }

  updateLogin(login: ApplicationUserDTO) {
    this.$userservice
      .addUpdate(login)
      .then((response) => {
        login.OrigUserName = response.OrigUserName;
        login.Id = response.Id;

        if (this.logins.indexOf(login) === -1) {
          this.logins.push(login);
        }

        this.selectedLogin = null;
      })
      .catch((e: any) => {
        this.selectedLogin = null;
        console.error(e);
      });
  }

  // #endregion

  // #region Sites
  addSite() {
    if (!this.selectedCustomer) {
      return;
    }

    this.selectedSite = {
      CustomerId: this.selectedCustomer.Id,
      CustomerName: this.selectedCustomer.Name,
    } as SiteDTO;
  }

  removeSite(idToDelete: number) {
    this.$siteservice
      .markasdeleted(idToDelete)
      .then((response) => {
        if (response) {
          this.sites = this.sites.filter((value) => value.Id !== idToDelete);
          this.selectedSite = null;
        }
      })
      .catch((e: any) => {
        this.selectedSite = null;
        console.error(e);
      });
  }

  cancelUpdateSite() {
    this.selectedSite = null;
  }

  updateSite(siteToUpdate: SiteDTO) {
    if (siteToUpdate === null) {
      console.error("The site to update is null");
      return;
    }

    this.$siteservice
      .addUpdate(siteToUpdate)
      .then((response) => {
        siteToUpdate.Id = response.Id;

        if (this.sites.indexOf(siteToUpdate) === -1) {
          this.sites.push(siteToUpdate);
        }

        this.selectedSite = null;
      })
      .catch((e: any) => {
        this.selectedSite = null;
        console.error(e);
      });
  }

  // #endregion

  // #region Legal Entities

  selectLegalEntity(id: number) {
    if (this.selectedCustomer && id) {
      this.selectedCustomer.LegalEntity = this.legalentities.find(
        (entity) => entity.Id === id,
      );
    }
  }

  legalEntitySelected() {
    if (!this.selectedCustomer) {
      return;
    }

    if (
      !this.selectedCustomer.LegalEntity ||
      this.selectedCustomer.LegalEntity.Name === "New Entity" ||
      this.selectedCustomer.LegalEntity.Name === "---"
    ) {
      this.selectedLegalEntity = {} as LegalEntityDTO;
    }
  }

  addLegalEntity() {
    this.selectedLegalEntity = {} as LegalEntityDTO;
  }

  removeLegalEntity(legalentity: LegalEntityDTO) {
    this.$legalentityservice
      .markasdeleted(legalentity.Id)
      .then((response) => {
        if (response) {
          this.legalentities.splice(this.legalentities.indexOf(legalentity), 1);

          if (this.selectedCustomer?.LegalEntityId === legalentity.Id) {
            this.selectedCustomer.LegalEntityId = undefined;
            this.selectedCustomer.LegalEntity = undefined;
          }

          this.selectedLegalEntity = null;
        }
      })
      .catch((e: any) => {
        this.selectedLegalEntity = null;
        console.error(e);
      });
  }

  cancelUpdateLegalEntity() {
    this.selectedLegalEntity = null;
  }

  updateLegalEntity(
    customerToUpdate: CustomerDTO,
    legalEntityToUpdate: LegalEntityDTO,
  ) {
    if (!customerToUpdate || !legalEntityToUpdate) {
      return;
    }

    this.$legalentityservice
      .addUpdate(legalEntityToUpdate)
      .then((response) => {
        if (this.legalentities.indexOf(legalEntityToUpdate) === -1) {
          this.legalentities.push(legalEntityToUpdate);
          customerToUpdate.LegalEntity = response;
          customerToUpdate.LegalEntityId = response.Id;
        }

        this.selectedLegalEntity = null;
      })
      .catch((e: any) => {
        this.selectedLegalEntity = null;
        console.error(e);
      });
  }

  // #endregion legal entities
}
