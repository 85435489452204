import angular from "angular";
import { AdminService } from "./services/AdminService";
import { AgencyService } from "./services/AgencyService";
import { AuthService } from "./services/AuthService";
import { BillValidationService } from "./services/BillValidationService";
import { ContractOffersService } from "./services/ContractOffersService";
import { ContractService } from "./services/ContractService";
import { CreditNoteService } from "./services/CreditNoteService";
import { CustomerService } from "./services/CustomerService";
import { DataAggregatorService } from "./services/DataAggregatorService";
import { LegalEntityService } from "./services/LegalEntityService";
import { MapService } from "./services/MapService";
import { MeterAgencyAuthorityService } from "./services/MeterAgencyAuthorityService";
import { MeterService } from "./services/MeterService";
import { MeterUsageManualService } from "./services/MeterUsageManualService";
import { OperatorService } from "./services/OperatorService";
import { ReportService } from "./services/ReportService";
import { RoleService } from "./services/RoleService";
import { SearchService } from "./services/SearchService";
import { SiteService } from "./services/SiteService";
import { SupplierService } from "./services/SupplierService";
import { TenderService } from "./services/TenderService";
import { UBTaskService } from "./services/UBTaskService";
import { UserService } from "./services/UserService";

export function registerServices() {
  angular
    .module("ccqapp")
    .service("AdminService", AdminService)
    .service("AgencyService", AgencyService)
    .service("AuthService", AuthService)
    .service("BillValidationService", BillValidationService)
    .service("ContractOffersService", ContractOffersService)
    .service("ContractService", ContractService)
    .service("CreditNoteService", CreditNoteService)
    .service("CustomerService", CustomerService)
    .service("DataAggregatorService", DataAggregatorService)
    .service("LegalEntityService", LegalEntityService)
    .service("MapService", MapService)
    .service("MeterAgencyAuthorityService", MeterAgencyAuthorityService)
    .service("MeterService", MeterService)
    .service("MeterUsageManualService", MeterUsageManualService)
    .service("OperatorService", OperatorService)
    .service("ReportService", ReportService)
    .service("RoleService", RoleService)
    .service("SearchService", SearchService)
    .service("SiteService", SiteService)
    .service("SupplierService", SupplierService)
    .service("TenderService", TenderService)
    .service("UBTaskService", UBTaskService)
    .service("UserService", UserService);
}
