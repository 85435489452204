import angular from "angular";
import { BaseService } from "../filesfromccqbase/BaseService";
import { BroadcastService } from "../filesfromccqbase/BroadcastService";
import { CustomerDTO } from "@js/oldmodels/DTOs/CustomerDTO.cs.d";

export class CustomerService extends BaseService<CustomerDTO, number> {
  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/customer";
    this.$broadcastBusinessNameSingular = "Customer";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }
  fetchAll(): ng.IPromise<CustomerDTO[]> {
    let defer = this.$q.defer<CustomerDTO[]>();
    this.fetchAllInternal(
      this.$broadcastScreen,
      "Unable to get a list of " +
        this.$broadcastBusinessNamePlural +
        " from the  Please try again shortly.",
    ).then((response) => {
      // Convert JSON addresses to objects.
      angular.forEach(response, (customer, index) => {
        if (customer.Address) {
          try {
            customer.Address = JSON.parse(customer.Address);
          } catch (e) {
            customer.Address = {} as any;
          }
        } else {
          customer.Address = {} as any;
        }
      });
      defer.resolve(response);
    });
    return defer.promise;
  }
  fetch(id: number): ng.IPromise<CustomerDTO> {
    let defer = this.$q.defer<CustomerDTO>();
    this.fetchInternal(
      id,
      this.$broadcastScreen,
      "Unable to get a list of " +
        this.$broadcastBusinessNamePlural +
        " from the  Please try again shortly.",
    ).then((response) => {
      // Convert JSON addresses to objects.
      if (response.Address) {
        try {
          response.Address = JSON.parse(response.Address);
        } catch (e) {
          response.Address = {} as any;
        }
      } else {
        response.Address = {} as any;
      }
      defer.resolve(response);
    });
    return defer.promise;
  }
  addUpdate(dtos: CustomerDTO): ng.IPromise<CustomerDTO> {
    // Convert object addresses to JSON.
    let customerCopy = angular.copy(dtos);
    customerCopy.Address = JSON.stringify(dtos.Address);
    if (customerCopy.LegalEntity && customerCopy.LegalEntity.Address) {
      customerCopy.LegalEntity.Address = JSON.stringify(
        dtos.LegalEntity.Address,
      );
    }
    return this.addUpdateInternal(
      customerCopy,
      this.$broadcastScreen,
      "Unable to add update " +
        this.$broadcastBusinessNamePlural +
        " from the  Please try again shortly.",
    );
  }

  fetchCustomersForAgency(id: number): ng.IPromise<CustomerDTO[]> {
    let defer = this.$q.defer<CustomerDTO[]>();
    this.$http
      .get("/api/customer/agency/" + id.toString())
      .then((response) => {
        // Convert JSON addresses to objects.
        angular.forEach(response.data as CustomerDTO[], (customer, index) => {
          if (customer.Address) {
            try {
              customer.Address = JSON.parse(customer.Address);
            } catch (e) {
              customer.Address = {} as any;
            }
          } else {
            customer.Address = {} as any;
          }
        });

        defer.resolve(response.data as CustomerDTO[]);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "customers",
          "Unable to get a list of customers from the  Please try again shortly.",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  fetchNew(id: number): ng.IPromise<CustomerDTO> {
    let defer = this.$q.defer<CustomerDTO>();
    this.$http
      .get(this.$baseAddress + "/newfetch/" + id.toString())
      .then((response) => {
        defer.resolve(response.data as CustomerDTO);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          this.$broadcastScreen,
          "Unable to get a list of " +
            this.$broadcastBusinessNamePlural +
            " from the  Please try again shortly.",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  ListNew(): ng.IPromise<CustomerDTO[]> {
    let defer = this.$q.defer<CustomerDTO[]>();
    this.$http
      .get(this.$baseAddress + "/newlist")
      .then((response) => {
        defer.resolve(response.data as CustomerDTO[]);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          this.$broadcastScreen,
          "Unable to get a list of " +
            this.$broadcastBusinessNamePlural +
            " from the  Please try again shortly.",
        );
        defer.reject(response);
      });
    return defer.promise;
  }
}
