import { MeterService } from "../services/MeterService";
import { AuthService } from "../services/AuthService";
import { TenderService } from "../services/TenderService";
import { BroadcastService } from "../filesfromccqbase/BroadcastService";
import { SearchService } from "../services/SearchService";
import { MeterDTO } from "@js/oldmodels/DTOs/MeterDTO.cs.d";

export class ListTenderRequestsController {
  meters: MeterDTO[];
  selectedMeters: MeterDTO[];
  fetchingMeters: boolean;
  fetchingReport: boolean;

  reportData: string;
  reportFileName: string;
  reportReady: boolean;

  error: boolean;

  startDateOverride: Date;
  utilityType: string;

  static $inject = [
    "$routeParams",
    "AuthService",
    "MeterService",
    "TenderService",
    "BroadcastService",
    "SearchService",
  ];

  constructor(
    private $routeParams: ng.route.IRouteParamsService,
    private $auth: AuthService,
    private $meterservice: MeterService,
    private $tenderservice: TenderService,
    private $broadcastservice: BroadcastService,
    private $search: SearchService,
  ) {
    this.fetchMeters();
  }

  fetchMeters(): void {
    this.error = false;
    this.fetchingMeters = true;
    this.$meterservice
      .ListNew()
      .then((response) => {
        this.meters = response;
        this.preSelectedItem();
      })
      .catch((response) => {
        this.error = true;
      })
      .finally(() => {
        this.fetchingMeters = false;
      });
  }

  private preSelectedItem() {
    if (this.$routeParams.meterId) {
      let selectedMeter = this.meters.find((value, index) => {
        return value.Id === parseInt(this.$routeParams.meterId);
      });

      if (!this.selectedMeters) {
        this.selectedMeters = [];
      }

      this.selectedMeters.push(selectedMeter);
    }
  }

  // #region Search

  search(needle: string): ng.IPromise<any[]> {
    if (!needle) return null;

    return this.$search.search(needle, true, false, false, false);
  }

  selectItem(item: any, type: string): void {
    if (!this.selectedMeters) {
      this.selectedMeters = [];
    }

    let meter: MeterDTO = item as MeterDTO;
    this.selectedMeters.push(meter);
  }

  removeItem(meter: MeterDTO) {
    this.selectedMeters.splice(this.selectedMeters.indexOf(meter), 1);
  }

  // #endregion Search

  fetchTenderReport() {
    this.fetchingReport = true;
    this.reportReady = false;

    this.reportFileName = "TenderReport-" + new Date() + ".csv";

    this.$tenderservice
      .buildTenderForMetersAndFetchCSV(
        this.selectedMeters.map((meter) => {
          return meter.Id;
        }),
        this.startDateOverride,
      )
      .then((response) => {
        this.reportData = response;
        this.reportReady = true;
      })
      .finally(() => {
        this.fetchingReport = false;
      });
  }
}
