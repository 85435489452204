import { CustomerDTO } from "@js/oldmodels/DTOs/CustomerDTO.cs.d";
import { LegalEntityDTO } from "@js/oldmodels/DTOs/LegalEntityDTO.cs.d";
import { MeterDTO } from "@js/oldmodels/DTOs/MeterDTO.cs.d";
import { SiteDTO } from "@js/oldmodels/DTOs/SiteDTO.cs.d";
import { BroadcastService } from "../filesfromccqbase/BroadcastService";
import { AuthService } from "../services/AuthService";
import { CustomerService } from "../services/CustomerService";
import { LegalEntityService } from "../services/LegalEntityService";
import { MapService } from "../services/MapService";
import { MeterService } from "../services/MeterService";
import { SiteService } from "../services/SiteService";

export class ListSitesController {
  selectedSection: string;
  selectedUtilitySubSection: "Electricity" | "Gas" | "Water" = "Electricity";

  sites: SiteDTO[];
  selectedSite: SiteDTO;

  fetchingSites: boolean;
  fetchingsite: boolean;

  addingMeter: boolean;

  meters: MeterDTO[];
  selectedMeter: MeterDTO;

  fetchingMeters: boolean;
  fetchingMeter: boolean;

  siteform: ng.IFormController;
  meterform: ng.IFormController;

  legalentities: LegalEntityDTO[];
  selectedLegalEntity: LegalEntityDTO;

  customers: CustomerDTO[];
  customer: CustomerDTO;
  fetchingCustomers: boolean;

  // #region Maps

  siteLocation: any;
  mapCentre: any;

  mapOptions: any = {
    zoom: 11,
    disableDefaultUI: true,
    styles: [
      {
        featureType: "administrative",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#444444",
          } as google.maps.MapTypeStyler,
        ],
      },
      {
        featureType: "landscape",
        elementType: "all",
        stylers: [
          {
            color: "#f2f2f2",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "all",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "all",
        stylers: [
          {
            saturation: -100,
          },
          {
            lightness: 45,
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "all",
        stylers: [
          {
            visibility: "simplified",
          },
        ],
      },
      {
        featureType: "road.arterial",
        elementType: "labels.icon",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "transit",
        elementType: "all",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "all",
        stylers: [
          {
            color: "#46bcec",
          },
          {
            visibility: "on",
          },
        ],
      },
    ],
  };

  markerOptions: any = {
    url: "/img/icons/mapmarker.svg",
    title: "Site Location",
  };

  // #endregion

  static $inject = [
    "$rootScope",
    "$routeParams",
    "AuthService",
    "SiteService",
    "CustomerService",
    "MeterService",
    "MapService",
    "LegalEntityService",
    "BroadcastService",
  ];

  constructor(
    private $rootScope: ng.IRootScopeService,
    private $routeParams: ng.route.IRouteParamsService,
    private $auth: AuthService,
    private $siteservice: SiteService,
    private $customerservice: CustomerService,
    private $meterservice: MeterService,
    private $mapservice: MapService,
    private $legalentityservice: LegalEntityService,
    private $broadcastservice: BroadcastService,
  ) {
    this.$rootScope.$on(
      "selected-customer-updated",
      (event: ng.IAngularEvent) => {
        this.updateSites();
      },
    );

    this.updateSites();

    this.$legalentityservice.fetchAll().then((response) => {
      this.legalentities = response;
      this.legalentities.push({ Name: "---" } as LegalEntityDTO);
      this.legalentities.push({
        Name: "New Entity",
      } as LegalEntityDTO);
    });

    this.updateCustomers();
  }

  updateSites() {
    this.fetchingSites = true;
    if ((this.$rootScope as any).selectedCustomer) {
      // Fetch meters for a single company.
      this.fetchingSites = true;
      this.$siteservice
        .fetchSitesForCustomer((this.$rootScope as any).selectedCustomer.Id)
        .then((response) => {
          this.sites = response;
          this.preSelectedItem();
        })
        .finally(() => {
          this.fetchingSites = false;
        });
    } else {
      // Fetch all sites available to the user.
      this.$siteservice
        .ListNew()
        .then((response) => {
          this.sites = response;
          this.preSelectedItem();
        })
        .finally(() => {
          this.fetchingSites = false;
        });
    }
  }

  private updateCustomers() {
    this.fetchingCustomers = true;
    this.$customerservice
      .ListNew()
      .then((response) => {
        this.customers = response;
        this.preSelectedItem();
      })
      .finally(() => {
        this.fetchingCustomers = false;
      });

    if (!this.$auth.hasPermission("Customers", "Read")) {
      return;
    }
  }

  private preSelectedItem() {
    if (this.$routeParams.siteId) {
      let selectedSite = this.sites.find((value, index) => {
        return value.Id === parseInt(this.$routeParams.siteId);
      });

      this.selectSite(selectedSite);
    }

    if (this.customers && this.$routeParams.customerId) {
      let selectedCustomer = this.customers.find((value, index) => {
        return value.Id === parseInt(this.$routeParams.customerId);
      });

      if (selectedCustomer) {
        this.selectedSite = {
          CustomerId: selectedCustomer.Id,
          CustomerName: selectedCustomer.Name,
        } as SiteDTO;
        this.selectedSection = "details";
      }
    }
  }

  selectSite(site: SiteDTO) {
    this.selectedSite = site;

    this.selectLegalEntity(this.selectedSite.LegalEntityId);

    if (
      this.selectedSite &&
      this.selectedSite.Address &&
      (this.selectedSite.Address as any).Latitude &&
      (this.selectedSite.Address as any).Longitude
    ) {
      this.mapCentre = {
        latitude: parseFloat((this.selectedSite.Address as any).Latitude),
        longitude: parseFloat((this.selectedSite.Address as any).Longitude),
      };
      this.siteLocation = {
        latitude: parseFloat((this.selectedSite.Address as any).Latitude),
        longitude: parseFloat((this.selectedSite.Address as any).Longitude),
      };
    } else {
      delete this.mapCentre;
      delete this.siteLocation;
    }

    this.fetchingMeters = true;
    this.$meterservice
      .fetchMetersForSite(site.Id)
      .then((response) => {
        this.meters = response;
        this.fetchingMeters = false;
      })
      .catch((response) => {
        this.fetchingMeters = false;
      });

    this.selectedSection = "details";
  }

  createSite() {
    this.selectedSection = "details";
    this.selectedSite = {} as SiteDTO;

    if ((this.$rootScope as any).selectedCustomer) {
      this.selectedSite.CustomerId = (
        this.$rootScope as any
      ).selectedCustomer.Id;
      this.selectedSite.LegalEntityId = this.legalentities.find(
        (value, index) => {
          return (
            ((this.$rootScope as any).selectedCustomer as CustomerDTO)
              .LegalEntityId === value.Id
          );
        },
      ).Id;
    }
  }

  cancel() {
    if (!this.selectedSite.Id) {
      delete this.selectedSite;
      this.siteform.$setPristine();
      return;
    }

    this.fetchingsite = true;
    this.$siteservice.fetchNew(this.selectedSite.Id).then((response) => {
      this.selectedSite = response;
      this.fetchingsite = false;
      this.siteform.$setPristine();
      this.sites.filter((site) => site.Id == response.Id)[0].Name =
        response.Name; //reset name in the list also incase renamed on screen then cancel
    });
  }

  //TODO: consider add legal entity in one round trip via DTO on server
  save() {
    if (!this.selectedSite.LegalEntityId) {
      this.selectedSite.LegalEntity = {
        Name: this.selectedSite.Name,
      } as LegalEntityDTO;

      this.$legalentityservice
        .addUpdate(this.selectedSite.LegalEntity)
        .then((response) => {
          this.selectedSite.LegalEntityId = response.Id;
          this.legalentities.push(response);
          this.saveSite();
        });
    } else {
      this.saveSite();
    }
  }

  saveSite() {
    this.$siteservice.addUpdate(this.selectedSite).then((response) => {
      this.selectedSite.Id = response.Id;
      if (!this.sites) {
        this.sites = [];
      }

      let matches: SiteDTO[] = this.sites.filter((value, index) => {
        return value.Id == response.Id;
      });

      if (!matches || matches.length === 0) {
        this.sites.push(this.selectedSite);
      }

      this.siteform.$setPristine();
      this.updateSites();
    });
  }

  delete() {
    this.$siteservice.markasdeleted(this.selectedSite.Id).then((response) => {
      if (response) {
        this.sites.splice(this.sites.indexOf(this.selectedSite), 1);
        this.siteform.$setPristine();
        delete this.selectedSite;
      }
    });
  }

  // #region Meters
  addMeter() {
    this.selectedMeter = {
      AssociatedSiteId: this.selectedSite.Id,
      AssociatedSiteName: this.selectedSite.Name,
    } as MeterDTO;
  }

  removeMeter(meter: MeterDTO) {
    this.$meterservice.markasdeleted(meter.Id).then((response) => {
      this.meters.splice(this.meters.indexOf(this.selectedMeter), 1);
      delete this.selectedMeter;
    });
  }

  cancelUpdateMeter() {
    delete this.selectedMeter;
  }

  updateMeter(meter: MeterDTO) {
    this.$meterservice
      .addUpdateNew(meter)
      .then((response) => {
        if (this.meters.indexOf(meter) === -1) {
          this.meters.push(meter);
        }
        delete this.selectedMeter;
      })
      .catch((response) => {
        delete this.selectedMeter;
      });
  }

  // #endregion

  updateGeoPosition() {
    this.$mapservice
      .getCoordinates((this.selectedSite.Address as any).Postcode)
      .then((response) => {
        if (response) {
          this.mapCentre = {
            latitude: parseFloat(response.lat),
            longitude: parseFloat(response.lon),
          };
          this.siteLocation = {
            latitude: parseFloat(response.lat),
            longitude: parseFloat(response.lon),
          };

          (this.selectedSite.Address as any).Latitude = response.lat;
          (this.selectedSite.Address as any).Longitude = response.lon;
        }
      });
  }

  // #region Legal Entities

  selectLegalEntity(id: number) {
    if (this.selectedSite && id) {
      this.selectedSite.LegalEntity = this.legalentities.find(
        (entity, index) => {
          return entity.Id === id;
        },
      );
    }
  }

  //addLegalEntity() {
  //    this.selectedLegalEntity = {
  //    } as LegalEntityDTO;
  //}

  removeLegalEntity(legalentity: LegalEntityDTO) {
    this.$legalentityservice.markasdeleted(legalentity.Id).then((response) => {
      this.legalentities.splice(
        this.legalentities.indexOf(this.selectedLegalEntity),
        1,
      );
      if (this.selectedSite.LegalEntityId === legalentity.Id) {
        delete this.selectedSite.LegalEntityId;
        delete this.selectedSite.LegalEntity;
      }
      delete this.selectedLegalEntity;
    });
  }

  cancelUpdateLegalEntity() {
    delete this.selectedLegalEntity;
  }

  updateLegalEntity(legalentity: LegalEntityDTO) {
    this.$legalentityservice
      .addUpdate(legalentity)
      .then((response) => {
        if (this.legalentities.indexOf(legalentity) === -1) {
          this.legalentities.push(legalentity);
          this.selectedSite.LegalEntity = response;
          this.selectedSite.LegalEntityId = response.Id;
        }
        delete this.selectedLegalEntity;
      })
      .catch((response) => {
        delete this.selectedLegalEntity;
      });
  }

  // #endregion legal entities

  //checks to see if the associated customer has warnings enabled
  checkIfCustomerHasWarningsEnabled(id: number) {
    this.customer = this.customers.find((cust) => cust.Id == id);
    return this.customer.ContractWarningsEnabled;
  }
}
