export class AdminServiceBase {
  constructor(
    protected $http: ng.IHttpService,
    protected $cookies: ng.cookies.ICookiesService,
    protected $rootScope: ng.IRootScopeService,
    protected $q: ng.IQService,
    protected $timeout: ng.ITimeoutService,
  ) {}

  isGuid = function (stringToTest: string) {
    if (!stringToTest) {
      return false;
    }
    if (stringToTest[0] === "{") {
      stringToTest = stringToTest.substring(1, stringToTest.length - 1);
    }
    var regexGuid =
      /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi;
    return regexGuid.test(stringToTest);
  };
}
