import { PageWithTotalCountDTO } from "@js/oldmodels/DTOs/PageWithTotalCountDTO.cs.d";
import { BroadcastService } from "./BroadcastService";

export class BaseService<T, IDT> {
  protected $baseAddress: string; //base route
  protected $broadcastScreen: string;
  protected $broadcastBusinessNameSingular: string;
  protected $broadcastBusinessNamePlural: string;

  constructor(
    protected $http: ng.IHttpService,
    protected $cookies: ng.cookies.ICookiesService,
    protected $rootScope: ng.IRootScopeService,
    protected $q: ng.IQService,
    protected $timeout: ng.ITimeoutService,
    protected $broadcastservice: BroadcastService,
  ) {
    this.$broadcastScreen = "NotSet";
    this.$broadcastBusinessNamePlural = "NotSet";
    this.$broadcastBusinessNameSingular = "NotSet";
  }

  //paging with filter
  fetchpageneedle(
    needle: string,
    page: number,
    count: number,
  ): ng.IPromise<PageWithTotalCountDTO> {
    let defer = this.$q.defer<PageWithTotalCountDTO>();
    this.$http
      .get(
        this.$baseAddress +
          "/pagedneedle?needle=" +
          needle +
          "&page=" +
          page +
          "&Count=" +
          count,
      )
      .then((response) => {
        defer.resolve(response.data as PageWithTotalCountDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  protected fetchAllInternal(
    screen: string,
    message: string,
  ): ng.IPromise<T[]> {
    let defer = this.$q.defer<T[]>();
    this.$http
      .get(this.$baseAddress + "/list")
      .then((response) => {
        defer.resolve(response.data as T[]);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(screen, message);
        defer.reject(response);
      });
    return defer.promise;
  }

  fetchAll(): ng.IPromise<T[]> {
    return this.fetchAllInternal(
      this.$broadcastScreen,
      "Unable to get a list of " +
        this.$broadcastBusinessNamePlural +
        " from the  Please try again shortly.",
    );
  }

  protected fetchInternal(
    id: IDT,
    screen: string,
    message: string,
  ): ng.IPromise<T> {
    let defer = this.$q.defer<T>();
    this.$http
      .get(this.$baseAddress + "/fetch/" + id.toString())
      .then((response) => {
        defer.resolve(response.data as T);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(screen, message);
        defer.reject(response);
      });
    return defer.promise;
  }

  fetch(id: IDT): ng.IPromise<T> {
    return this.fetchInternal(
      id,
      this.$broadcastScreen,
      "Unable to get a list of " +
        this.$broadcastBusinessNamePlural +
        " from the  Please try again shortly.",
    );
  }

  protected countInternal(
    screen: string,
    message: string,
  ): ng.IPromise<number> {
    let defer = this.$q.defer<number>();
    this.$http
      .get(this.$baseAddress + "/count")
      .then((response) => {
        defer.resolve(response.data as number);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(screen, message);
        defer.reject(response);
      });
    return defer.promise;
  }

  count(): ng.IPromise<number> {
    return this.countInternal(
      this.$broadcastScreen,
      "Unable to get count of " +
        this.$broadcastBusinessNamePlural +
        " from the  Please try again shortly.",
    );
  }

  protected fetchpageInternal(
    needle: string,
    page: number,
    count: number,
    screen: string,
    message: string,
  ): ng.IPromise<PageWithTotalCountDTO> {
    let defer = this.$q.defer<PageWithTotalCountDTO>();
    this.$http
      .get(
        this.$baseAddress +
          "/paged?needle=" +
          needle +
          "&page=" +
          page +
          "&Count=" +
          count,
      )
      .then((response) => {
        defer.resolve(response.data as PageWithTotalCountDTO);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(screen, message);
        defer.reject(response);
      });
    return defer.promise;
  }
  fetchpage(
    needle: string,
    page: number,
    count: number,
  ): ng.IPromise<PageWithTotalCountDTO> {
    return this.fetchpageInternal(
      needle,
      page,
      count,
      this.$broadcastScreen,
      "Unable to get a list of " +
        this.$broadcastBusinessNamePlural +
        " from the  Please try again shortly.",
    );
  }

  protected processDtoBeforeAddUpdate(dto: T): T {
    return dto;
  }

  addUpdateInternal(dto: T, screen: string, message: string): ng.IPromise<T> {
    let defer = this.$q.defer<T>();

    dto = this.processDtoBeforeAddUpdate(dto);

    this.$http
      .post(
        this.$baseAddress + "/addupdate",
        JSON.stringify(dto, this.jsonPropertyReplacer),
      )
      .then((response) => {
        defer.resolve(response.data as T);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(screen, message);
        defer.reject(response);
      });
    return defer.promise;
  }
  jsonPropertyReplacer(key: string, value: any) {
    return value; //can be overidden to disable properties form going up
  }
  addUpdate(dtos: T): ng.IPromise<T> {
    return this.addUpdateInternal(
      dtos,
      this.$broadcastScreen,
      "Unable to add update " +
        this.$broadcastBusinessNamePlural +
        " from the  Please try again shortly.",
    );
  }
  addUpdatereturnonlyidInternal(
    dto: T,
    screen: string,
    message: string,
  ): ng.IPromise<number> {
    let defer = this.$q.defer<number>();

    dto = this.processDtoBeforeAddUpdate(dto);

    this.$http
      .post(
        this.$baseAddress + "/addupdatereturnonlyid",
        JSON.stringify(dto, this.jsonPropertyReplacer),
      )
      .then((response) => {
        defer.resolve(response.data as number);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(screen, message);
        defer.reject(response);
      });
    return defer.promise;
  }
  addUpdatereturnonlyid(dtos: T): ng.IPromise<number> {
    return this.addUpdatereturnonlyidInternal(
      dtos,
      this.$broadcastScreen,
      "Unable to add update " +
        this.$broadcastBusinessNamePlural +
        " from the  Please try again shortly.",
    );
  }
  addUpdatelistInternal(
    dto: T[],
    screen: string,
    message: string,
  ): ng.IPromise<T[]> {
    let defer = this.$q.defer<T[]>();
    this.$http
      .post(this.$baseAddress + "/addupdatelist", JSON.stringify(dto))
      .then((response) => {
        defer.resolve(response.data as T[]);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(screen, message);
        defer.reject(response);
      });
    return defer.promise;
  }
  addUpdatelist(dtos: T[]): ng.IPromise<T[]> {
    return this.addUpdatelistInternal(
      dtos,
      this.$broadcastScreen,
      "Unable to add update list " +
        this.$broadcastBusinessNamePlural +
        " from the  Please try again shortly.",
    );
  }

  addUpdatelistreturnonlyidsInternal(
    dto: T[],
    screen: string,
    message: string,
  ): ng.IPromise<number[]> {
    let defer = this.$q.defer<number[]>();
    this.$http
      .post(
        this.$baseAddress + "/addupdatelistreturnonlyid",
        JSON.stringify(dto),
      )
      .then((response) => {
        defer.resolve(response.data as number[]);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(screen, message);
        defer.reject(response);
      });
    return defer.promise;
  }
  addUpdatelistreturnonlyids(dtos: T[]): ng.IPromise<number[]> {
    return this.addUpdatelistreturnonlyidsInternal(
      dtos,
      this.$broadcastScreen,
      "Unable to add update list " +
        this.$broadcastBusinessNamePlural +
        " from the  Please try again shortly.",
    );
  }

  deleteInternal(
    id: IDT,
    screen: string,
    message: string,
  ): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.$http
      .delete(this.$baseAddress + "/remove/" + id)
      .then(() => {
        defer.resolve(true);
      })
      .catch(() => {
        this.$broadcastservice.broadcastError(screen, message);
        defer.reject(false);
      });
    return defer.promise;
  }
  delete(id: IDT): ng.IPromise<boolean> {
    return this.deleteInternal(
      id,
      this.$broadcastScreen,
      "Unable to delete " +
        this.$broadcastBusinessNamePlural +
        " from the  Please try again shortly.",
    );
  }

  markasdeletedInternal(
    id: IDT,
    screen: string,
    message: string,
  ): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.$http
      .delete(this.$baseAddress + "/markasdeleted/" + id + "/")
      .then(() => {
        defer.resolve(true);
      })
      .catch(() => {
        this.$broadcastservice.broadcastError(screen, message);
        defer.reject(false);
      });
    return defer.promise;
  }
  markasdeleted(id: IDT): ng.IPromise<boolean> {
    return this.markasdeletedInternal(
      id,
      this.$broadcastScreen,
      "Unable to mark as deleted " +
        this.$broadcastBusinessNamePlural +
        " from the  Please try again shortly.",
    );
  }

  isSavedObject(dto: T): boolean {
    return dto && (dto as any).Id && (dto as any).Id > 0;
  }
}
