import { LegalEntityDTO } from "@js/oldmodels/DTOs/LegalEntityDTO.cs.d";
import { LegalEntityService } from "../../../services/LegalEntityService";

export class LegalEntityFormComponent implements ng.IComponentOptions {
  public bindings: any;
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.bindings = {
      legalentity: "=",
    };
    this.controller = LegalEntityFormController;
    this.templateUrl =
      "js/filesfromccqbase/Components/legalentityform/legalentityform.html";
  }
}

export class LegalEntityFormController {
  public legalentity: LegalEntityDTO;

  static $inject = ["$parse", "LegalEntityService"];
  constructor(
    private $parse: ng.IParseService,
    public legalentityService: LegalEntityService,
  ) {}
}
