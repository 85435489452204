export enum BillingUnitEnum {
  Pence_per_Kwh = 1,
  Pence_per_Day = 2,
  Pence_per_Month = 3,
  Pence_per_Quarter = 4,
  Pounds_per_Kwh = 5,
  Pounds_per_Day = 6,
  Pounds_per_Month = 7,
  Pounds_per_Quarter = 8,
  Pounds_per_Year = 9,
}
