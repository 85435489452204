import { AccountSettingsController } from "./controllers/AccountSettingsController";
import { AdminController } from "./controllers/AdminController";
import { AgencyController } from "./controllers/AgencyController";
import { AgencyDetailController } from "./controllers/AgencyDetailController";
//import { BillValidationController } from "./controllers/BillValidationController";
import { DashboardController } from "./controllers/DashboardController";
import { DataAggregatorController } from "./controllers/DataAggregatorController";
import { ExportMeterReadingsController } from "./controllers/ExportMeterReadingsController";
import { ImportMeterReadingsController } from "./controllers/ImportMeterReadingsController";
import { IndexController } from "./controllers/IndexController";
import { ListAgenciesController } from "./controllers/ListAgenciesController";
import { ListBillsController } from "./controllers/ListBillsController";
import { ListConsultantsController } from "./controllers/ListConsultantsController";
import { ListCustomersController } from "./controllers/ListCustomersController";
import { ListExportsController } from "./controllers/ListExportsController";
import { ListMeterAgencyAuthoritiesController } from "./controllers/ListMeterAgencyAuthorityController";
import { ListMetersController } from "./controllers/ListMetersController";
import { ListSitesController } from "./controllers/ListSitesController";
import { ListTenderRequestsController } from "./controllers/ListTenderRequestsController";
import { ListUsersController } from "./controllers/ListUsersController";
import { LoginController } from "./controllers/LoginController";
import { MeterReadingController } from "./controllers/MeterReadingController";
import { NewContractOfferController } from "./controllers/NewContractOfferController";
//import { ReportCarbonController } from "./controllers/ReportCarbonController";
import { ReportComparisonController } from "./controllers/ReportComparisonController";
import { ReportEfficiencyController } from "./controllers/ReportEfficiencyController";
import { ReportUsageController } from "./controllers/ReportUsageController";
import { UBTaskController } from "./controllers/UBTaskController";
import { UserManagementController } from "./controllers/UserManagementController";

export function registerControllers() {
  angular
    .module("ccqapp")
    .controller("AccountSettingsController", AccountSettingsController)
    .controller("AdminController", AdminController)
    .controller("AgencyController", AgencyController)
    .controller("AgencyDetailController", AgencyDetailController)
    .controller("DashboardController", DashboardController)
    .controller("DataAggregatorController", DataAggregatorController)
    .controller("ExportMeterReadingsController", ExportMeterReadingsController)
    .controller("ImportMeterReadingsController", ImportMeterReadingsController)
    .controller("IndexController", IndexController)
    .controller("ListAgenciesController", ListAgenciesController)
    .controller("ListBillsController", ListBillsController)
    .controller("ListConsultantsController", ListConsultantsController)
    .controller("ListCustomersController", ListCustomersController)
    .controller("ListExportsController", ListExportsController)
    .controller(
      "ListMeterAgencyAuthoritiesController",
      ListMeterAgencyAuthoritiesController,
    )
    .controller("ListMetersController", ListMetersController)
    .controller("ListSitesController", ListSitesController)
    .controller("ListTenderRequestsController", ListTenderRequestsController)
    .controller("ListUsersController", ListUsersController)
    .controller("LoginController", LoginController)
    .controller("MeterReadingController", MeterReadingController)
    .controller("NewContractOfferController", NewContractOfferController)
    .controller("ReportComparisonController", ReportComparisonController)
    .controller("ReportEfficiencyController", ReportEfficiencyController)
    .controller("ReportUsageController", ReportUsageController)
    .controller("UBTaskController", UBTaskController)
    .controller("UserManagementController", UserManagementController);
}
