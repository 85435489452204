import { CCQAuthProvider, useCCQAuth } from "@ccq/auth-lib";
import { CCQAuthClient, OidcConfigDTO } from "@react/apis/ModularCCQClient";
import AppRoutes from "@react/AppRoutes";
import { IRootScopeService } from "angular";
import { FC } from "react";
import { BrowserRouter } from "react-router-dom";

interface Props {
  $rootScope: IRootScopeService;
}

const LoginCallback: FC<Props> = ({ $rootScope }) => {
  // get the current auth provider
  let currAuthProvider = localStorage.getItem("authProvider");

  // if it doesn't exist, then set it to local
  if (currAuthProvider === null) {
    currAuthProvider = "Local";
    localStorage.setItem("authProvider", "Local");
  }

  return (
    <BrowserRouter>
      <CCQAuthProvider
        loginCallback={AppRoutes.LoginCallback}
        getOIDCConfig={() =>
          new CCQAuthClient()
            .getOidcConfigs(currAuthProvider)
            .then((result: OidcConfigDTO) => {
              const config = {
                authority: result.Authority ? result.Authority : "",
                clientId: result.ClientId ? result.ClientId : "",
                clientSecret: result.ClientSecret
                  ? result.ClientSecret
                  : undefined,
                scopes: result.Scopes ? result.Scopes : [],
              };

              return config;
            })
        }
      >
        <LoginCallbackInternal
          $rootScope={$rootScope}
          authProvider={currAuthProvider}
        />
      </CCQAuthProvider>
    </BrowserRouter>
  );
};

interface Props2 extends Props {
  authProvider: string;
}

const LoginCallbackInternal: FC<Props2> = ({ $rootScope, authProvider }) => {
  const { isAuthenticated, idToken, accessToken } = useCCQAuth();

  if (isAuthenticated) {
    switch (authProvider) {
      case "Microsoft":
        $rootScope.$emit("logged-in", accessToken);
        break;
      case "Google":
        $rootScope.$emit("logged-in", idToken);
        break;
      default:
        break;
    }
  }

  return <div>Logging In...</div>;
};

export default LoginCallback;
