import { AgencyDTO } from "@js/oldmodels/DTOs/AgencyDTO.cs.d";
import { AgencyService } from "../services/AgencyService";

export class AgencyController {
  selectedSection: string = "";

  objects: AgencyDTO[] = [];
  selectedObject: AgencyDTO | null = null;

  fetchingObjects: boolean = false;

  //addingSubobject: boolean;

  //subobjects: SubobjectDTO[];
  //selectedSubobject: SubobjectDTO;

  //fetchingSubobjects: boolean;
  //fetchingSubobject: boolean;

  agencyForm: ng.IFormController;
  //subagencyForm: ng.IFormController;

  static $inject = [
    "$routeParams",
    "AgencyService",

    //'SubobjectService',
  ];
  //private $subobjectservice: App.Services.SubobjectService
  constructor(
    private $routeParams: ng.route.IRouteParamsService,
    private $agencyservice: AgencyService,
  ) {
    this.updateObjects();
  }

  updateObjects() {
    this.fetchingObjects = true;

    // Fetch all objects.
    this.$agencyservice
      .fetchAll()
      .then((response) => {
        this.objects = response;
        this.preSelectedItem();
      })
      .finally(() => {
        this.fetchingObjects = false;
      });
  }

  private preSelectedItem() {
    if (this.$routeParams.Id) {
      let selectedObject = this.objects.find((value: AgencyDTO) => {
        return value.Id === parseInt(this.$routeParams.objectId);
      });

      if (selectedObject === undefined) {
        return;
      }

      this.selectObject(selectedObject);
    }
  }

  selectObject(object: AgencyDTO) {
    this.selectedObject = object;

    //this.fetchingSubobjects = true;
    //this.$subobjectservice.fetchSubobjectsForObject(object.Id).then((response) => {
    //    this.subobjects = response;
    //    this.fetchingSubobjects = false;
    //}).catch((response) => {
    //    this.fetchingSubobjects = false;
    //});

    this.selectedSection = "details";
  }

  createObject() {
    this.selectedSection = "details";
    this.selectedObject = {} as AgencyDTO;
  }

  save() {
    if (this.selectedObject === null) {
      return;
    }

    this.$agencyservice
      .addUpdatereturnonlyid(this.selectedObject)
      .then((response) => {
        if (this.selectedObject === null) {
          return;
        }

        this.selectedObject.Id = response;
        if (!this.objects) {
          this.objects = [];
        }

        let matches: AgencyDTO[] = this.objects.filter(
          (value: AgencyDTO) => value.Id === response,
        );

        if (!matches || matches.length === 0) {
          this.objects.push(this.selectedObject);
        }

        this.agencyForm.$setPristine();
        this.updateObjects();
      });
  }

  delete() {
    if (this.selectedObject === null) {
      return;
    }

    this.$agencyservice
      .delete(this.selectedObject.Id)
      .then((response) => {
        if (!response) {
          return;
        }

        if (this.selectedObject === null) {
          return;
        }

        this.objects.splice(this.objects.indexOf(this.selectedObject), 1);
        this.agencyForm.$setPristine();
        this.selectedObject = null;
      })
      .catch((e: any) => {
        console.error(e);
        this.selectedObject = null;
      });
  }

  //addSubobject() {
  //    this.selectedSubobject = {
  //        AssociatedObjectId: this.selectedObject.Id,
  //        AssociatedObjectName: this.selectedObject.Name
  //    } as SubobjectDTO;
  //}

  //removeSubobject(subobject: SubobjectDTO) {
  //    this.$subobjectservice.deleteSubobject(subobject.Id).then((response) => {
  //        this.subobjects.splice(this.subobjects.indexOf(this.selectedSubobject), 1);
  //        delete this.selectedSubobject;
  //    });
  //}

  //cancelUpdateSubobject() {
  //    delete this.selectedSubobject;
  //}

  //updateSubobject(subobject: SubobjectDTO) {
  //    this.$subobjectservice.addUpdateSubobject(subobject).then((response) => {
  //        if (this.subobjects.indexOf(subobject) === -1) {
  //            this.subobjects.push(subobject);
  //        }
  //        delete this.selectedSubobject;
  //    }).catch((response) => {
  //        delete this.selectedSubobject;
  //    });

  //}
}
