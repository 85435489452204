import { Button, Stack, TextField, Typography } from "@mui/material";
import { FC, useState } from "react";
import { useSignIn } from "react-auth-kit";
import {
  BrowserRouter,
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";
import { AuthErrorDetails } from "../apis/ModularCCQClient";
import AppRoutes from "../AppRoutes";
import { ErrorMessage } from "../components/ErrorMessage";
import { useAPIs } from "../hooks/useAPIs";

const SMSVerificationPageInternal: FC = () => {
  const { loginId } = useParams();
  const [otp, setOtp] = useState("");
  const [awaitingText, setAwaitingText] = useState(false);
  const signIn = useSignIn();
  const [error, setError] = useState<Partial<AuthErrorDetails>>();

  const navigate = useNavigate();

  const { otpClient } = useAPIs();
  const requestToken = async () => {
    setError(undefined);
    try {
      const authInfo = await otpClient.getTokenFromSMS(otp, Number(loginId));
      const { Token, ExpiresIn, ApplicationUser } = authInfo;
      console.log(Token);
      if (!Token) return;
      signIn({
        token: Token,
        expiresIn: ExpiresIn,
        tokenType: "Bearer",
        authState: ApplicationUser, // Get actual user info from backend
      });

      navigate(AppRoutes.Dashboard);
    } catch (e: unknown) {
      console.log(e);
      setError(e as Partial<AuthErrorDetails>);
    }
  };

  const handleSendText = async () => {
    setError(undefined);

    try {
      await otpClient.sendSMSCode(Number(loginId));
      setAwaitingText(true);
    } catch (e: unknown) {
      console.log(e);
      setError(e as Partial<AuthErrorDetails>);
    }
  };
  if (!loginId)
    return (
      <Stack
        padding={20}
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <Typography>Please provide a valid login link</Typography>
      </Stack>
    );
  return (
    <Stack
      padding={20}
      alignItems="center"
      justifyContent="center"
      height="100%"
      spacing={3}
    >
      {awaitingText ? (
        <>
          <Typography variant="h4">Please enter code from SMS.</Typography>
          <Stack spacing={3}>
            <TextField
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              label="Enter Code from SMS"
            />
            <Button
              onClick={() => requestToken()}
              disabled={!otp}
              variant="contained"
              fullWidth
            >
              Login
            </Button>
          </Stack>
        </>
      ) : (
        <Button onClick={handleSendText} variant="contained">
          Request SMS Code
        </Button>
      )}
      {error && <ErrorMessage error={error} />}
      <Typography variant="h4">or</Typography>
      <Button
        onClick={() => navigate(`${AppRoutes.OtpVerification}/${loginId}`)}
        variant="contained"
      >
        Use Authenticator App
      </Button>
    </Stack>
  );
};

const SMSVerificationPage: FC = () => (
  <BrowserRouter>
    <Routes>
      <Route
        path="/sms-verification/:loginId"
        element={<SMSVerificationPageInternal />}
      />
    </Routes>
  </BrowserRouter>
);

export default SMSVerificationPage;
