import { ApplicationUserDTO } from "@js/oldmodels/DTOs/ApplicationUserDTO.cs.d";
import { UserRoleEnum } from "@js/oldmodels/Enums/UserRoleEnum.cs.d";
import { AuthServiceBase } from "../filesfromccqbase/AuthServiceBase";
import { BroadcastService } from "../filesfromccqbase/BroadcastService";

export class AuthService extends AuthServiceBase<ApplicationUserDTO> {
  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
    "$httpParamSerializerJQLike",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    private $broadcastservice: BroadcastService,
    $httpParamSerializerJQLike: ng.IHttpParamSerializer,
  ) {
    super(http, cookies, rootScope, q, timeout, $httpParamSerializerJQLike);
  }

  getUserType(): UserRoleEnum {
    let user: ApplicationUserDTO = this.$cookies.getObject(
      "profile",
    ) as ApplicationUserDTO;
    if (!user) return null;

    return user.UserType;
  }

  resetPassword(email: string): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.$http
      .get("/api/users/passwordreset?email=" + email)
      .then((response) => {
        defer.resolve(true);
      })
      .catch((response) => {
        defer.reject(false);
      });
    return defer.promise;
  }

  changePassword(
    oldPassword: string,
    newPassword: string,
  ): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.$http
      .get(
        "/api/users/changepassword?oldPassword=" +
          oldPassword +
          "&newPassword=" +
          newPassword,
      )
      .then((response) => {
        defer.resolve(true);
      })
      .catch((response) => {
        defer.reject(false);
      });
    return defer.promise;
  }

  changeEmail(newEmail: string): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.$http
      .get("/api/users/changeemail?email=" + newEmail)
      .then((response) => {
        defer.resolve(true);
      })
      .catch((response) => {
        defer.reject(false);
      });
    return defer.promise;
  }

  hasPermission(objectType: string, actionType: string): boolean {
    let user: ApplicationUserDTO = this.$cookies.getObject(
      "profile",
    ) as ApplicationUserDTO;
    if (!user || !user.UserType) {
      return false;
    }

    // Assume admin users can do everything.
    if (
      user.UserType === UserRoleEnum.GlobalSystemAdmin &&
      objectType !== "Agency"
    ) {
      return true;
    }

    switch (objectType) {
      case "Customers":
        switch (actionType) {
          case "Read":
          case "Write":
            return (
              user.UserType === UserRoleEnum.AgencyAdmin ||
              user.UserType === UserRoleEnum.AgencyUser
            );
          default:
            return false;
        }
      case "Sites":
      case "Meters":
        switch (actionType) {
          case "Read":
            return (
              user.UserType === UserRoleEnum.AgencyAdmin ||
              user.UserType === UserRoleEnum.AgencyUser ||
              user.UserType === UserRoleEnum.CustomerAdmin ||
              user.UserType === UserRoleEnum.CustomerUser
            );
          case "Write":
            return (
              user.UserType === UserRoleEnum.AgencyAdmin ||
              user.UserType === UserRoleEnum.AgencyUser ||
              user.UserType === UserRoleEnum.CustomerAdmin
            );
          default:
            return false;
        }
      case "Agency":
        return user.UserType === UserRoleEnum.AgencyAdmin;
      default:
        return false;
    }
  }
}
