import { ContractChargeDTO } from "@js/oldmodels/DTOs/ContractChargeDTO.cs.d";
import { ContractDTO } from "@js/oldmodels/DTOs/ContractDTO.cs.d";
import { ContractRateDTO } from "@js/oldmodels/DTOs/ContractRateDTO.cs.d";
import { MeterDTO } from "@js/oldmodels/DTOs/MeterDTO.cs.d";
import { ContractService } from "../services/ContractService";

export class NewContractOfferController {
  selectedSection: string;

  selectedContract: ContractDTO;

  meters: MeterDTO[];

  fetchingContract: boolean;

  contractform: ng.IFormController;

  filter: string = "current";

  suppliers: string[] = [
    "Airtricity",
    "Atlantic",
    "Axis",
    "Better Energy",
    "British Energy",
    "British Gas",
    "CNG",
    "Co-operative Energy",
    "Corona",
    "Crown Gas & Power",
    "Daligas",
    "Dong Energy",
    "Dual Energy",
    "Ebico",
    "Ecotricity",
    "EDF Energy",
    "EON",
    "Extra Energy",
    "Extraenergy",
    "First Utility",
    "Flow Energy",
    "Gazprom",
    "GnErgy",
    "Good Energy",
    "Green Energy UK",
    "Haven Power",
    "Hudson Energy",
    "iSupply Energy",
    "LoCO2",
    "M&S Energy",
    "Npower",
    "Oink Energy",
    "Opus Energy",
    "OVO Energy",
    "Power NI",
    "Sainsbury's Energy",
    "Scottish Hydro",
    "Scottish Power",
    "Southern Electric",
    "Speark Energy",
    "SSE",
    "Swalec",
    "Utilita",
    "Utility Warehouse",
    "Woodland Trust Energy",
    "Zog Energy",
  ];

  static $inject = ["$routeParams", "ContractService"];

  constructor(
    private $routeParams: ng.route.IRouteParamsService,
    private $contractservice: ContractService,
  ) {
    this.selectedContract = {} as ContractDTO;
  }

  // #region Contracts

  cancelUpdateContract() {
    delete this.selectedContract;
  }

  updateContract(contract: ContractDTO) {
    this.$contractservice
      .addUpdateNew(contract)
      .then((response) => {
        delete this.selectedContract;
      })
      .catch((response) => {
        delete this.selectedContract;
      });
  }

  // #endregion

  // #region Rates

  addRate() {
    if (!this.selectedContract.Rates) {
      this.selectedContract.Rates = [];
    }

    this.selectedContract.Rates.push({} as ContractRateDTO);
  }

  removeRate(rate: ContractRateDTO) {
    this.selectedContract.Rates.splice(
      this.selectedContract.Rates.indexOf(rate),
      1,
    );
  }

  // #endregion

  // #region Charges

  addCharge(kva: boolean = false) {
    if (!this.selectedContract.Charges) {
      this.selectedContract.Charges = [];
    }
    let newCharge: ContractChargeDTO = {
      ChargeType: "Normal" as any,
    } as ContractChargeDTO;

    if (kva) {
      newCharge.ChargeType = "KVA" as any;
    }

    this.selectedContract.Charges.push(newCharge);
  }

  removeCharge(charge: ContractChargeDTO) {
    this.selectedContract.Charges.splice(
      this.selectedContract.Charges.indexOf(charge),
      1,
    );
  }

  // #endregion
}
