import { FileAttachmentDTO } from "@js/oldmodels/DTOs/FileAttachmentDTO.cs.d";
import { AdminServiceBase } from "../filesfromccqbase/AdminServiceBase";

export class AdminService extends AdminServiceBase {
  static $inject = ["$http", "$cookies", "$rootScope", "$q", "$timeout"];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
  ) {
    super(http, cookies, rootScope, q, timeout);
  }

  public uploadStarkData(
    filename: string,
    file: any = null,
  ): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();

    let eventattachment: FileAttachmentDTO = {
      FileLocation: file,
      FileName: filename,
    } as FileAttachmentDTO;

    this.$http
      .put("/api/report/stark", JSON.stringify(eventattachment))
      .then((response) => {
        defer.resolve(true);
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }
}
