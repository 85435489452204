import { Button, Stack, TextField, Typography } from "@mui/material";
import {
  AuthErrorDetails,
  CCQMagicLinkClient,
} from "@react/apis/ModularCCQClient";
import { FC, useEffect, useState } from "react";
import AppRoutes from "../AppRoutes";
import { ErrorMessage } from "../components/ErrorMessage";
import { useUser } from "../hooks/useUser";

const RequestEmailPageInternal: FC = () => {
  const [username, setUsername] = useState("");
  const [error, setError] = useState<Partial<AuthErrorDetails>>();
  const [sent, setSent] = useState(false);
  const user = useUser();
  //const navigate = useNavigate();

  const requestEmail = async () => {
    if (!username) return;
    setError(undefined);
    const client = new CCQMagicLinkClient();

    try {
      await client.sendLoginEmail(username);
      setSent(true);
    } catch (e: any) {
      setError(e);
    }
  };

  useEffect(() => {
    if (user) {
      window.location.href = AppRoutes.Dashboard;
    }
  }, [user]);

  return (
    <Stack direction={"row"}>
      <Stack
        alignItems="center"
        justifyContent="center"
        flex={1}
        padding={2}
        spacing={3}
      >
        {sent ? (
          <Typography component="h1">
            Your Verification email has been sent. Please check your inbox.
          </Typography>
        ) : (
          <Stack spacing={4} width={"90%"}>
            <TextField
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              label="User Name"
              fullWidth
            />
            <Button
              onClick={() => requestEmail()}
              disabled={!username}
              variant="contained"
              sx={{ padding: 1 }}
            >
              Request Login Email
            </Button>
          </Stack>
        )}
        {error && <ErrorMessage error={error} />}
      </Stack>

      <Stack
        sx={{
          backgroundImage: `none`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "center bottom",
          flex: 3.2,
          height: "90vh",
          marginTop: 1,
        }}
      ></Stack>
    </Stack>
  );
};

export const RequestEmailPage: FC = () => (
  <RequestEmailPageInternal></RequestEmailPageInternal>
);
