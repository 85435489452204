import angular from "angular";
import { BaseService } from "../filesfromccqbase/BaseService";
import { BroadcastService } from "../filesfromccqbase/BroadcastService";
import { SiteDTO } from "@js/oldmodels/DTOs/SiteDTO.cs.d";

export class SiteService extends BaseService<SiteDTO, number> {
  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/site";
    this.$broadcastBusinessNameSingular = "Site";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }

  fetchAll(): ng.IPromise<SiteDTO[]> {
    let defer = this.$q.defer<SiteDTO[]>();
    this.fetchAllInternal(
      this.$broadcastScreen,
      "Unable to get a list of " +
      this.$broadcastBusinessNamePlural +
      " from the  Please try again shortly.",
    ).then((response) => {
      // Convert JSON addresses to objects.
      angular.forEach(response, (site, index) => {
        if (site.Address) {
          try {
            site.Address = JSON.parse(site.Address);
          } catch (e) {
            site.Address = {} as any;
          }
        } else {
          site.Address = {} as any;
        }
      });
      defer.resolve(response);
    });
    return defer.promise;
  }
  fetch(id: number): ng.IPromise<SiteDTO> {
    let defer = this.$q.defer<SiteDTO>();
    this.fetchInternal(
      id,
      this.$broadcastScreen,
      "Unable to get a list of " +
      this.$broadcastBusinessNamePlural +
      " from the  Please try again shortly.",
    ).then((response) => {
      // Convert JSON addresses to objects.
      if (response.Address) {
        try {
          response.Address = JSON.parse(response.Address);
        } catch (e) {
          response.Address = {} as any;
        }
      } else {
        response.Address = {} as any;
      }
      defer.resolve(response);
    });
    return defer.promise;
  }
  addUpdate(dtos: SiteDTO): ng.IPromise<SiteDTO> {
    // Convert object addresses to JSON.
    let siteCopy = angular.copy(dtos);
    siteCopy.Address = JSON.stringify(dtos.Address);

    // Handle addresses.
    if (siteCopy.LegalEntity && siteCopy.LegalEntity.Address) {
      siteCopy.LegalEntity.Address = JSON.stringify(
        siteCopy.LegalEntity.Address,
      );
    }

    return this.addUpdateInternal(
      siteCopy,
      this.$broadcastScreen,
      "Unable to add update " +
      this.$broadcastBusinessNamePlural +
      " from the  Please try again shortly.",
    );
  }

  fetchSitesForCustomer(id: number): ng.IPromise<SiteDTO[]> {
    let defer = this.$q.defer<SiteDTO[]>();
    this.$http
      .get("/api/site/customer/" + id.toString())
      .then((response) => {
        defer.resolve(response.data as SiteDTO[]);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "sites",
          "Unable to get a list of sites from the  Please try again shortly.",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  fetchNew(id: number): ng.IPromise<SiteDTO> {
    let defer = this.$q.defer<SiteDTO>();
    this.$http
      .get(this.$baseAddress + "/newfetch/" + id.toString())
      .then((response) => {
        defer.resolve(response.data as SiteDTO);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          this.$broadcastScreen,
          "Unable to get a list of " +
          this.$broadcastBusinessNamePlural +
          " from the  Please try again shortly.",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  ListNew(): ng.IPromise<SiteDTO[]> {
    let defer = this.$q.defer<SiteDTO[]>();
    this.$http
      .get(this.$baseAddress + "/newlist")
      .then((response) => {
        defer.resolve(response.data as SiteDTO[]);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          this.$broadcastScreen,
          "Unable to get a list of " +
          this.$broadcastBusinessNamePlural +
          " from the  Please try again shortly.",
        );
        defer.reject(response);
      });
    return defer.promise;
  }
}
