addAuthInterceptor.$inject = ["$httpProvider"];

export function addAuthInterceptor($httpProvider: ng.IHttpProvider) {
  // Add our authInterceptor to the http provider array of interceptors
  $httpProvider.interceptors.push("authInterceptor");
}

authInterceptor.$inject = ["$rootScope", "$q", "$location", "$cookies"];

export function authInterceptor(
  $rootScope: ng.IRootScopeService,
  $q: ng.IQService,
  $location: ng.ILocationService,
  $cookies: ng.cookies.ICookiesService,
) {
  return {
    // intercept every request for Amazon AWS
    request: function (config: any) {
      var foreignUrl = config.url.indexOf("amazonaws") > -1;
      if (foreignUrl) {
        config.headers["Authorization"] = undefined;
      }
      return config;
    },

    // Intercept every response. If it's 401, we need to go to login.
    response: (response: any) => {
      if (response.status === 401) {
        $cookies.remove("access_token");
        $cookies.remove("access_token_expires");
        $cookies.remove("profile");

        localStorage.clear();

        $location.path("/login");
      }

      // Otherwise return our response. (q helps us pass back promises)
      return response || $q.when(response);
    },

    // Intercept http error responses. If it's 401, we need to go to login.
    responseError: (response: any) => {
      if (response.status === 401) {
        $cookies.remove("access_token");
        $cookies.remove("access_token_expires");
        $cookies.remove("profile");

        localStorage.clear();

        $location.path("/login");
      }

      // Otherwise just pass on the error (could be a 500/etc we want to handle gracefully.)
      return $q.reject(response);
    },
  };
}
