export class SearchService {
  static $inject = ["$http", "$q"];

  constructor(
    private $http: ng.IHttpService,
    private $q: ng.IQService,
  ) {}

  search(
    needle: string,
    meter: boolean = true,
    customer: boolean = true,
    agency: boolean = true,
    site: boolean = true,
  ): ng.IPromise<any[]> {
    let defer = this.$q.defer<any[]>();
    this.$http
      .get(
        "/api/search?needle=" +
          needle +
          "&meter=" +
          meter +
          "&customer=" +
          customer +
          "&agency=" +
          agency +
          "&site=" +
          site,
      )
      .then((response) => {
        defer.resolve(response.data as any[]);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }
}
