import { MeterAgencyAuthorityService } from "../services/MeterAgencyAuthorityService";
import { UserService } from "../services/UserService";
import { CustomerService } from "../services/CustomerService";
import { ApplicationUserDTO } from "@js/oldmodels/DTOs/ApplicationUserDTO.cs.d";
import { CustomerDTO } from "@js/oldmodels/DTOs/CustomerDTO.cs.d";
import { MeterAgencyAuthorityDTO } from "@js/oldmodels/DTOs/MeterAgencyAuthorityDTO.cs.d";

export class ListMeterAgencyAuthoritiesController {
  selectedSection: string;

  meterMeterAgencyAuthorityAuthorities: MeterAgencyAuthorityDTO[];
  selectedMeterAgencyAuthority: MeterAgencyAuthorityDTO;

  fetchingMeterMeterAgencyAuthorityAuthorities: boolean;
  fetchingMeterAgencyAuthority: boolean;

  addingLogin: boolean;
  addingConsultant: boolean;
  addingClient: boolean;

  logins: ApplicationUserDTO[];
  selectedLogin: ApplicationUserDTO;

  fetchingLogins: boolean;
  fetchingLogin: boolean;

  customers: CustomerDTO[];
  selectedCustomer: CustomerDTO;

  fetchingCustomers: boolean;
  fetchingCustomer: boolean;

  meterAgencyAuthorityform: ng.IFormController;
  loginform: ng.IFormController;
  customerform: ng.IFormController;

  static $inject = [
    "MeterAgencyAuthorityService",
    "UserService",
    "CustomerService",
  ];

  constructor(
    private $meterAgencyAuthorityservice: MeterAgencyAuthorityService,
    private $userservice: UserService,
    private $customerservice: CustomerService,
  ) {
    this.fetchingMeterMeterAgencyAuthorityAuthorities = true;
    this.$meterAgencyAuthorityservice
      .fetchUnverifiedMeterAgencyAuthorities()
      .then((response) => {
        this.meterMeterAgencyAuthorityAuthorities = response;
        this.fetchingMeterMeterAgencyAuthorityAuthorities = false;
      })
      .catch((response) => {
        this.fetchingMeterMeterAgencyAuthorityAuthorities = false;
      });
  }

  verify(meterAgencyAuthority: MeterAgencyAuthorityDTO) {
    this.$meterAgencyAuthorityservice
      .verifyMeterAgencyAuthority(meterAgencyAuthority)
      .then((response) => {
        meterAgencyAuthority.IsVerified = true;
        this.meterMeterAgencyAuthorityAuthorities.splice(
          this.meterMeterAgencyAuthorityAuthorities.indexOf(
            meterAgencyAuthority,
          ),
          1,
        );
      });
  }

  delete() {
    this.$meterAgencyAuthorityservice
      .markasdeleted(this.selectedMeterAgencyAuthority.Id)
      .then((response) => {
        if (response) {
          this.meterMeterAgencyAuthorityAuthorities.splice(
            this.meterMeterAgencyAuthorityAuthorities.indexOf(
              this.selectedMeterAgencyAuthority,
            ),
            1,
          );
          this.meterAgencyAuthorityform.$setPristine();
          delete this.selectedMeterAgencyAuthority;
        }
      });
  }
}
