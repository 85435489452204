import { MeterUsageManualService } from "../services/MeterUsageManualService";

export class ImportMeterReadingsController {
  uploadingFiles: boolean;
  error: boolean;
  errorString: string;

  sampleCSV: string =
    "MPANCore,Date,RecType,HH01,HH02,HH03,HH04,HH05,HH06,HH07,HH08,HH09,HH10,HH11,HH12,HH13,HH14,HH15,HH16,HH17,HH18,HH19,HH20,HH21,HH22,HH23,HH24,HH25,HH26,HH27,HH28,HH29,HH30,HH31,HH32,HH33,HH34,HH35,HH36,HH37,HH38,HH39,HH40,HH41,HH42,HH43,HH44,HH45,HH46,HH47,HH48\r\n";

  static $inject = ["MeterUsageManualService"];

  constructor(private $meterusagemanualservice: MeterUsageManualService) {}

  selectFile(file: File) {
    if (!file) {
      return;
    }

    this.error = false;
    delete this.errorString;

    var reader = new FileReader();
    reader.onload = (evt: ProgressEvent) => {
      this.uploadingFiles = true;
      this.uploadFile(file.name, (<FileReader>evt.target).result);
    };

    reader.readAsDataURL(file);
  }

  uploadFile(file_name: string, file: any) {
    this.$meterusagemanualservice
      .uploadCSVMeterReadings(file_name, file)
      .then((response) => {
        this.uploadingFiles = false;
      })
      .catch((response) => {
        this.uploadingFiles = false;
        this.error = true;
        if (response.data && response.data.ExceptionMessage) {
          if (response.data.ExceptionType === "CsvHelper.ValidationException") {
            this.errorString = "CSV file doesn't match expected format.";
          } else {
            this.errorString = response.data.ExceptionMessage;
          }
        }
      });
  }
}
