export class LazyLoadListComponent implements ng.IComponentOptions {
  public transclude: boolean = true;
  public bindings: any;
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.bindings = {
      getItems: "&",
      selectItem: "&",
      frontendFiltering: "<",
    };

    this.controller = LazyLoadListController;
    this.templateUrl = "js/components/lazyloadlist/lazyloadlist.html";
  }
}

export class LazyLoadListController implements ng.IComponentController {
  public items: any[] = [];
  public getItems: (query: any) => ng.IPromise<any>;
  public selectItem: (item: any) => ng.IPromise<any>;
  public page: number = 1;
  public itemFormat: any;
  public scrollPercent: number = 0;
  public limitHit: boolean = false;
  public fetchingItems: boolean = false;
  public needle: string = "";
  public frontendFiltering: boolean = false;

  static $inject = ["$scope"];
  constructor(private $scope: ng.IScope) {}

  $postLink() {
    this.doGetItems();
  }

  doGetItems() {
    if (!this.limitHit) {
      this.fetchingItems = true;
      this.getItems({ page: this.page, needle: this.needle })
        .then((response) => {
          if (response.TotalPageCount >= this.page) {
            this.items = [...this.items, ...response.List];
            this.page++;
          } else {
            this.limitHit = true;
          }
        })
        .finally(() => {
          this.fetchingItems = false;
        });
    }
  }

  doSelectItem(item: any) {
    this.selectItem({ item: item });
  }

  resetListAndSearch() {
    this.items = [];
    this.page = 1;
    this.limitHit = false;
    this.doGetItems();
  }

  filterItems() {
    if (this.frontendFiltering) {
      return this.needle;
    } else {
      return "";
    }
  }
}
