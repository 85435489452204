import { AuthService } from "../services/AuthService";
import { SearchService } from "../services/SearchService";
import { CustomerService } from "../services/CustomerService";
import { MeterService } from "../services/MeterService";
import { ContractService } from "../services/ContractService";
import { AgencyDTO } from "@js/oldmodels/DTOs/AgencyDTO.cs.d";
import { ApplicationUserDTO } from "@js/oldmodels/DTOs/ApplicationUserDTO.cs.d";
import { CustomerDTO } from "@js/oldmodels/DTOs/CustomerDTO.cs.d";
import { MeterDTO } from "@js/oldmodels/DTOs/MeterDTO.cs.d";
import { SiteDTO } from "@js/oldmodels/DTOs/SiteDTO.cs.d";

export class IndexController {
  user: ApplicationUserDTO;
  role: string;
  pageTitle: string;
  loggedIn: boolean = false;
  menuShrunk: boolean = false;

  customers: CustomerDTO[];
  selectedCustomer: CustomerDTO;

  errors: any[];

  alarmCount: number = 0;

  static $inject = [
    "$scope",
    "$rootScope",
    "$cookies",
    "$location",
    "$http",
    "AuthService",
    "SearchService",
    "CustomerService",
    "MeterService",
    "ContractService",
  ];

  constructor(
    private $scope: ng.IScope,
    private $rootScope: ng.IRootScopeService,
    private $cookies: ng.cookies.ICookiesService,
    private $location: ng.ILocationService,
    private $http: ng.IHttpService,
    private $authService: AuthService,
    private $search: SearchService,
    private $customerservice: CustomerService,
    private $meters: MeterService,
    private $contractservice: ContractService,
  ) {
    if ($scope) {
      $rootScope.$on(
        "select-customer",
        (event: ng.IAngularEvent, customer: CustomerDTO) => {
          this.selectedCustomer = customer;
          (this.$rootScope as any).selectedCustomer = customer;
        },
      );

      $rootScope.$on("error", (event: ng.IAngularEvent, data) => {
        if (!this.errors) {
          this.errors = [];
        }

        this.errors.push(data);
      });

      $rootScope.$on("clearerrors", (event: ng.IAngularEvent, page: string) => {
        this.clearErrors(page);
      });

      $scope.$on("$routeChangeStart", function (event, next, current) {
        if ($cookies.get("access_token")) {
          $http.defaults.headers.common.Authorization =
            "Bearer " + $cookies.get("access_token");
        }
      });

      $scope.$on("$routeChangeSuccess", function (event, current, previous) {
        ($scope as any).pageTitle = current.$$route.page;
        ($scope as any).openSection = current.$$route.section;

        ($scope as any).ctrl.startup();
      });

      $rootScope.$on(
        "logged-in",
        function (event: ng.IAngularEvent, token: string) {
          ($scope as any).ctrl.loggedIn = true;

          $http.defaults.headers.common.Authorization = "Bearer " + token;

          ($scope as any).ctrl.$authService.getProfile().then((response) => {
            ($scope as any).ctrl.user = response;
          });

          $location.url("/dashboard");
        },
      );
    }
  }

  startup() {
    this.alarmCount = 0;

    if (this.$cookies.get("access_token")) {
      this.loggedIn = true;

      //this.$meters.countMetersWithoutContracts().then((response) => {
      //    this.alarmCount += response;
      //});
      //this.$meters.countMetersWithMissingHHData().then((response) => {
      //    this.alarmCount += response;
      //});
      //this.$meters.countMetersWithZeroValueHHData().then((response) => {
      //    this.alarmCount += response;
      //});

      //this.$contractservice.countExpiringContracts(120).then((response) => {
      //    this.alarmCount += response;
      //});

      this.$authService.getProfile().then((response) => {
        this.user = response;
        console.log("THE USER IS:" + this.user);
      });

      if (!this.customers) {
        this.$customerservice.ListNew().then((response) => {
          this.customers = response;
        });
      }
    }
  }

  clearErrors(page: string) {
    if (this.errors) {
      this.errors = this.errors.filter((value: any, index: number) => {
        return value.page !== page;
      });
    }
  }

  dismissError(index: number) {
    this.errors.splice(index, 1);
  }

  search(needle: string): ng.IPromise<any[]> {
    if (!needle) return null;

    return this.$search.search(needle, true, true, true, true);
  }

  selectItem(item: any, type: string): void {
    if (type === "meter") {
      this.go("/meters/" + (item as MeterDTO).Id);
    } else if (type === "customer") {
      this.go("/customers/" + (item as CustomerDTO).Id);
    } else if (type === "agency") {
      this.go("/agencies/" + (item as AgencyDTO).Id);
    } else if (type === "site") {
      this.go("/sites/" + (item as SiteDTO).Id);
    }
  }

  go(path: string) {
    this.$location.path(path);
  }

  logout() {
    this.loggedIn = false;
    this.user = null;
    delete this.user;
    this.$authService.logout();
    this.$location.path("/login");
  }

  updateSelectedCustomer() {
    (this.$rootScope as any).selectedCustomer = this.selectedCustomer;
    (this.$rootScope as any).$broadcast("selected-customer-updated");
  }

  clearSelectedCustomer() {
    delete this.selectedCustomer;
    delete (this.$rootScope as any).selectedCustomer;
    (this.$rootScope as any).$broadcast("selected-customer-updated");
  }
}
