import { AgencyDTO } from "@js/oldmodels/DTOs/AgencyDTO.cs.d";
import { ApplicationUserDTO } from "@js/oldmodels/DTOs/ApplicationUserDTO.cs.d";
import { CustomerDTO } from "@js/oldmodels/DTOs/CustomerDTO.cs.d";
import { LegalEntityDTO } from "@js/oldmodels/DTOs/LegalEntityDTO.cs.d";
import { UserRoleEnum } from "@js/oldmodels/Enums/UserRoleEnum.cs.d";
import { AgencyService } from "../services/AgencyService";
import { CustomerService } from "../services/CustomerService";
import { UserService } from "../services/UserService";

export class AgencyDetailController {
  selectedSection: string = "";

  selectedAgency: AgencyDTO | null = null;

  fetchingAgency: boolean;

  addingLogin: boolean;
  addingConsultant: boolean;
  addingClient: boolean;

  logins: ApplicationUserDTO[] = [];
  selectedLogin: ApplicationUserDTO | null = null;

  fetchingLogins: boolean = false;

  customers: CustomerDTO[] = [];
  selectedCustomer: CustomerDTO | null = null;

  fetchingCustomers: boolean = false;

  agencyform: ng.IFormController;
  loginform: ng.IFormController;
  customerform: ng.IFormController;

  static $inject = ["AgencyService", "UserService", "CustomerService"];

  constructor(
    private $agencyservice: AgencyService,
    private $userservice: UserService,
    private $customerservice: CustomerService,
  ) {
    this.fetchingAgency = true;
    this.$agencyservice
      .fetchMyAgency()
      .then((response) => {
        this.selectAgency(response);
        this.fetchingAgency = false;
      })
      .catch((e: any) => {
        this.fetchingAgency = false;
        console.error(e);
      });
  }

  selectAgency(agency: AgencyDTO) {
    this.selectedAgency = agency;
    this.selectedSection = "details";
    this.fetchingLogins = true;
    this.$userservice
      .fetchLoginsForAgency(agency.Id)
      .then((response) => {
        this.logins = response;
        this.fetchingLogins = false;
      })
      .catch((e: any) => {
        this.fetchingLogins = false;
        console.error(e);
      });
    this.fetchingCustomers = true;
    this.$customerservice
      .fetchCustomersForAgency(agency.Id)
      .then((response) => {
        this.customers = response;
        this.fetchingCustomers = false;
      })
      .catch((e: any) => {
        this.fetchingCustomers = false;
        console.error(e);
      });
  }

  save() {
    if (this.selectedAgency === null) {
      return;
    }

    this.$agencyservice.addUpdate(this.selectedAgency).then((response) => {
      if (this.selectedAgency === null) {
        return;
      }

      this.selectedAgency.Id = response.Id;

      this.agencyform.$setPristine();
    });
  }

  // #region Logins
  addLogin() {
    this.selectedLogin = {
      AssociatedAgencyId: this.selectedAgency?.Id,
      AssociatedAgencyName: this.selectedAgency?.Name,
      Roles: [] as string[],
      UserType: UserRoleEnum.AgencyUser,
    } as ApplicationUserDTO;
  }

  removeLogin(login: ApplicationUserDTO) {
    this.$userservice.markasdeleted(login.UserName).then(() => {
      this.logins.splice(this.logins.indexOf(login), 1);
      this.selectedLogin = null;
    });
  }

  cancelUpdateLogin() {
    this.selectedLogin = null;
  }

  updateLogin(login: ApplicationUserDTO) {
    this.$userservice
      .addUpdate(login)
      .then((response) => {
        login.OrigUserName = response.OrigUserName;
        login.Id = response.Id;
        if (this.logins.indexOf(login) === -1) {
          this.logins.push(login);
        }

        this.selectedLogin = null;
      })
      .catch((e: any) => {
        this.selectedLogin = null;
        console.error(e);
      });
  }

  // #endregion

  // #region Customers
  addCustomer() {
    this.selectedCustomer = {
      CurrentAgencyId: this.selectedAgency?.Id,
      CurrentAgencyName: this.selectedAgency?.Name,
      LegalEntity: {} as LegalEntityDTO,
    } as CustomerDTO;
  }

  removeCustomer(customer: CustomerDTO) {
    this.$customerservice.markasdeleted(customer.Id).then(() => {
      if (this.selectedCustomer === null) {
        return;
      }

      this.customers.splice(this.customers.indexOf(this.selectedCustomer), 1);
      this.selectedCustomer = null;
    });
  }

  cancelUpdateCustomer() {
    this.selectedCustomer = null;
  }

  updateCustomer(customer: CustomerDTO) {
    this.$customerservice
      .addUpdate(customer)
      .then(() => {
        if (this.customers.indexOf(customer) === -1) {
          this.customers.push(customer);
        }
        this.selectedCustomer = null;
      })
      .catch((e: any) => {
        this.selectedCustomer = null;
        console.error(e);
      });
  }

  // #endregion
}
