import angular from "angular";
import { AuthService } from "../services/AuthService";
import { MeterService } from "../services/MeterService";
import { ReportService } from "../services/ReportService";
import { SearchService } from "../services/SearchService";
import { MeterDTO } from "@js/oldmodels/DTOs/MeterDTO.cs.d";
import { UsageComparisonReportDTO } from "@js/oldmodels/DTOs/UsageReportDTO.cs.d";

export class ReportComparisonController {
  selectedReport: UsageComparisonReportDTO;
  meters: MeterDTO[];
  selectedMeters1: MeterDTO[] = [];
  selectedMeters2: MeterDTO[] = [];
  fetchingMeters: boolean;

  fetchingReports: boolean;
  fetchingReport: boolean;

  utilityType1: string;
  utilityType2: string;

  start1: Date = new Date(2017, 8, 1, 12, 0, 0, 0);
  end1: Date = new Date(
    Date.today().getFullYear(),
    Date.today().getMonth(),
    Date.today().getDate(),
    12,
    0,
    0,
  );
  start2: Date = new Date(2017, 8, 1, 12, 0, 0, 0);
  end2: Date = new Date(
    Date.today().getFullYear(),
    Date.today().getMonth(),
    Date.today().getDate(),
    12,
    0,
    0,
  );

  //start1: Date = new Date(Date.UTC(2017, 8, 1, 0, 0, 0, 0));
  //end1: Date = new Date(Date.UTC(Date.today().getFullYear(), Date.today().getMonth(), Date.today().getDate(), 0, 0, 0));
  //start2: Date = new Date(Date.UTC(2017, 8, 1, 0, 0, 0, 0));
  //end2: Date = new Date(Date.UTC(Date.today().getFullYear(), Date.today().getMonth(), Date.today().getDate(), 0, 0, 0));
  granularity: string = "Month";
  canDrillUp: boolean = false;

  chartTimelineLabels1: string[];
  chartData1: number[][] = [];

  chartTimelineLabels2: string[];
  chartData2: number[][] = [];

  seriesNames: string[] = ["kWh (Off-Peak)", "kWh (Peak)"];
  seriesColours: string[] = ["#6C78C4", "#50CAE8"];
  seriesOptions1: any[] = [
    {},
    {
      backgroundColor: ["#50CAE8", "#50CAE8"],
      borderColor: ["#50CAE8", "#50CAE8"],
    },
  ];
  seriesOptions2: any[] = [
    {},
    {
      backgroundColor: ["#50CAE8", "#50CAE8"],
      borderColor: ["#50CAE8", "#50CAE8"],
    },
  ];

  lineChartOptions: any = {
    scales: {
      yAxes: [
        {
          id: "y-axis-1",
          type: "linear",
          display: true,
          position: "left",
          stacked: true,
          ticks: {
            beginAtZero: true,
          },
          scaleLabel: {
            display: true,
            labelString: "kWh",
          },
        },
      ],
      xAxes: [
        {
          stacked: true,
          fillOpacity: 1,
          scaleLabel: {
            display: true,
            labelString: "Date",
          },
        },
      ],
    },
  };

  static $inject = [
    "$rootScope",
    "AuthService",
    "MeterService",
    "ReportService",
    "SearchService",
  ];

  constructor(
    private $rootScope: ng.IRootScopeService,
    private $auth: AuthService,
    private $meters: MeterService,
    private $reports: ReportService,
    private $search: SearchService,
  ) {
    this.fetchingMeters = true;
    this.$meters
      .ListNew()
      .then((response) => {
        this.meters = response;
      })
      .finally(() => {
        this.fetchingMeters = false;
      });
  }

  updateLabels() {
    switch (this.granularity.toString()) {
      case "Month":
        this.lineChartOptions.scales.xAxes[0].scaleLabel.labelString = "Month";
        break;

      case "Week":
        this.lineChartOptions.scales.xAxes[0].scaleLabel.labelString = "Week";
        break;

      case "Day":
        this.lineChartOptions.scales.xAxes[0].scaleLabel.labelString = "Date";
        break;

      case "Hour":
      case "HalfHour":
      default:
        this.lineChartOptions.scales.xAxes[0].scaleLabel.labelString = "Time";
        break;
    }
  }

  toggleMeter(meters: MeterDTO[], meter: MeterDTO) {
    var meterIndex = meters.indexOf(meter);

    if (meterIndex == -1) {
      meters.push(meter);
    } else {
      meters.splice(meterIndex, 1);
    }
  }

  calcNextFigure(num: number): number {
    let fig = Math.pow(10, Math.round(num).toString().length - 1);
    return Math.ceil(num / fig) * fig;
  }

  setGranularity(): void {
    const elapsedMS1: number = this.end1.getTime() - this.start1.getTime();
    const elapsedDays1: number = elapsedMS1 / 86400000;

    const elapsedMS2: number = this.end2.getTime() - this.start2.getTime();
    const elapsedDays2: number = elapsedMS2 / 86400000;

    let elapsedDays: number = elapsedDays2;

    if (elapsedDays1 > elapsedDays2) {
      elapsedDays = elapsedDays1;
    } else {
      elapsedDays = elapsedDays2;
    }

    if (elapsedDays < 14) {
      this.granularity = "HalfHour";
    } else if (elapsedDays < 28) {
      this.granularity = "Hour";
    } else if (elapsedDays < 180) {
      this.granularity = "Day";
    } else {
      this.granularity = "Month";
    }
  }

  fetchReport() {
    this.$rootScope.$broadcast("clearerrors", "reporting");

    if (
      !this.selectedMeters1 ||
      this.selectedMeters1.length === 0 ||
      !this.selectedMeters2 ||
      this.selectedMeters2.length === 0
    ) {
      this.$rootScope.$broadcast("error", {
        page: "reporting",
        error: "Please select at least one meter.",
      });
      return;
    }

    this.fetchingReport = true;

    this.setGranularity();
    this.updateCanDrillUp();

    let selectedMeters: number[][] = [];
    selectedMeters[0] = this.selectedMeters1.map((meter) => {
      return meter.Id;
    });
    selectedMeters[1] = this.selectedMeters2.map((meter) => {
      return meter.Id;
    });

    this.$reports
      .fetchComparisonReport(
        selectedMeters,
        this.start1,
        this.end1,
        this.start2,
        this.end2,
        this.granularity,
      )
      .then((response) => {
        this.selectedReport = response;
        this.updateLabels();
        this.chartData1[0] = [];
        this.chartData1[1] = [];
        this.chartTimelineLabels1 = [];
        this.chartData2[0] = [];
        this.chartData2[1] = [];
        this.chartTimelineLabels2 = [];

        this.seriesOptions1[0] = {
          backgroundColor: [],
          borderColor: [],
        };
        this.seriesOptions1[1] = {
          backgroundColor: [],
          borderColor: [],
        };
        this.seriesOptions2[0] = {
          backgroundColor: [],
          borderColor: [],
        };
        this.seriesOptions2[1] = {
          backgroundColor: [],
          borderColor: [],
        };

        this.lineChartOptions.scales.yAxes[0].ticks.max = 0;

        angular.forEach(this.selectedReport.Report1.DataDay, (item, index) => {
          this.chartTimelineLabels1.push(index);
          this.chartData1[1].push(item);

          //if (this.lineChartOptions.scales.yAxes[0].ticks.max < this.calcNextFigure(item)) {
          //    this.lineChartOptions.scales.yAxes[0].ticks.max = this.calcNextFigure(item);
          //}
        });

        let idx: number = 0;

        angular.forEach(
          this.selectedReport.Report1.DataNight,
          (item, index) => {
            //this.chartTimelineLabels1.push(index);
            this.chartData1[0].push(item);
            this.seriesOptions1[0].backgroundColor.push("#50CAE8");
            this.seriesOptions1[0].borderColor.push("#50CAE8");

            if (
              this.lineChartOptions.scales.yAxes[0].ticks.max <
              this.calcNextFigure(
                this.chartData1[0][idx] + this.chartData1[1][idx],
              )
            ) {
              this.lineChartOptions.scales.yAxes[0].ticks.max =
                this.calcNextFigure(
                  this.chartData1[0][idx] + this.chartData1[1][idx],
                );
            }

            idx++;
          },
        );

        angular.forEach(this.selectedReport.Report2.DataDay, (item, index) => {
          this.chartTimelineLabels2.push(index);
          this.chartData2[1].push(item);

          //if (this.lineChartOptions.scales.yAxes[0].ticks.max < this.calcNextFigure(item)) {
          //    this.lineChartOptions.scales.yAxes[0].ticks.max = this.calcNextFigure(item);
          //}
        });

        idx = 0;
        angular.forEach(
          this.selectedReport.Report2.DataNight,
          (item, index) => {
            //this.chartTimelineLabels1.push(index);
            this.chartData2[0].push(item);
            this.seriesOptions2[0].backgroundColor.push("#50CAE8");
            this.seriesOptions2[0].borderColor.push("#50CAE8");

            if (
              this.lineChartOptions.scales.yAxes[0].ticks.max <
              this.calcNextFigure(
                this.chartData2[0][idx] + this.chartData2[1][idx],
              )
            ) {
              this.lineChartOptions.scales.yAxes[0].ticks.max =
                this.calcNextFigure(
                  this.chartData2[0][idx] + this.chartData2[1][idx],
                );
            }
          },
        );

        this.fetchingReport = false;
      })
      .catch((response) => {
        this.$rootScope.$broadcast("error", {
          page: "reporting",
          error:
            "There was a problem fetching your report. Please try again later.",
        });
        this.fetchingReport = false;
      });
  }

  chartClick(points: any[], event: MouseEvent) {
    let scope: ng.IScope = angular.element(event.target as Element).scope();
    let ctrl: ReportComparisonController = (scope as any).ctrl;
    if (points && points.length > 0) {
      // TODO labels aren't the best, and this assumes labels1 and labels 2 are the same
      let chartLabel = ctrl.chartTimelineLabels1[points[0]._index];

      // Drill down:
      switch (ctrl.granularity.toString()) {
        case "Month":
          let month_date: Date = new Date(chartLabel);
          ctrl.start1 = month_date.clone();
          ctrl.end1 = month_date.clone().addMonths(1).addDays(-1);
          ctrl.start2 = month_date.clone();
          ctrl.end2 = month_date.clone().addMonths(1).addDays(-1);
          ctrl.fetchReport();

          break;

        case "Week":
          ctrl.start1 = new Date(chartLabel.substring(0, 4))
            .addWeeks(parseInt(chartLabel.substring(4)) - 1)
            .addDays(1);
          ctrl.end1 = new Date(chartLabel.substring(0, 4))
            .addWeeks(parseInt(chartLabel.substring(4)) - 1)
            .addDays(7);
          ctrl.start2 = new Date(chartLabel.substring(0, 4))
            .addWeeks(parseInt(chartLabel.substring(4)) - 1)
            .addDays(1);
          ctrl.end2 = new Date(chartLabel.substring(0, 4))
            .addWeeks(parseInt(chartLabel.substring(4)) - 1)
            .addDays(7);
          ctrl.fetchReport();

          break;

        case "Day":
          let day_date: Date = new Date(chartLabel);
          ctrl.start1 = new Date(
            day_date.getFullYear(),
            day_date.getMonth(),
            day_date.getDate(),
          );
          ctrl.end1 = new Date(
            day_date.getFullYear(),
            day_date.getMonth(),
            day_date.getDate(),
            12,
            0,
            0,
            0,
          );
          ctrl.start2 = new Date(
            day_date.getFullYear(),
            day_date.getMonth(),
            day_date.getDate(),
          );
          ctrl.end2 = new Date(
            day_date.getFullYear(),
            day_date.getMonth(),
            day_date.getDate(),
            12,
            0,
            0,
            0,
          );
          ctrl.fetchReport();

          break;

        case "Hour":
          ctrl.granularity = "HalfHour";
          ctrl.fetchReport();

          break;

        case "HalfHour":
        // Can't drill down any further.
        default:
      }
    }
  }

  updateCanDrillUp(): void {
    const elapsedMS: number = this.end1.getTime() - this.start1.getTime();
    const elapsedDays: number = elapsedMS / 86400000;

    this.canDrillUp =
      elapsedDays < 367 &&
      !(
        this.start1.getMonth() == 0 &&
        this.start1.getDate() == 1 &&
        this.end1.getMonth() == 11 &&
        this.end1.getDate() == 31
      );
  }

  drillUp() {
    // Drill Up:
    switch (this.granularity.toString()) {
      case "Month":
        this.start1 = new Date(this.start1.getFullYear(), 0, 1);
        this.end1 = new Date(this.start1.getFullYear(), 11, 31);
        this.start2 = new Date(this.start1.getFullYear(), 0, 1);
        this.end2 = new Date(this.start1.getFullYear(), 11, 31);

        this.fetchReport();

        break;

      case "Day":
        this.granularity = "Month";
        this.start1 = new Date(this.start1.getFullYear(), 0, 1);
        this.end1 = new Date(this.start1.getFullYear(), 11, 31);
        this.start2 = new Date(this.start1.getFullYear(), 0, 1);
        this.end2 = new Date(this.start1.getFullYear(), 11, 31);

        this.fetchReport();

        break;
      default:
        this.granularity = "Day";
        this.start1 = new Date(
          this.start1.getFullYear(),
          this.start1.getMonth(),
          1,
        );
        this.end1 = this.start1.clone().addMonths(1).addDays(-1);
        this.start2 = new Date(
          this.start1.getFullYear(),
          this.start1.getMonth(),
          1,
        );
        this.end2 = this.start1.clone().addMonths(1).addDays(-1);
        this.fetchReport();
        break;
    }
  }

  setDates1(range: string) {
    if (range === "thismonth") {
      this.start1 = new Date().moveToFirstDayOfMonth();
      this.end1 = new Date(
        Date.today().getFullYear(),
        Date.today().getMonth(),
        Date.today().getDate(),
        12,
        0,
        0,
      );
    } else if (range === "lastmonth") {
      this.start1 = new Date().moveToFirstDayOfMonth().addMonths(-1);
      this.end1 = new Date().moveToFirstDayOfMonth().addDays(-1);
    } else if (range === "last30days") {
      this.start1 = new Date().addDays(-30);
      this.end1 = new Date(
        Date.today().getFullYear(),
        Date.today().getMonth(),
        Date.today().getDate(),
        12,
        0,
        0,
      );
    } else if (range === "last6months") {
      this.start1 = new Date().moveToFirstDayOfMonth().addMonths(-6);
      this.end1 = new Date().moveToFirstDayOfMonth().addDays(-1);
    } else if (range === "thisyear") {
      this.start1 = new Date(new Date().getFullYear(), 0, 1);
      this.end1 = new Date(
        Date.today().getFullYear(),
        Date.today().getMonth(),
        Date.today().getDate(),
        12,
        0,
        0,
      );
    } else if (range === "lastyear") {
      this.start1 = new Date(new Date().getFullYear(), 0, 1).addYears(-1);
      this.end1 = new Date(this.start1.getFullYear(), 11, 31);
    } else if (range === "last2years") {
      this.start1 = new Date(new Date().getFullYear(), 0, 1).addYears(-2);
      this.end1 = new Date(new Date().getFullYear(), 0, 1).addDays(-1);
    } else if (range === "last5years") {
      this.start1 = new Date(new Date().getFullYear(), 0, 1).addYears(-5);
      this.end1 = new Date(new Date().getFullYear(), 0, 1).addDays(-1);
    }

    // Set times to start1 of first day and end1 of last day.
    //this.start1.setHours(0, 0, 0, 0);
    //this.end1.setHours(23, 59, 59, 999);
  }

  setDates2(range: string) {
    if (range === "thismonth") {
      this.start2 = new Date().moveToFirstDayOfMonth();
      this.end2 = new Date(
        Date.today().getFullYear(),
        Date.today().getMonth(),
        Date.today().getDate(),
        12,
        0,
        0,
      );
    } else if (range === "lastmonth") {
      this.start2 = new Date().moveToFirstDayOfMonth().addMonths(-1);
      this.end2 = new Date().moveToFirstDayOfMonth().addDays(-1);
    } else if (range === "last30days") {
      this.start2 = new Date().addDays(-30);
      this.end2 = new Date(
        Date.today().getFullYear(),
        Date.today().getMonth(),
        Date.today().getDate(),
        12,
        0,
        0,
      );
    } else if (range === "last6months") {
      this.start2 = new Date().moveToFirstDayOfMonth().addMonths(-6);
      this.end2 = new Date().moveToFirstDayOfMonth().addDays(-1);
    } else if (range === "thisyear") {
      this.start2 = new Date(new Date().getFullYear(), 0, 1);
      this.end2 = new Date(
        Date.today().getFullYear(),
        Date.today().getMonth(),
        Date.today().getDate(),
        12,
        0,
        0,
      );
    } else if (range === "lastyear") {
      this.start2 = new Date(new Date().getFullYear(), 0, 1).addYears(-1);
      this.end2 = new Date(this.start2.getFullYear(), 11, 31);
    } else if (range === "last2years") {
      this.start2 = new Date(new Date().getFullYear(), 0, 1).addYears(-2);
      this.end2 = new Date(new Date().getFullYear(), 0, 1).addDays(-1);
    } else if (range === "last5years") {
      this.start2 = new Date(new Date().getFullYear(), 0, 1).addYears(-5);
      this.end2 = new Date(new Date().getFullYear(), 0, 1).addDays(-1);
    }

    // Set times to start2 of first day and end2 of last day.
    //this.start2.setHours(0, 0, 0, 0);
    //this.end2.setHours(23, 59, 59, 999);
  }

  // #region Search

  search(needle: string): ng.IPromise<any[]> {
    if (!needle) return null;

    return this.$search.search(needle, true, false, false, false);
  }

  selectItem(array: MeterDTO[], item: any, type: string): void {
    let meter: MeterDTO = item as MeterDTO;
    array.push(meter);
  }

  removeItem(array: MeterDTO[], meter: MeterDTO) {
    array.splice(array.indexOf(meter), 1);
  }

  // #endregion Search
}
