import { MeterUsageManualService } from "../services/MeterUsageManualService";
import { MeterService } from "../services/MeterService";
import { SearchService } from "../services/SearchService";
import { MeterDTO } from "@js/oldmodels/DTOs/MeterDTO.cs.d";
import { MeterUsageManualDTO } from "@js/oldmodels/DTOs/MeterUsageManualDTO.cs.d";

export class MeterReadingController {
  page: number = 0;
  performingAction: boolean = false;

  meters: MeterDTO[] = [];
  selectedMeter: MeterDTO;
  fetchingMeters: boolean;

  readings: MeterUsageManualDTO[] = [];
  editingReading: boolean = false;

  meterReading: MeterUsageManualDTO = {} as MeterUsageManualDTO;

  utilityType: "Electricity" | "Gas" | "Water";

  static $inject = ["MeterUsageManualService", "MeterService", "SearchService"];

  constructor(
    private $meterusagemanualservice: MeterUsageManualService,
    private $meterservice: MeterService,
    private $search: SearchService,
  ) {
    this.fetchingMeters = true;
    this.$meterservice
      .ListNew()
      .then((response) => {
        this.meters = response;
      })
      .finally(() => {
        this.fetchingMeters = false;
      });
  }

  search(needle: string): ng.IPromise<any[]> {
    if (!needle) return null;

    return this.$search.search(needle, true, false, false, false);
  }

  selectItem(item: any, type: string): void {
    let meter: MeterDTO = item as MeterDTO;
    this.selectedMeter = meter;
    this.meterReading = {
      AssociatedMeterId: this.selectedMeter.Id,
    } as MeterUsageManualDTO;
    this.updateReadings(this.selectedMeter.Id);
  }

  submitReading(): void {
    this.$meterusagemanualservice
      .addUpdateNew(this.meterReading)
      .then((response) => {
        this.meterReading = {
          AssociatedMeterId: this.selectedMeter.Id,
        } as MeterUsageManualDTO;
        this.editingReading = false;
        this.updateReadings(this.selectedMeter.Id);
      });
  }

  cancelUpdate(): void {
    this.editingReading = false;
    this.meterReading = {
      AssociatedMeterId: this.selectedMeter.Id,
    } as MeterUsageManualDTO;
  }

  updateReadings(associatedMeterId: number): void {
    this.$meterusagemanualservice.fetchAll().then((response) => {
      this.readings = response.filter(
        (m) => m.AssociatedMeterId == associatedMeterId,
      );
      this.readings.forEach(function (reading) {
        reading.UsageDate = new Date(reading.UsageDate);
      });
    });
  }

  deleteEntry(id: number): void {
    this.$meterusagemanualservice.RemoveRec(id).then(() => {
      this.updateReadings(this.selectedMeter.Id);
      this.meterReading = {
        AssociatedMeterId: this.selectedMeter.Id,
      } as MeterUsageManualDTO;
      this.editingReading = false;
    });
  }
}
