export function instantiateUtilityBridgeDirectives() {
  angular
    .module("utilitybridgeDirectives", [])
    .directive("mpantopMask", function () {
      return {
        restrict: "A",
        require: "ngModel",
        link: function (scope, element, attrs, ngModelController) {
          // Run formatting on keyup
          var numberWithHyphens = function (value) {
            value = value.toString();
            value = value.replace(/[^0-9\.]/g, "");

            if (value.length < 3) {
              return value;
            } else if (value.length < 6) {
              let mpanregex = RegExp(/(\d{2})(\d{1,3})/, "g");
              let top = mpanregex.exec(value);

              let pc = top[1];
              let llf = top[2];

              return (pc + " - " + llf).trim();
            } else if (value.length < 9) {
              let mpanregex = RegExp(/(\d{2})(\d{3})(\d{1,3})/, "g");
              let top = mpanregex.exec(value);

              let pc = top[1];
              let llf = top[2];
              let mtc = top[3];

              return (pc + " - " + llf + " - " + mtc).trim();
            } else {
              return value;
            }
          };
          var applyFormatting = function () {
            var value = element.val();
            var original = value;
            if (!value || value.length == 0) {
              return;
            }
            value = numberWithHyphens(value);
            if (value != original) {
              element.val(value);
              element.triggerHandler("input");
            }
          };
          element.bind("keyup", function (e) {
            var keycode = e.keyCode;
            var isTextInputKey =
              (keycode > 47 && keycode < 58) || // number keys
              keycode == 32 ||
              keycode == 8 || // spacebar or backspace
              (keycode > 64 && keycode < 91) || // letter keys
              (keycode > 95 && keycode < 112) || // numpad keys
              (keycode > 185 && keycode < 193) || // ;=,-./` (in order)
              (keycode > 218 && keycode < 223); // [\]' (in order)
            if (isTextInputKey) {
              applyFormatting();
            }
          });
          ngModelController.$parsers.push(function (value) {
            if (!value || value.length == 0) {
              return value;
            }
            value = value.toString();
            value = value.replace(/[^0-9\.]/g, "");
            return value;
          });
          ngModelController.$formatters.push(function (value) {
            if (!value || value.length == 0) {
              return value;
            }
            value = numberWithHyphens(value);
            return value;
          });
        },
      };
    });
}
