import angular from "angular";
import "angular-animate";
import "angular-chart.js";
import "angular-cookies";
import "angular-resource";
import "angular-route";
import "angular-utils-pagination";
import "isteven-angular-multiselect/isteven-multi-select.js";
import "ng-file-upload";
import "ng-tags-input";
import { instantiateUtilityBridgeDirectives } from "./directives.js";
import {
  addAuthInterceptor,
  authInterceptor,
} from "./filesfromccqbase/AuthInterceptor";
import { BroadcastService } from "./filesfromccqbase/BroadcastService";
import { instantiateCcqDirectives } from "./filesfromccqbase/CcqDirectives";
import "./libs/date";
import { BillingUnitEnum } from "./oldmodels/Enums/BillingUnitEnum.cs.d";
import { ContractRateTypeEnum } from "./oldmodels/Enums/ContractRateTypeEnum.cs.d";
import { ContractTypeEnum } from "./oldmodels/Enums/ContractTypeEnum.cs.d";
import { MeterDataTypeEnum } from "./oldmodels/Enums/MeterDataTypeEnum.cs.d";
import { MeterReadingTypeEnum } from "./oldmodels/Enums/MeterReadingTypeEnum.cs.d";
import { MeterUtilityTypeEnum } from "./oldmodels/Enums/MeterUtilityTypeEnum.cs.d";
import { MeterVoltageTypeEnum } from "./oldmodels/Enums/MeterVoltageTypeEnum.cs.d";
import { RateTypeEnum } from "./oldmodels/Enums/RateTypeEnum.cs.d";
import { RateUnitEnum } from "./oldmodels/Enums/RateUnitEnum.cs.d";
import { TimeGranularityEnum } from "./oldmodels/Enums/TimeGranularityEnum.cs.d";
import { UserRoleEnum } from "./oldmodels/Enums/UserRoleEnum.cs.d";
import { registerCCQBaseComponents } from "./registerCCQBaseComponents";
import { registerControllers } from "./registerControllers";
import { registerReactComponents } from "./registerReactComponents";
import { registerServices } from "./registerServices";
import { registerRoutes } from "./routes";

// The ccqapp module depends on the utilitybridgeDirectives module
// so we must instantiate it first
instantiateCcqDirectives();
instantiateUtilityBridgeDirectives();

const ccqapp = angular.module("ccqapp", [
  "CcqDirectives",
  "utilitybridgeDirectives",
  "angularUtils.directives.dirPagination",
  "chart.js",
  "isteven-multi-select",
  "ngAnimate",
  "ngCookies",
  "ngFileUpload",
  "ngResource",
  "ngRoute",
  "ngTagsInput",
  //"uiGmapgoogle-maps",
]);

interface IScopeWithEnums extends angular.IRootScopeService {
  BillingUnitEnum: typeof BillingUnitEnum;
  ContractRateTypeEnum: typeof ContractRateTypeEnum;
  ContractTypeEnum: typeof ContractTypeEnum;
  MeterDataTypeEnum: typeof MeterDataTypeEnum;
  MeterReadingTypeEnum: typeof MeterReadingTypeEnum;
  MeterUtilityTypeEnum: typeof MeterUtilityTypeEnum;
  MeterVoltageTypeEnum: typeof MeterVoltageTypeEnum;
  RateTypeEnum: typeof RateTypeEnum;
  RateUnitEnum: typeof RateUnitEnum;
  TimeGranularityEnum: typeof TimeGranularityEnum;
  UserRoleEnum: typeof UserRoleEnum;
}

ccqapp.run(function ($rootScope: IScopeWithEnums) {
  $rootScope.BillingUnitEnum = BillingUnitEnum;
  $rootScope.ContractRateTypeEnum = ContractRateTypeEnum;
  $rootScope.ContractTypeEnum = ContractTypeEnum;
  $rootScope.MeterDataTypeEnum = MeterDataTypeEnum;
  $rootScope.MeterReadingTypeEnum = MeterReadingTypeEnum;
  $rootScope.MeterUtilityTypeEnum = MeterUtilityTypeEnum;
  $rootScope.MeterVoltageTypeEnum = MeterVoltageTypeEnum;
  $rootScope.RateTypeEnum = RateTypeEnum;
  $rootScope.RateUnitEnum = RateUnitEnum;
  $rootScope.TimeGranularityEnum = TimeGranularityEnum;
  $rootScope.UserRoleEnum = UserRoleEnum;
});

ccqapp.config(addAuthInterceptor);

ccqapp.config([
  "$httpProvider",
  function ($httpProvider: angular.IHttpProvider) {
    $httpProvider.interceptors.push(function () {
      return {
        request: function (request) {
          //return convertDateStringsToDates(request, false);
          // We're not modifying anything sent to the server.
          return request;
        },

        response: function (response) {
          // Modify anything received from the server to local timezone.
          return convertDateStringsToDates(response, true);
          //return response;
        },
      };
    });
  },
]);

// ccqapp.config(["uiGmapGoogleMapApiProvider", function (uiGmapGoogleMapApiProvider:any) {
//     uiGmapGoogleMapApiProvider.configure({
//         key: 'AIzaSyAzMfnJDaA_NBezIC7za_unJo2gBAvL1C0',
//         v: '3.28', //defaults to latest 3.X anyhow
//         libraries: 'weather,geometry,visualization'
//     });
// }])

//utilitybridge.config(['bugsnagProvider', function (bugsnagProvider) {
//    bugsnagProvider
//        .noConflict()
//        .apiKey('a080f8c3ebc0accbdbb5b5d7ea742f71')
//        .releaseStage('release')
//        .appVersion('0.1.0')
//        .beforeNotify(['$log', function ($log) {
//            return function (error, metaData) {
//                $log.debug(error.name);
//                return true;
//            };
//        }]);
//}]);

ccqapp.config(function ($locationProvider: angular.ILocationProvider) {
  $locationProvider.html5Mode(true);
});

registerRoutes();
registerCCQBaseComponents();
registerServices();
registerControllers();
registerReactComponents();

//Create our auth interceptor as an angular factory
ccqapp.factory("authInterceptor", authInterceptor);
ccqapp.service("BroadcastService", BroadcastService);

ccqapp.filter("nospaces", function () {
  return function (text: string) {
    if (text === null || text === undefined) {
      return null;
    }

    return text.replace(/\s+/g, "");
  };
});

ccqapp.filter("startFrom", function () {
  return function (input: any, start: any) {
    start = +start; //parse to int
    return input.slice(start);
  };
});

function convertDateStringsToDates(input: any, toLocal: boolean): any {
  // Ignore things that aren't objects.
  if (typeof input !== "object") return input;

  for (var key in input) {
    if (!input.hasOwnProperty(key)) continue;

    var value = input[key];
    // Check for string properties which look like dates.
    if (
      typeof value === "string" &&
      value.match(/(\d{4})-(\d{2})-(\d{2})T(\d{2})\:(\d{2})\:(\d{2})/)
    ) {
      input[key] = new Date(value + "Z");
      if (Object.prototype.toString.call(input[key]) === "[object Date]") {
        // it is a date
        if (isNaN(input[key].getTime())) {
          // d.valueOf() could also work
          input[key] = value;
        }
      } else {
        // not a date
        input[key] = value;
      }
    } else if (typeof value === "object") {
      // Recurse into object
      convertDateStringsToDates(value, toLocal);
    }
  }
  return input;
}

String.prototype.isNumber = function () {
  return /^\d+$/.test(this);
};

String.prototype.startsWith = function (needle: string) {
  return this.indexOf(needle) == 0;
};

declare global {
  interface String {
    isNumber(): boolean;
    startsWith(needle: string): boolean;
  }
  interface Object {
    watch(prop: PropertyDescriptor, handler: Function): Object;
    unwatch(prop: PropertyDescriptor): any;
  }

  interface Array<T> {
    mapProperty(property: PropertyDescriptor): any;
  }

  interface Array<T> {
    find(
      predicate: (value: T, index: number, obj: Array<T>) => boolean,
      thisArg?: any,
    ): T | undefined;
  }
}

// object.watch
if (!Object.prototype.watch) {
  Object.defineProperty(Object.prototype, "watch", {
    enumerable: false,
    configurable: true,
    writable: false,
    value: function (prop: any, handler: any) {
      var oldval = this[prop],
        newval = oldval,
        getter = function () {
          return newval;
        },
        setter = function (val: any) {
          oldval = newval;
          newval = handler.call(this, prop, oldval, val);
          return newval;
        };
      if (delete this[prop]) {
        // can't watch constants
        Object.defineProperty(this, prop, {
          get: getter,
          set: setter,
          enumerable: true,
          configurable: true,
        });
      }
    },
  });
}

// object.unwatch
if (!Object.prototype.unwatch) {
  Object.defineProperty(Object.prototype, "unwatch", {
    enumerable: false,
    configurable: true,
    writable: false,
    value: function (prop: any) {
      var val = this[prop];
      delete this[prop]; // remove accessors
      this[prop] = val;
    },
  });
}

Array.prototype.mapProperty = function (property: any) {
  return this.map(function (obj: any) {
    return obj[property];
  });
};

if (!Array.prototype.find) {
  Object.defineProperty(Array.prototype, "find", {
    value: function (predicate: any) {
      // 1. Let O be ? ToObject(this value).
      if (this == null) {
        throw new TypeError('"this" is null or not defined');
      }

      var o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0;

      // 3. If IsCallable(predicate) is false, throw a TypeError exception.
      if (typeof predicate !== "function") {
        throw new TypeError("predicate must be a function");
      }

      // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
      var thisArg = arguments[1];

      // 5. Let k be 0.
      var k = 0;

      // 6. Repeat, while k < len
      while (k < len) {
        // a. Let Pk be ! ToString(k).
        // b. Let kValue be ? Get(O, Pk).
        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
        // d. If testResult is true, return kValue.
        var kValue = o[k];
        if (predicate.call(thisArg, kValue, k, o)) {
          return kValue;
        }
        // e. Increase k by 1.
        k++;
      }

      // 7. Return undefined.
      return undefined;
    },
  });
}

export default ccqapp;
