import { AdminService } from "../services/AdminService";

export class AdminController {
  uploadingFiles: boolean;

  static $inject = ["AdminService"];

  constructor(private $admin: AdminService) {}

  selectFile(file: File) {
    if (!file) {
      return;
    }

    var reader = new FileReader();
    reader.onload = (evt: ProgressEvent) => {
      this.uploadingFiles = true;
      this.uploadFile(file.name, (<FileReader>evt.target).result);
    };

    reader.readAsDataURL(file);
  }

  uploadFile(file_name: string, file: any) {
    this.$admin
      .uploadStarkData(file_name, file)
      .then((response) => {
        this.uploadingFiles = false;
      })
      .catch((response) => {
        this.uploadingFiles = false;
      });
  }
}
