import angular from "angular";
import { BaseService } from "../filesfromccqbase/BaseService";
import { BroadcastService } from "../filesfromccqbase/BroadcastService";
import { BillDTO } from "@js/oldmodels/DTOs/BillDTO.cs.d";

export class BillValidationService extends BaseService<BillDTO, number> {
  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/bill";
    this.$broadcastBusinessNameSingular = "bill";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }

  // #region Bills
  fetchAll(): ng.IPromise<BillDTO[]> {
    let defer = this.$q.defer<BillDTO[]>();
    this.fetchAllInternal(
      this.$broadcastScreen,
      "Unable to get a list of " +
        this.$broadcastBusinessNamePlural +
        " from the  Please try again shortly.",
    ).then((response) => {
      // Convert JSON addresses to objects.
      for (let i = 0; i < response.length; i++) {
        this.convertDatesAndTimes(response[i]);
      }
      defer.resolve(response);
    });
    return defer.promise;
  }

  fetchBillsForCustomer(id: number): ng.IPromise<BillDTO[]> {
    let defer = this.$q.defer<BillDTO[]>();
    this.$http
      .get("/api/bill/customer/" + id.toString())
      .then((response) => {
        let bills: BillDTO[] = response.data as BillDTO[];
        for (let i = 0; i < bills.length; i++) {
          this.convertDatesAndTimes(bills[i]);
        }
        defer.resolve(response.data as BillDTO[]);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "assurance",
          "There was a problem fetching the bills. Please try again later.",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  countOtherBillsByDate(bill: BillDTO): ng.IPromise<number> {
    bill.DateFrom = new Date(bill.DateFrom.toISOString());
    bill.DateTo = new Date(bill.DateTo.toISOString());
    let defer = this.$q.defer<number>();
    this.$http
      .get(
        "/api/bill/daterange/?billId=" +
          bill.Id.toString() +
          "&meterId=" +
          bill.MeterId.toString() +
          "&startDate=" +
          bill.DateFrom.toISOString() +
          "&endDate=" +
          bill.DateTo.toISOString(),
      )
      .then((response) => {
        defer.resolve(response.data as number);
      })
      .catch((response) => {
        //this.$broadcastservice.broadcastError("assurance", "There was a problem fetching the bill. Please try again later.");
        defer.reject(response);
      });
    return defer.promise;
  }
  addUpdate(dtos: BillDTO): ng.IPromise<BillDTO> {
    let tmpBill = angular.copy(dtos);
    tmpBill.DateFrom.setHours(0, 0, 0);
    tmpBill.DateTo.setHours(0, 0, 0);

    // Convert Rates from Dates to TimeSpans and set dates to match parent bill.
    if (tmpBill.Rates) {
      for (let i = 0; i < tmpBill.Rates.length; i++) {
        let rate = tmpBill.Rates[i];
        //rate.DateFrom = new Date(tmpBill.DateFrom.setTime(0));
        //rate.DateTo = new Date(tmpBill.DateTo.setTime(0));
        rate.DateFrom = tmpBill.DateFrom;
        rate.DateTo = tmpBill.DateTo;

        try {
          rate.RateFromTime =
            "0." +
            (rate.RateFromTime as Date).getHours() +
            ":" +
            (rate.RateFromTime as Date).getMinutes() +
            ":00.0000";
          rate.RateToTime =
            "0." +
            (rate.RateToTime as Date).getHours() +
            ":" +
            (rate.RateToTime as Date).getMinutes() +
            ":00.0000";
        } catch {}
      }
    }
    //dtos.AssociatedCustomerId = dtos.Meter.AssociatedCustomerId;

    return this.addUpdateNew(tmpBill);
  }

  addUpdateNew(dtos: BillDTO): ng.IPromise<BillDTO> {
    let defer = this.$q.defer<BillDTO>();

    dtos = this.processDtoBeforeAddUpdate(dtos);

    this.$http
      .post(this.$baseAddress + "/addupdatenew", JSON.stringify(dtos))
      .then((response) => {
        defer.resolve(response.data as BillDTO);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          this.$broadcastScreen,
          "Unable to add update " +
            this.$broadcastBusinessNamePlural +
            " from the  Please try again shortly.",
        );
        defer.reject(response);
      });
    return defer.promise;
  }
  // #endregion

  // #region Validation

  /**
   * Returns actual usage data along with the contract for this meter.
   * This allows the client to do the comparison and show discrepencies on screen.
   * @param meterId
   * @param total
   * @param startDate
   * @param endDate
   */
  // [FromUri] DateTime startDate, [FromUri] DateTime endDate, [FromUri] CCQ_DBIdType meterId
  getBillComparisonData(
    meterId: number,
    startDate: Date,
    endDate: Date,
  ): ng.IPromise<BillDTO> {
    let defer = this.$q.defer<BillDTO>();
    this.$http
      .get(
        "/api/bill/validate/getdata?meterId=" +
          meterId +
          "&startDate=" +
          startDate.toISOString() +
          "&endDate=" +
          endDate.toISOString(),
      )
      .then((response) => {
        defer.resolve(response.data as BillDTO);
      })
      .catch((response) => {
        if (response.status === 400) {
          // No active contract found for billing period
          this.$broadcastservice.broadcastWarning(
            "assurance",
            "No active contract was found for the selected billing period and meter.",
          );
        } else if (response.status === 409) {
          // More than one active contract found for billing period
          this.$broadcastservice.broadcastWarning(
            "assurance",
            "More than one active contract was found for the selected billing period and meter.",
          );
        } else {
          this.$broadcastservice.broadcastError(
            "assurance",
            "There was a problem validating the bill. Please try again later.",
          );
        }
        defer.reject(response);
      });
    return defer.promise;
  }

  /**
   * Validates the components of the bill against the contract and use.
   * @param bill
   * @param startDate
   * @param endDate
   */
  // [FromUri] DateTime startDate, [FromUri] DateTime endDate, [FromBody] BillDTO bill
  validateBill(
    bill: BillDTO,
    startDate: Date,
    endDate: Date,
  ): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.$http
      .put(
        "/api/bill/validate/bill?startDate=" +
          startDate.toISOString() +
          "&endDate=" +
          endDate.toISOString(),
        JSON.stringify(bill),
      )
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "assurance",
          "There was a problem validating the bill. Please try again later.",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  // #endregion

  convertDatesAndTimes(bill: BillDTO) {
    bill.DateFrom = new Date(bill.DateFrom.toString());
    bill.DateTo = new Date(bill.DateTo.toString());

    if (bill.Rates) {
      for (let i = 0; i < bill.Rates.length; i++) {
        let rate = bill.Rates[i];

        rate.DateFrom = new Date(rate.DateFrom.toString());
        rate.DateTo = new Date(rate.DateTo.toString());

        let rateFromTime: string[] = rate.RateFromTime.split(":");
        rate.RateFromTime = new Date();
        rate.RateFromTime.setHours(
          parseInt(rateFromTime[0]),
          parseInt(rateFromTime[1]),
          parseInt(rateFromTime[2]),
          0,
        );

        let rateToTime: string[] = rate.RateToTime.split(":");
        rate.RateToTime = new Date();
        rate.RateToTime.setHours(
          parseInt(rateToTime[0]),
          parseInt(rateToTime[1]),
          parseInt(rateToTime[2]),
          0,
        );
      }
    }

    if (bill.Charges) {
      for (let i = 0; i < bill.Charges.length; i++) {
        let charge = bill.Charges[i];

        if (charge.DateFrom) {
          charge.DateFrom = new Date(charge.DateFrom.toString());
        }
        if (charge.DateTo) {
          charge.DateTo = new Date(charge.DateTo.toString());
        }
      }
    }
  }
}
