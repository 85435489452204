import { AuthService } from "../services/AuthService";
import { AdminService } from "../services/AdminService";
import { UserService } from "../services/UserService";
import { ApplicationUserDTO } from "@js/oldmodels/DTOs/ApplicationUserDTO.cs.d";

export class UserManagementController {
  users: ApplicationUserDTO[];
  selectedUser: ApplicationUserDTO;

  loadingUsers: boolean;
  error: boolean;

  userform: ng.IFormController;

  static $inject = [
    "$scope",
    "$rootScope",
    "$cookies",
    "$location",
    "$http",
    "AuthService",
    "AdminService",
    "UserService",
  ];

  constructor(
    private $scope: ng.IScope,
    private $rootScope: ng.IRootScopeService,
    private $cookies: ng.cookies.ICookiesService,
    private $location: ng.ILocationService,
    private $http: ng.IHttpService,
    private $auth: AuthService,
    private $admin: AdminService,
    private $userservice: UserService,
  ) {}

  selectUser(user: ApplicationUserDTO) {
    this.selectedUser = user;
  }

  createUser() {
    this.selectedUser = {} as ApplicationUserDTO;
  }

  save() {
    this.$userservice.addUpdate(this.selectedUser).then((response) => {
      this.selectedUser = response;
      if (!this.users) {
        this.users = [];
      }

      let matches: ApplicationUserDTO[] = this.users.filter((value, index) => {
        return value.Id == response.toString();
      });

      if (!matches || matches.length === 0) {
        this.users.push(this.selectedUser);
      }

      this.userform.$setPristine();
    });
  }

  delete() {
    this.$userservice.delete(this.selectedUser.Id).then((response) => {
      if (response) {
        this.users.splice(this.users.indexOf(this.selectedUser), 1);
        this.userform.$setPristine();
        delete this.selectedUser;
      }
    });
  }
}
