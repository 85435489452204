import { TenderDTO } from "@js/oldmodels/DTOs/TenderDTO.cs.d";
import { BaseService } from "../filesfromccqbase/BaseService";
import { BroadcastService } from "../filesfromccqbase/BroadcastService";
export class TenderService extends BaseService<TenderDTO, number> {
  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/tender";
    this.$broadcastBusinessNameSingular = "Tender";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }

  fixTimestamps(tenders: TenderDTO[]) {
    tenders.forEach((tender, index) => {
      tender.Offers.forEach((offer, index) => {
        offer.Rates.forEach((rate, index) => {
          let rateFrom = rate.RateFromTime.split(":");
          rate.RateFromTime = new Date();
          rate.RateFromTime.setHours(rateFrom[0]);
          rate.RateFromTime.setMinutes(rateFrom[1]);
          rate.RateFromTime.setSeconds(0);
          rate.RateFromTime.setMilliseconds(0);

          let rateTo = rate.RateToTime.split(":");
          rate.RateToTime = new Date();
          rate.RateToTime.setHours(rateTo[0]);
          rate.RateToTime.setMinutes(rateTo[1]);
          rate.RateToTime.setSeconds(0);
          rate.RateToTime.setMilliseconds(0);
        });
      });
    });
  }

  /**
   * This will create a tender for each meter and return a CSV, in string format, including details for all meters for sending to suppliers.
   * @param meters
   */
  buildTenderForMetersAndFetchCSV(
    meters: number[],
    startDateOverride: Date,
  ): ng.IPromise<string> {
    let defer = this.$q.defer<string>();
    this.$http
      .post(
        "/api/tender/generatetenders?startDateOverride=" +
          (startDateOverride ? startDateOverride.toISOString() : ""),
        JSON.stringify(meters),
      )
      .then((response) => {
        defer.resolve(response.data as string);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "tenders",
          "Unable to generate tenders. Please try again shortly.",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  fetchTenders(filter: string = ""): ng.IPromise<TenderDTO[]> {
    let defer = this.$q.defer<TenderDTO[]>();
    this.$http
      .get("/api/tender/state/" + filter)
      .then((response) => {
        let tenders: TenderDTO[] = response.data as TenderDTO[];

        this.fixTimestamps(tenders);

        defer.resolve(tenders);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "tenders",
          "Unable to get a list of tenders from the  Please try again shortly.",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  fetchTendersForMeter(id: number): ng.IPromise<TenderDTO[]> {
    let defer = this.$q.defer<TenderDTO[]>();
    this.$http
      .get("/api/tender/meter/" + id.toString())
      .then((response) => {
        let tenders: TenderDTO[] = response.data as TenderDTO[];

        this.fixTimestamps(tenders);

        defer.resolve(tenders);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "tenders",
          "Unable to get a list of tenders from the  Please try again shortly.",
        );
        console.log(response);
        defer.reject(response);
      });
    return defer.promise;
  }

  countExpiringTenders(days: number): ng.IPromise<number> {
    let defer = this.$q.defer<number>();
    this.$http
      .get("/api/tender/count/expiring?days=" + days.toString())
      .then((response) => {
        defer.resolve(response.data as number);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }
}
