import * as ng from "angular";

interface IpdfdownlaodScope extends ng.IScope {
  downloadPdf: any;
  name: string;
  icon: string;
}

export function instantiateCcqDirectives() {
  ng.module("CcqDirectives", [])

    .directive("scrollBottom", function ($document) {
      return {
        restrict: "A",
        link: function (scope, element, attrs) {
          var el = element[0];
          element.bind("scroll", function () {
            if (el.scrollTop + el.offsetHeight >= el.scrollHeight) {
              scope.$apply(attrs.scrollBottom);
            }
          });
        },
      };
    })

    .directive("ngConfirmClick", [
      function () {
        return {
          link: function (scope, element, attr) {
            var msg = attr.ngConfirmClick || "Are you sure?";
            var clickAction = attr.confirmedClick;
            element.bind("click", function (event) {
              if (window.confirm(msg)) {
                scope.$eval(clickAction);
              }
            });
          },
        };
      },
    ])

    .directive("convertToBoolean", function () {
      return {
        require: "ngModel",
        link: function (scope, element, attrs, ngModel) {
          (ngModel as any).$parsers.push(function (val: any) {
            if (val === undefined) {
              return undefined;
            }

            return val === "true";
          });
          (ngModel as any).$formatters.push(function (val: any) {
            return "" + val;
          });
        },
      };
    })

    .directive("convertToNumber", function () {
      return {
        require: "ngModel",
        link: function (scope, element, attrs, ngModel) {
          (ngModel as any).$parsers.push(function (val: any) {
            return parseInt(val, 10);
          });
          (ngModel as any).$formatters.push(function (val: any) {
            //chek if flagged

            return "" + val;
          });
        },
      };
    })
    //this is used for enums set with flags with mulit option ability
    .directive("convertToNumberm", function () {
      return {
        require: "ngModel",
        link: function (scope, element, attrs, ngModel) {
          (ngModel as any).$parsers.push(function (val: any) {
            if (Array.isArray(val)) {
              var res: number = parseInt(val[0], 10);

              //if an array multi select enum
              val.forEach((x) => (res = parseInt(x, 10) | res));
              return res;
            } else {
              return parseInt(val, 10);
            }
          });
          (ngModel as any).$formatters.push(function (val: any) {
            //chek if flagged
            var ret: string[] = [];
            //deal with top 0 being selected
            if (val == 0) {
              ret.push("0");
              return ret;
            }

            var res: string = parseInt(val, 10).toString(2);
            var currentenumval: number = 1;
            for (var i = res.length - 1; i >= 0; i--) {
              if (res.charAt(i) == "1") {
                ret.push(currentenumval.toString());
              }
              currentenumval = currentenumval * 2;
            }
            return ret;
          });
        },
      };
    })
    .directive("moveNext", function () {
      return {
        require: "ngModel",
        link: function (scope, element, attrs, ngModel) {
          element.bind("keyup", (e) => {
            if (
              (element.val() as string).length === parseInt(attrs["moveNext"])
            ) {
              (element.next()[0] as any).focus();
            }
          });
        },
      };
    })
    .directive("currency", [
      "$filter",
      function ($filter) {
        return {
          require: "?ngModel",
          link: function (scope, elem, attrs, ctrl: any) {
            if (!ctrl) return;

            ctrl.$formatters.unshift(function (a: any) {
              return $filter("currency")(ctrl.$modelValue, "");
            });
          },
        };
      },
    ])
    .directive("decimalPlace", [
      "$filter",
      function ($filter) {
        return {
          require: "?ngModel",
          link: function (scope, elem, attrs, ctrl: any) {
            if (!ctrl) return;

            ctrl.$formatters.push(function (a: any) {
              if (!a || a.length == 0) {
                return a;
              }
              const value = parseFloat(a);
              return value.toFixed(attrs.decimalPlace);
            });
            elem.bind("blur", function (e) {
              const value = parseFloat(ctrl.$modelValue);
              elem.val(value.toFixed(attrs.decimalPlace));
              elem.triggerHandler("input");
            });
          },
        };
      },
    ])

    .directive("currencyMask", function () {
      return {
        restrict: "A",
        require: "ngModel",
        link: function (scope, element, attrs, ngModelController: any) {
          // Run formatting on keyup
          var numberWithCommas = function (value: any, addExtraZero: any) {
            if (addExtraZero == undefined) addExtraZero = false;
            value = value.toString();
            value = value.replace(/[^0-9\.]/g, "");
            var parts = value.split(".");
            parts[0] = parts[0].replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, "$&,");
            if (parts[1] && parts[1].length > 2) {
              parts[1] = parts[1].substring(0, 2);
            }
            if (addExtraZero && parts[1] && parts[1].length === 1) {
              parts[1] = parts[1] + "0";
            }
            return parts.join(".");
          };
          var applyFormatting = function () {
            var value = element.val();
            var original = value;
            if (!value || (value as string).length == 0) {
              return;
            }
            value = numberWithCommas(value, false);
            if (value != original) {
              element.val(value);
              element.triggerHandler("input");
            }
          };
          element.bind("keyup", (e: any) => {
            var keycode = e.keyCode;
            let originalPosition = (e.target as HTMLInputElement)
              .selectionStart;
            var isTextInputKey =
              (keycode > 47 && keycode < 58) || // number keys
              keycode == 32 ||
              keycode == 8 ||
              keycode == 46 ||
              keycode == 229 || // spacebar or backspace/delete (or android)
              (keycode > 64 && keycode < 91) || // letter keys
              (keycode > 95 && keycode < 112) || // numpad keys
              (keycode > 185 && keycode < 193) || // ;=,-./` (in order)
              (keycode > 218 && keycode < 223); // [\]' (in order)
            if (isTextInputKey) {
              let originalLength = (element.val() as string).length;
              applyFormatting();
              // If our length changed (due to commas being added), we need to adjust our position.
              originalPosition =
                originalPosition +
                ((element.val() as string).length - originalLength);
              if (originalPosition < 0) {
                originalPosition = 0;
              }
              (e.target as HTMLInputElement).selectionStart = originalPosition;
              (e.target as HTMLInputElement).selectionEnd = originalPosition;
            }
          });
          ngModelController.$parsers.push(function (value: any) {
            if (!value || value.length == 0) {
              return value;
            }
            value = value.toString();
            value = value.replace(/[^0-9\.]/g, "");
            return value;
          });
          ngModelController.$formatters.push(function (value: any) {
            if (!value || value.length == 0) {
              return value;
            }
            value = numberWithCommas(value, true);
            return value;
          });
        },
      };
    })
    //.filter('percentage', ['$filter', function ($filter) {
    //return function (input, decimals) {

    //    return $filter('number')(input * 100, decimals) + '%';
    //};

    //}])
    .directive("pdfDownload", function () {
      return {
        restrict: "E",
        templateUrl: "/Views/pdfdownload.html",
        scope: true,
        link: function (scope: IpdfdownlaodScope, element, attr) {
          var anchor = element.children()[0];
          scope.name = attr.controlname;
          // When the download starts, do nothing at moment but maybe display somthing
          scope.$on("download-start", function () {});

          // When the download finishes, attach the data to the link. and init the click event
          scope.$on("downloaded", function (event, data, filename) {
            var fileURL = window.URL.createObjectURL(data);
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.href = fileURL;
            if (attr.filename && attr.filename.length > 0) {
              a.download = attr.filename;
            } else {
              a.download = filename;
            }
            a.click();
          });
        },
        controller: [
          "$scope",
          "$attrs",
          "$http",
          function ($scope, $attrs, $http) {
            $scope.downloadPdf = () => {
              $scope.$emit("download-start");
              $http
                .get($attrs.url, {
                  responseType: "arraybuffer",
                })
                .then((response: any) => {
                  var filename = "";
                  var disposition = response.headers()["content-disposition"];
                  if (disposition && disposition.indexOf("attachment") !== -1) {
                    var filenameRegex =
                      /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    var matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1]) {
                      filename = matches[1].replace(/['"]/g, "");
                    }
                  }
                  var dispostype = response.headers()["content-type"];
                  if (!dispostype) {
                    dispostype = "octet/stream";
                  }
                  var blob = new Blob([response.data], {
                    type: dispostype,
                  });
                  $scope.$emit("downloaded", blob, filename);
                });
            };
          },
        ],
      };
    });
}
