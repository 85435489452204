import angular from "angular";
import { BaseService } from "../filesfromccqbase/BaseService";
import { BroadcastService } from "../filesfromccqbase/BroadcastService";
import { CreditNoteDTO } from "@js/oldmodels/DTOs/CreditNoteDTO.cs.d";

export class CreditNoteService extends BaseService<CreditNoteDTO, number> {
  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/creditnote";
    this.$broadcastBusinessNameSingular = "CreditNote";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }

  fetchCreditNotesForCustomer(id: number): ng.IPromise<CreditNoteDTO[]> {
    let defer = this.$q.defer<CreditNoteDTO[]>();
    this.$http
      .get("/api/creditnote/customer/" + id.toString())
      .then((response) => {
        let creditNotes: CreditNoteDTO[] = response.data as CreditNoteDTO[];
        for (let i = 0; i < creditNotes.length; i++) {
          //this.convertDatesAndTimes(creditNotes[i]);
        }
        defer.resolve(response.data as CreditNoteDTO[]);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "assurance",
          "There was a problem fetching the creditNotes. Please try again later.",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  countOtherCreditNotesByDate(creditNote: CreditNoteDTO): ng.IPromise<number> {
    let defer = this.$q.defer<number>();
    this.$http
      .get(
        "/api/creditnote/daterange/?creditNoteId=" +
          creditNote.Id.toString() +
          "&meterId=" +
          creditNote.MeterId.toString() +
          "&startDate=" +
          creditNote.DateFrom.toISOString() +
          "&endDate=" +
          creditNote.DateTo.toISOString(),
      )
      .then((response) => {
        defer.resolve(response.data as number);
      })
      .catch((response) => {
        //this.$broadcastservice.broadcastError("assurance", "There was a problem fetching the creditNote. Please try again later.");
        defer.reject(response);
      });
    return defer.promise;
  }
  addUpdate(dtos: CreditNoteDTO): ng.IPromise<CreditNoteDTO> {
    let tmpCreditNote = angular.copy(dtos);
    tmpCreditNote.DateFrom.setHours(0, 0, 0);
    tmpCreditNote.DateTo.setHours(0, 0, 0);

    //tmpCreditNote.AssociatedCustomerId = dtos.Meter.AssociatedCustomerId;

    return this.addUpdateInternal(
      tmpCreditNote,
      this.$broadcastScreen,
      "Unable to add update " +
        this.$broadcastBusinessNamePlural +
        " from the  Please try again shortly.",
    );
  }
}
