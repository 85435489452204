import { BroadcastService } from "../filesfromccqbase/BroadcastService";
import { BaseService } from "../filesfromccqbase/BaseService";
import { MeterDTO } from "@js/oldmodels/DTOs/MeterDTO.cs.d";

export class MeterService extends BaseService<MeterDTO, number> {
  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/meter";
    this.$broadcastBusinessNameSingular = "Meter";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }

  countMetersWithoutContracts(): ng.IPromise<number> {
    let defer = this.$q.defer<number>();
    this.$http
      .get("/api/meter/count/outofcontract")
      .then((response) => {
        defer.resolve(response.data as number);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "meters",
          "Unable to get a list of meters from the  Please try again shortly.",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  countMetersWithMissingHHData(): ng.IPromise<number> {
    let defer = this.$q.defer<number>();
    this.$http
      .get("/api/meter/count/missinghhdata")
      .then((response) => {
        defer.resolve(response.data as number);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "meters",
          "Unable to get a list of meters from the  Please try again shortly.",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  countMetersWithZeroValueHHData(): ng.IPromise<number> {
    let defer = this.$q.defer<number>();
    this.$http
      .get("/api/meter/count/zerovaluehhdata")
      .then((response) => {
        defer.resolve(response.data as number);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "meters",
          "Unable to get a list of meters from the  Please try again shortly.",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  fetchMetersForCustomer(
    id: number,
    filter: string = "",
  ): ng.IPromise<MeterDTO[]> {
    let defer = this.$q.defer<MeterDTO[]>();
    this.$http
      .get("/api/meter/customer/" + id.toString() + "/" + filter)
      .then((response) => {
        defer.resolve(response.data as MeterDTO[]);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "meters",
          "Unable to get a list of meters from the  Please try again shortly.",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  fetchMetersForSite(id: number): ng.IPromise<MeterDTO[]> {
    let defer = this.$q.defer<MeterDTO[]>();
    this.$http
      .get("/api/meter/site/" + id.toString())
      .then((response) => {
        defer.resolve(response.data as MeterDTO[]);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "meters",
          "Unable to get a list of meters from the  Please try again shortly.",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  addUpdateNew(dtos: MeterDTO): ng.IPromise<MeterDTO> {
    let defer = this.$q.defer<MeterDTO>();

    dtos = this.processDtoBeforeAddUpdate(dtos);

    this.$http
      .post(this.$baseAddress + "/addupdatenew", JSON.stringify(dtos))
      .then((response) => {
        defer.resolve(response.data as MeterDTO);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          this.$broadcastScreen,
          "Unable to add update " +
          this.$broadcastBusinessNamePlural +
          " from the  Please try again shortly.",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  fetchNew(id: number): ng.IPromise<MeterDTO> {
    let defer = this.$q.defer<MeterDTO>();
    this.$http
      .get(this.$baseAddress + "/newfetch/" + id.toString())
      .then((response) => {
        defer.resolve(response.data as MeterDTO);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          this.$broadcastScreen,
          "Unable to get a list of " +
          this.$broadcastBusinessNamePlural +
          " from the  Please try again shortly.",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  ListNew(): ng.IPromise<MeterDTO[]> {
    let defer = this.$q.defer<MeterDTO[]>();
    this.$http
      .get(this.$baseAddress + "/newlist")
      .then((response) => {
        defer.resolve(response.data as MeterDTO[]);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          this.$broadcastScreen,
          "Unable to get a list of " +
          this.$broadcastBusinessNamePlural +
          " from the  Please try again shortly.",
        );
        defer.reject(response);
      });
    return defer.promise;
  }
}
