import { MeterAgencyAuthorityDTO } from "@js/oldmodels/DTOs/MeterAgencyAuthorityDTO.cs.d";
import { BaseService } from "../filesfromccqbase/BaseService";
import { BroadcastService } from "../filesfromccqbase/BroadcastService";

export class MeterAgencyAuthorityService extends BaseService<
  MeterAgencyAuthorityDTO,
  number
> {
  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/meteragencyauthority";
    this.$broadcastBusinessNameSingular = "MeterAgenceyAuthority";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "MeterAgenceyAuthorities";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }

  fetchUnverifiedMeterAgencyAuthorities(): ng.IPromise<
    MeterAgencyAuthorityDTO[]
  > {
    let defer = this.$q.defer<MeterAgencyAuthorityDTO[]>();
    this.$http
      .get("/api/meteragencyauthority/unverified")
      .then((response) => {
        defer.resolve(response.data as MeterAgencyAuthorityDTO[]);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  verifyMeterAgencyAuthority(
    meterAgencyAuthority: MeterAgencyAuthorityDTO,
  ): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.$http
      .put(
        "/api/verify/" + meterAgencyAuthority.Id,
        JSON.stringify(meterAgencyAuthority),
      )
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }
}
