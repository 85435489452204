import { AuthService } from "../services/AuthService";
import { BroadcastService } from "../filesfromccqbase/BroadcastService";
import { ApplicationUserDTO } from "@js/oldmodels/DTOs/ApplicationUserDTO.cs.d";
import { RegistrationRequestDTO } from "@js/oldmodels/DTOs/RegistrationRequestDTO.cs.d";

export class LoginController {
  dataLoading: boolean = false;
  username: string;
  password: string;

  // For password reset;
  email: string;

  resetSubmitted: boolean;
  resettingPassword: boolean;

  registrationSubmitted: boolean;
  registeringAccount: boolean;

  error: string;

  newUser: RegistrationRequestDTO = {
    Email: "",
    FirstName: "",
    LastName: "",
  } as RegistrationRequestDTO;
  confirmEmailAddress: string;

  static $inject = [
    "$scope",
    "$rootScope",
    "$cookies",
    "$location",
    "$http",
    "$httpParamSerializerJQLike",
    "AuthService",
    "BroadcastService",
  ];

  constructor(
    private $scope: ng.IScope,
    private $rootScope: ng.IRootScopeService,
    private $cookies: ng.cookies.ICookiesService,
    private $location: ng.ILocationService,
    private $http: ng.IHttpService,
    private $httpParamSerializerJQLike: ng.IHttpParamSerializer,
    private authService: AuthService,
    private broadcast: BroadcastService,
  ) {
    // $rootScope.title = "Login";
    if ($cookies.get("access_token")) {
      $location.path("dashboard");
    }
  }

  newlogin() {
    this.dataLoading = true;
    this.$http({
      method: "POST",
      url: "/auth/token",
      data: this.$httpParamSerializerJQLike({
        grant_type: "password",
        username: this.username,
        password: this.password,
        client_id: "CC022EBE67BE0424EA2A6548B062D2D71",
      }),
      headers: { "Content-Type": "text/plain" },
    });
  }

  login() {
    this.dataLoading = true;
    this.$http.defaults.headers.common["Authorization"] =
      "Basic " +
      this.authService.base64encode(this.username + ":" + this.password);
    this.$http({
      method: "POST",
      url: "/oauth/token",
      data: this.$httpParamSerializerJQLike({
        grant_type: "password",
        username: this.username,
        password: this.password,
        client_id: "CC022EBE67BE0424EA2A6548B062D2D71",
      }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
      .then((response) => {
        let t = new Date();
        t = t.addHours(12);

        this.$cookies.put("access_token", (response.data as any).access_token, {
          expires: t,
        });
        this.$cookies.put("access_token_expires", t.toISOString(), {
          expires: t,
        });
        this.$http.defaults.headers.common.Authorization =
          "Bearer " + (response.data as any).access_token;

        this.authService
          .getProfile()
          .then((response) => {
            this.$cookies.put(
              "user_type",
              (response as ApplicationUserDTO).UserType.toString(),
              {
                expires: t,
              },
            );

            this.$cookies.put("user_firstname", response.FirstName, {
              expires: t,
            });

            this.$location.path("/dashboard");
          })
          .catch((response) => {
            this.broadcast.broadcastError(
              "login",
              "There was a problem getting your details.",
            );
            this.error = response;
            this.dataLoading = false;
          });
      })
      .catch((response) => {
        this.broadcast.broadcastError(
          "login",
          "There was a problem logging in. Check your username and password and try again.",
        );
        this.error = response;
        this.dataLoading = false;
      });
  }

  resetPassword() {
    this.authService.resetPassword(this.email).then((response) => {
      this.resetSubmitted = true;
    });
  }

  clearPasswordReset() {
    this.resettingPassword = false;
    this.resetSubmitted = false;
  }

  registerAccount() {
    this.registrationSubmitted = true;
    // send registration
    this.authService
      .registerAccount(this.newUser)
      .then((response) => {
        this.newUser = {
          Email: "",
          FirstName: "",
          LastName: "",
        } as RegistrationRequestDTO;
        this.confirmEmailAddress = "";
      })
      .catch((response) => {});
  }

  clearRegistration() {
    this.registeringAccount = false;
    this.registrationSubmitted = false;
  }
}
