import angular from "angular";
import { BaseService } from "../filesfromccqbase/BaseService";
import { BroadcastService } from "../filesfromccqbase/BroadcastService";
import { AgencyDTO } from "@js/oldmodels/DTOs/AgencyDTO.cs.d";

export class AgencyService extends BaseService<AgencyDTO, number> {
  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/agency";
    this.$broadcastBusinessNameSingular = "agency";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }

  fetchAll(): ng.IPromise<AgencyDTO[]> {
    let defer = this.$q.defer<AgencyDTO[]>();
    this.fetchAllInternal(
      this.$broadcastScreen,
      "Unable to get a list of " +
        this.$broadcastBusinessNamePlural +
        " from the  Please try again shortly.",
    ).then((response) => {
      // Convert JSON addresses to objects.
      angular.forEach(response, (agency, index) => {
        if (agency.Address) {
          try {
            agency.Address = JSON.parse(agency.Address);
          } catch (e) {
            agency.Address = {} as any;
          }
        } else {
          agency.Address = {} as any;
        }
      });
      defer.resolve(response);
    });
    return defer.promise;
  }
  fetch(id: number): ng.IPromise<AgencyDTO> {
    let defer = this.$q.defer<AgencyDTO>();
    this.fetchInternal(
      id,
      this.$broadcastScreen,
      "Unable to get a list of " +
        this.$broadcastBusinessNamePlural +
        " from the  Please try again shortly.",
    ).then((response) => {
      // Convert JSON addresses to objects.
      if (response.Address) {
        try {
          response.Address = JSON.parse(response.Address);
        } catch (e) {
          response.Address = {} as any;
        }
      } else {
        response.Address = {} as any;
      }
      defer.resolve(response);
    });
    return defer.promise;
  }
  addUpdate(dtos: AgencyDTO): ng.IPromise<AgencyDTO> {
    // Convert object addresses to JSON.
    let agencyCopy = angular.copy(dtos);
    agencyCopy.Address = JSON.stringify(dtos.Address);

    return this.addUpdateInternal(
      agencyCopy,
      this.$broadcastScreen,
      "Unable to add update " +
        this.$broadcastBusinessNamePlural +
        " from the  Please try again shortly.",
    );
  }

  fetchMyAgency(): ng.IPromise<AgencyDTO> {
    let defer = this.$q.defer<AgencyDTO>();
    this.$http
      .get("/api/agency/getmyagency")
      .then((response) => {
        // Convert JSON addresses to objects.
        if ((response.data as AgencyDTO).Address) {
          try {
            (response.data as AgencyDTO).Address = JSON.parse(
              (response.data as AgencyDTO).Address,
            );
          } catch (e) {
            (response.data as AgencyDTO).Address = {} as any;
          }
        } else {
          (response.data as AgencyDTO).Address = {} as any;
        }

        defer.resolve(response.data as AgencyDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }
}
