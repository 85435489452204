import {
  CCQClaimClient,
  CCQMagicLinkClient,
  CCQOTPClient,
} from "@react/apis/ModularCCQClient";
import { Context, createContext } from "react";

export interface APIs {
  claimClient: CCQClaimClient;
  magicLinkClient: CCQMagicLinkClient;
  otpClient: CCQOTPClient;
}

export const APIContext: Context<APIs> = createContext({}) as any;
