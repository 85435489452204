import {
  UsageReportDTO,
  UsageComparisonReportDTO,
} from "@js/oldmodels/DTOs/UsageReportDTO.cs.d";
import { BroadcastService } from "../filesfromccqbase/BroadcastService";

export class ReportService {
  static $inject = ["$http", "$q", "BroadcastService"];

  constructor(
    private $http: ng.IHttpService,
    private $q: ng.IQService,
    private $broadcastservice: BroadcastService,
  ) {}

  makeUTCDate(date: Date): Date {
    date = new Date(date);
    //let utcdate: Date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
    let utcdate: Date = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0),
    );
    return utcdate;
  }

  makeUTCDate23(date: Date): Date {
    date = new Date(date);
    //let utcdate: Date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
    let utcdate: Date = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59),
    );
    return utcdate;
  }

  fetchUsageReport(
    meters: number[],
    startDateTime: Date,
    endDateTime: Date,
    timeGranularity: string,
    withDetail: boolean = false,
  ): ng.IPromise<UsageReportDTO> {
    let defer = this.$q.defer<UsageReportDTO>();
    startDateTime = new Date(startDateTime);
    endDateTime = new Date(endDateTime);

    startDateTime = this.makeUTCDate(startDateTime);
    endDateTime = this.makeUTCDate23(endDateTime);
    this.$http
      .post(
        "/api/report/usage" +
          (withDetail ? "/detail" : "") +
          "?startDateTime=" +
          startDateTime.toISOString() +
          "&endDateTime=" +
          endDateTime.toISOString() +
          "&timeGranularity=" +
          timeGranularity,
        JSON.stringify(meters),
      )
      .then((response) => {
        defer.resolve(response.data as UsageReportDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  fetchComparisonReport(
    meters: number[][],
    startDateTime1: Date,
    endDateTime1: Date,
    startDateTime2: Date,
    endDateTime2: Date,
    timeGranularity: string,
  ): ng.IPromise<UsageComparisonReportDTO> {
    startDateTime1 = new Date(startDateTime1);
    endDateTime1 = new Date(endDateTime1);
    startDateTime2 = new Date(startDateTime2);
    endDateTime2 = new Date(endDateTime2);

    let defer = this.$q.defer<UsageComparisonReportDTO>();
    startDateTime1 = this.makeUTCDate(startDateTime1);
    startDateTime2 = this.makeUTCDate(startDateTime2);
    endDateTime1 = this.makeUTCDate23(endDateTime1);
    endDateTime2 = this.makeUTCDate23(endDateTime2);

    this.$http
      .post(
        "/api/report/Comparison?startDateTime1=" +
          startDateTime1.toISOString() +
          "&endDateTime1=" +
          endDateTime1.toISOString() +
          "&startDateTime2=" +
          startDateTime2.toISOString() +
          "&endDateTime2=" +
          endDateTime2.toISOString() +
          "&timeGranularity=" +
          timeGranularity,
        JSON.stringify(meters),
      )
      .then((response) => {
        defer.resolve(response.data as UsageComparisonReportDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }
  //: ng.IPromise<UsageReportDTO>
  usageexcel(
    meters: number[],
    startDateTime: Date,
    endDateTime: Date,
    timeGranularity: string,
    reportBillId: number,
    reportContractId: number,
  ) {
    startDateTime = new Date(startDateTime);
    endDateTime = new Date(endDateTime);

    //  let defer = this.$q.defer<UsageReportDTO>();
    startDateTime = this.makeUTCDate(startDateTime);
    endDateTime = this.makeUTCDate23(endDateTime);
    this.$http
      .post(
        "/api/report/usageexcel" +
          "?startDateTime=" +
          startDateTime.toISOString() +
          "&endDateTime=" +
          endDateTime.toISOString() +
          "&timeGranularity=" +
          timeGranularity +
          "&reportBillId=" +
          reportBillId +
          "&reportContractId=" +
          reportContractId,
        JSON.stringify(meters),
        {
          responseType: "arraybuffer",
        },
      )
      .then((response: any) => {
        var blob = new Blob([response.data], { type: "octet/stream" });
        var fileURL = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.href = fileURL;
        a.download = "BillValidaation.xlsx";
        a.click();

        //             defer.resolve(response.data as UsageReportDTO);
      })
      .catch((response) => {
        //          defer.reject(response);
      });
    //    return defer.promise;
  }
}
