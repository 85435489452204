import angular from "angular";
import { react2angular } from "react2angular";

import CCQAuthWrapper from "./react/components/CCQAuthWrapper";
import LoginCallback from "./react/components/LoginCallback";
import LoginButton from "./react/components/LoginButton";
import OtpSetupPage from "./react/login/OtpSetupPage";
import OtpVerificationPage from "./react/login/OtpVerificationPage";
import { RequestEmailPage } from "./react/login/RequestEmailPage";
import SMSVerificationPage from "./react/login/SMSVerificationPage";

export function registerReactComponents() {
  angular
    .module("ccqapp")
    .component("reactLogin", react2angular(LoginButton))
    .component(
      "ccqAuthWrapper",
      react2angular(CCQAuthWrapper, [], ["$rootScope"]),
    )
    .component("requestEmail", react2angular(RequestEmailPage))
    .component("otpSetup", react2angular(OtpSetupPage))
    .component("otpVerification", react2angular(OtpVerificationPage))
    .component("smsVerification", react2angular(SMSVerificationPage))
    .component(
      "loginCallback",
      react2angular(LoginCallback, [], ["$rootScope"]),
    );
}
