import { Button, Stack, Typography } from "@mui/material";
import { AuthErrorDetails, CCQOTPClient } from "@react/apis/ModularCCQClient";
import { FC, useState } from "react";
import { useIsAuthenticated } from "react-auth-kit";
import {
  BrowserRouter,
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";
import AppRoutes from "../AppRoutes";
import { ErrorMessage } from "../components/ErrorMessage";

const OtpSetupPageInternal: FC = () => {
  const isAuthenticated = useIsAuthenticated();
  console.log(isAuthenticated());
  const { welcomeId } = useParams();
  const [imageUrl, setImageUrl] = useState("");
  const [code, setCode] = useState("");
  const [error, setError] = useState<Partial<AuthErrorDetails>>();

  const navigate = useNavigate();

  const revealCode = async () => {
    setError(undefined);
    const client = new CCQOTPClient();
    try {
      const qrCode = await client.getQRCode(Number(welcomeId));
      const qrtextCode = await client.getQRCodeText(Number(welcomeId));
      setImageUrl(URL.createObjectURL(qrCode.data));
      setCode(qrtextCode);
    } catch (e: unknown) {
      console.log(e);
      setError(e as Partial<AuthErrorDetails>);
    }
  };

  if (!welcomeId)
    return (
      <Stack
        padding={20}
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <Typography>
          Invalid welcome link, please make you copied it correctly
        </Typography>
      </Stack>
    );
  return (
    <Stack
      padding={5}
      spacing={3}
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      {imageUrl ? (
        <Stack spacing={2} alignItems="center">
          <img src={imageUrl} width="50%" style={{ maxWidth: 400 }} />
          <Typography>{code}</Typography>
          <Stack>
            <Typography>Step 1: Install Authenticator App</Typography>
            <Typography>
              Step 2: Scan or enter QR code above in Authenticator
            </Typography>
          </Stack>
          <Button
            onClick={() => navigate(AppRoutes.RequestEmail)}
            variant="contained"
          >
            Click to log in for the first time
          </Button>
        </Stack>
      ) : (
        <Button
          sx={{ marginY: 20 }}
          onClick={() => revealCode()}
          disabled={!!imageUrl}
          variant="contained"
        >
          Click To Reveal QR Code
        </Button>
      )}

      {error && <ErrorMessage error={error} />}
    </Stack>
  );
};

const OtpSetupPage: FC = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/otp-setup/:welcomeId" element={<OtpSetupPageInternal />} />
    </Routes>
  </BrowserRouter>
);

export default OtpSetupPage;
