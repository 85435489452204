export class BroadcastService {
  public static $inject = ["$rootScope", "$q"];

  constructor(
    private $rootScope: ng.IRootScopeService,
    private $q: ng.IQService,
  ) {}

  public broadcastError(screen: string, message: string): void {
    this.$rootScope.$broadcast("error", {
      page: screen,
      severity: "error",
      error: message,
    });
  }

  public broadcastWarning(screen: string, message: string): void {
    this.$rootScope.$broadcast("error", {
      page: screen,
      severity: "warning",
      error: message,
    });
  }

  public broadcastInfo(screen: string, message: string): void {
    this.$rootScope.$broadcast("error", {
      page: screen,
      severity: "info",
      error: message,
    });
  }
}
