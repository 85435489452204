import { FileAttachmentDTO } from "@js/oldmodels/DTOs/FileAttachmentDTO.cs.d";
import { MeterDTO } from "@js/oldmodels/DTOs/MeterDTO.cs.d";
import { MeterUsageManualDTO } from "@js/oldmodels/DTOs/MeterUsageManualDTO.cs.d";
import { BaseService } from "../filesfromccqbase/BaseService";
import { BroadcastService } from "../filesfromccqbase/BroadcastService";

export class MeterUsageManualService extends BaseService<
  MeterUsageManualDTO,
  number
> {
  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/meterusagemanual";
    this.$broadcastBusinessNameSingular = "manual meter reading";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }

  uploadCSVMeterReadings(
    filename: string,
    file: any = null,
  ): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();

    let eventattachment: FileAttachmentDTO = {
      FileLocation: file,
      FileName: filename,
    } as FileAttachmentDTO;

    this.$http
      .put("/api/report/hhcsv", JSON.stringify(eventattachment))
      .then((response) => {
        defer.resolve(true);
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }

  export(meters: MeterDTO[], start: Date, end: Date): ng.IPromise<string> {
    let defer = this.$q.defer<string>();
    let meterIds: number[] = meters.map((meter) => {
      return meter.Id;
    });
    this.$http
      .post(
        "/api/report/testy?startDateTime=" +
          (start ? start.toISOString() : "") +
          "&endDateTime=" +
          (end ? end.toISOString() : ""),
        JSON.stringify(meterIds),
      )
      .then((response) => {
        defer.resolve(response.data as string);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "meters",
          "Unable to generate export. Please try again shortly.",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  addUpdateNew(dtos: MeterUsageManualDTO): ng.IPromise<MeterUsageManualDTO> {
    let defer = this.$q.defer<MeterUsageManualDTO>();

    dtos = this.processDtoBeforeAddUpdate(dtos);

    this.$http
      .post(this.$baseAddress + "/addupdatenew", JSON.stringify(dtos))
      .then((response) => {
        defer.resolve(response.data as MeterUsageManualDTO);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          this.$broadcastScreen,
          "Unable to add update " +
            this.$broadcastBusinessNamePlural +
            " from the  Please try again shortly.",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  RemoveRec(id: number): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.$http
      .delete(this.$baseAddress + "/removerec/" + id)
      .then((response) => {
        defer.resolve(true);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          this.$broadcastScreen,
          "Unable to delete " +
            this.$broadcastBusinessNamePlural +
            " from the  Please try again shortly.",
        );
        defer.reject(false);
      });
    return defer.promise;
  }
}
