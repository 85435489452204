import { CCQAuthClient, OidcConfigDTO } from "./apis/ModularCCQClient";

/**
 * `getOidcConfig`: Returns a function for getting the OidcConfig
 * @param {string} authProvider - Which provider you want to use. It can be "Local", "Microsoft" or "Google"
 */
export const getOidcConfig = (authProvider: string) => {
  const getFunction = () =>
    new CCQAuthClient()
      .getOidcConfigs(authProvider)
      .then((result: OidcConfigDTO) => {
        const authority: string = result.Authority ?? "";
        const clientId: string = result.ClientId ?? "";
        const clientSecret: string | undefined = result.ClientSecret;
        const scopes: string[] = result.Scopes ?? [];

        const config = {
          authority,
          clientId,
          clientSecret,
          scopes,
        };

        return config;
      });

  return getFunction;
};

export const getMicrosoftOidcConfig = getOidcConfig("Microsoft");

export const getGoogleOidcConfig = getOidcConfig("Google");
