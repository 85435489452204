import angular from "angular";
import { AuthService } from "../services/AuthService";
import { MeterService } from "../services/MeterService";
import { ReportService } from "../services/ReportService";
import { SearchService } from "../services/SearchService";
import { MeterDTO } from "@js/oldmodels/DTOs/MeterDTO.cs.d";
import { UsageReportDTO } from "@js/oldmodels/DTOs/UsageReportDTO.cs.d";

export class ReportEfficiencyController {
  selectedReport: UsageReportDTO;
  meters: MeterDTO[];
  selectedMeter: MeterDTO;
  fetchingMeters: boolean;

  fetchingReports: boolean;
  fetchingReport: boolean;

  //start: Date = new Date(Date.UTC(2017, 8, 1, 0, 0, 0, 0));
  start: Date = new Date(2017, 8, 1, 12, 0, 0, 0);

  //end: Date = new Date(Date.UTC(Date.today().getFullYear(), Date.today().getMonth(), Date.today().getDate(), 23, 59, 59));
  end: Date = new Date(
    Date.today().getFullYear(),
    Date.today().getMonth(),
    Date.today().getDate(),
    12,
    0,
    0,
  );

  granularity: string = "Month";

  canDrillUp: boolean = false;

  chartDataPoints: Date[];
  chartTimelineLabels: string[];
  chartData: number[][] = [];

  seriesNames: string[] = ["kWh (Off-Peak)", "kWh (Peak)"];
  seriesColours: string[] = ["#6C78C4", "#50CAE8"];
  seriesOptions: any[] = [
    {},
    {
      backgroundColor: ["#50CAE8", "#50CAE8"],
      borderColor: ["#50CAE8", "#50CAE8"],
    },
  ];

  lineChartOptions: any = {
    scales: {
      yAxes: [
        {
          id: "y-axis-1",
          type: "linear",
          display: true,
          position: "left",
          stacked: true,
          ticks: {
            beginAtZero: true,
          },
          scaleLabel: {
            display: true,
            labelString: "kWh",
          },
        },
      ],
      xAxes: [
        {
          stacked: true,
          fillOpacity: 1,
          scaleLabel: {
            display: true,
            labelString: "Time",
          },
        },
      ],
    },
  };

  static $inject = [
    "$rootScope",
    "AuthService",
    "MeterService",
    "ReportService",
    "SearchService",
  ];

  constructor(
    private $rootScope: ng.IRootScopeService,
    private $auth: AuthService,
    private $meters: MeterService,
    private $reports: ReportService,
    private $search: SearchService,
  ) {
    this.fetchingMeters = true;
    this.$meters
      .ListNew()
      .then((response) => {
        this.meters = response;
      })
      .finally(() => {
        this.fetchingMeters = false;
      });

    //this.start.setHours(0, 0, 0, 0);
    //this.end.setHours(23, 59, 59, 999);
  }

  updateLabels() {
    switch (this.granularity.toString()) {
      case "Month":
        this.lineChartOptions.scales.xAxes[0].scaleLabel.labelString = "Month";
        break;

      case "Day":
        this.lineChartOptions.scales.xAxes[0].scaleLabel.labelString = "Date";
        break;

      case "HalfHour":
      default:
        this.lineChartOptions.scales.xAxes[0].scaleLabel.labelString = "Time";
        break;
    }
  }

  clearReport(): void {
    delete this.selectedReport;
  }

  setGranularity(): void {
    const elapsedMS: number = this.end.getTime() - this.start.getTime();
    const elapsedDays: number = elapsedMS / 86400000;

    if (elapsedDays < 15) {
      this.granularity = "HalfHour";
    } else if (elapsedDays < 180) {
      this.granularity = "Day";
    } else {
      this.granularity = "Month";
    }
  }

  fetchReport(withDetail: boolean = false) {
    this.$rootScope.$broadcast("clearerrors", "reporting");

    if (!this.selectedMeter) {
      this.$rootScope.$broadcast("error", {
        page: "reporting",
        severity: "error",
        error: "Please select a meter.",
      });
      return;
    }

    this.fetchingReport = true;

    this.setGranularity();
    this.updateCanDrillUp();

    this.$reports
      .fetchUsageReport(
        [this.selectedMeter.Id],
        this.start,
        this.end,
        this.granularity,
        withDetail,
      )
      .then((response) => {
        this.selectedReport = response;
        this.updateLabels();
        this.chartData[0] = [];
        this.chartData[1] = [];
        this.chartTimelineLabels = [];
        this.seriesOptions[0] = {
          backgroundColor: [],
          borderColor: [],
        };
        this.seriesOptions[1] = {
          backgroundColor: [],
          borderColor: [],
        };

        angular.forEach(this.selectedReport.DataDay, (item, index) => {
          this.chartTimelineLabels.push(index);
          this.chartData[1].push(item);
          //this.seriesOptions[0].backgroundColor.push("#50CAE8");
          //this.seriesOptions[0].borderColor.push("#50CAE8");
        });

        angular.forEach(this.selectedReport.DataNight, (item, index) => {
          this.chartData[0].push(item);
          this.seriesOptions[0].backgroundColor.push("#50CAE8");
          this.seriesOptions[0].borderColor.push("#50CAE8");
        });

        this.fetchingReport = false;
      })
      .catch((response) => {
        this.$rootScope.$broadcast("error", {
          page: "reporting",
          severity: "error",
          error:
            "There was a problem fetching your report. Please try again later.",
        });
        this.fetchingReport = false;
      });
  }

  chartClick(points: any[], event: MouseEvent) {
    let scope: ng.IScope = angular.element(event.target as Element).scope();
    let ctrl: ReportEfficiencyController = (scope as any).ctrl;
    if (points && points.length > 0) {
      let chartLabel = ctrl.chartTimelineLabels[points[0]._index];

      let selectedDate: Date = new Date(
        ctrl.selectedReport.DataPoints[points[0]._index].toString(),
      );

      // Drill down:
      switch (ctrl.granularity.toString()) {
        case "Month":
          let month_date: Date = new Date(
            selectedDate.getFullYear(),
            selectedDate.getMonth(),
          );
          ctrl.start = month_date.clone();
          ctrl.end = month_date.clone().addMonths(1).addDays(-1);
          ctrl.fetchReport();

          break;

        case "Day":
          let day_date: Date = new Date(chartLabel);
          ctrl.start = new Date(
            selectedDate.getFullYear(),
            selectedDate.getMonth(),
            selectedDate.getDate(),
            12,
            0,
            0,
          );
          ctrl.end = new Date(
            selectedDate.getFullYear(),
            selectedDate.getMonth(),
            selectedDate.getDate(),
            12,
            0,
            0,
          );
          ctrl.fetchReport();

          break;
        default:
      }
    }
  }

  updateCanDrillUp(): void {
    const elapsedMS: number = this.end.getTime() - this.start.getTime();
    const elapsedDays: number = elapsedMS / 86400000;

    this.canDrillUp =
      elapsedDays < 367 &&
      !(
        this.start.getMonth() == 0 &&
        this.start.getDate() == 1 &&
        this.end.getMonth() == 11 &&
        this.end.getDate() == 31
      );
  }

  drillUp() {
    // Drill Up:
    switch (this.granularity.toString()) {
      case "Month":
        this.start = new Date(this.start.getFullYear(), 0, 1);
        this.end = new Date(this.start.getFullYear(), 11, 31);

        this.fetchReport();

        break;

      case "Day":
        this.granularity = "Month";
        //this.start = new Date(this.start.getFullYear(), this.start.getMonth(), 1);
        //this.end = this.start.addMonths(1).addDays(-1);
        this.start = new Date(this.start.getFullYear(), 0, 1);
        this.end = new Date(this.start.getFullYear(), 11, 31);

        this.fetchReport();

        break;
      default:
        this.granularity = "Day";
        this.start = new Date(
          this.start.getFullYear(),
          this.start.getMonth(),
          1,
        );
        this.end = this.start.clone().addMonths(1).addDays(-1);
        this.fetchReport();
        break;
    }
  }

  // #region Search

  search(needle: string): ng.IPromise<any[]> {
    if (!needle) return null;

    return this.$search.search(needle, true, false, false, false);
  }

  selectItem(item: any, type: string): void {
    this.selectedMeter = item as MeterDTO;
  }

  // #endregion Search

  setDates(range: string) {
    if (range === "thismonth") {
      this.start = new Date().moveToFirstDayOfMonth();
      this.end = new Date(
        Date.today().getFullYear(),
        Date.today().getMonth(),
        Date.today().getDate(),
        12,
        0,
        0,
      );
    } else if (range === "lastmonth") {
      this.start = new Date().moveToFirstDayOfMonth().addMonths(-1);
      this.end = new Date().moveToFirstDayOfMonth().addDays(-1);
    } else if (range === "last30days") {
      this.start = new Date().addDays(-30);
      this.end = new Date(
        Date.today().getFullYear(),
        Date.today().getMonth(),
        Date.today().getDate(),
        12,
        0,
        0,
      );
    } else if (range === "last6months") {
      this.start = new Date().moveToFirstDayOfMonth().addMonths(-6);
      this.end = new Date().moveToFirstDayOfMonth().addDays(-1);
    } else if (range === "thisyear") {
      this.start = new Date(new Date().getFullYear(), 0, 1);
      this.end = new Date(
        Date.today().getFullYear(),
        Date.today().getMonth(),
        Date.today().getDate(),
        12,
        0,
        0,
      );
    } else if (range === "lastyear") {
      this.start = new Date(new Date().getFullYear(), 0, 1).addYears(-1);
      this.end = new Date(this.start.getFullYear(), 11, 31);
    } else if (range === "last2years") {
      this.start = new Date(new Date().getFullYear(), 0, 1).addYears(-2);
      this.end = new Date(new Date().getFullYear(), 0, 1).addDays(-1);
    } else if (range === "last5years") {
      this.start = new Date(new Date().getFullYear(), 0, 1).addYears(-5);
      this.end = new Date(new Date().getFullYear(), 0, 1).addDays(-1);
    }

    // Set times to start of first day and end of last day.
    //this.start.setHours(0, 0, 0, 0);
    //this.end.setHours(23, 59, 59, 999);
  }
}
