import { AuthService } from "../services/AuthService";

export class AccountSettingsController {
  noMoreMails: boolean;

  currentPassword: string;
  newPassword: string;
  repeatPassword: string;

  newEmail: string;
  repeatEmail: string;

  passwordUpdated: boolean;
  emailUpdated: boolean;

  static $inject = ["$http", "AuthService"];

  constructor(
    private $http: ng.IHttpService,
    private $auth: AuthService,
  ) {}

  saveSettings() {
    if (
      this.newPassword &&
      this.repeatPassword &&
      this.newPassword === this.repeatPassword
    ) {
      this.$auth
        .changePassword(this.currentPassword, this.newPassword)
        .then((response) => {
          delete this.currentPassword;
          delete this.newPassword;
          delete this.repeatPassword;
          this.passwordUpdated = true;
        });
    }
    if (
      this.newEmail &&
      this.repeatEmail &&
      this.newEmail === this.repeatEmail
    ) {
      this.$auth.changeEmail(this.newEmail).then((response) => {
        delete this.newEmail;
        delete this.repeatEmail;
        this.emailUpdated = true;
      });
    }
  }
}
