export function registerRoutes() {
  angular
    .module("ccqapp")
    .config([
      "$routeProvider",
      function ($routeProvider) {
        $routeProvider
          .when("/", {
            page: "Login",
            controller: "LoginController",
            controllerAs: "ctrl",
            templateUrl: "views/login.html",
          })
          .when("/login", {
            page: "Login",
            controller: "LoginController",
            controllerAs: "ctrl",
            templateUrl: "views/login.html",
          })
          .when("/dashboard", {
            page: "Dashboard",
            section: "dashboard",
            controller: "DashboardController",
            controllerAs: "ctrl",
            templateUrl: "views/dashboard.html",
          })
          .when("/newuser/:email/:verificationCode", {
            page: "Account Verification",
            controller: "AccountVerificationController",
            controllerAs: "ctrl",
            templateUrl: "views/accountverification.html",
          })
          .when("/settings", {
            page: "Account Settings",
            controller: "AccountSettingsController",
            controllerAs: "ctrl",
            templateUrl: "views/accountsettings.html",
          })
          .when("/usermanagement", {
            page: "User Management",
            controller: "UserManagementController",
            controllerAs: "ctrl",
            templateUrl: "views/usermanagement.html",
          })
          .when("/import", {
            page: "Import",
            controller: "ImportMeterReadingsController",
            controllerAs: "ctrl",
            templateUrl: "views/importmeterreadings.html",
          })
          .when("/export", {
            page: "Export",
            controller: "ExportMeterReadingsController",
            controllerAs: "ctrl",
            templateUrl: "views/exportmeterreadings.html",
          })
          .when("/users", {
            page: "List Users",
            section: "admin",
            controller: "ListUsersController",
            controllerAs: "ctrl",
            templateUrl: "views/listusers.html",
          })
          .when("/rolesorclaims", {
            page: "Roles or Claims",
            section: "admin",
            templateUrl: "views/rolesorclaims.html",
          })
          .when("/agencies", {
            page: "List Agencies",
            section: "admin",
            controller: "ListAgenciesController",
            controllerAs: "ctrl",
            templateUrl: "views/listagencies.html",
          })
          .when("/agency", {
            page: "Agency Details",
            section: "portfolio",
            controller: "AgencyDetailController",
            controllerAs: "ctrl",
            templateUrl: "views/myagency.html",
          })
          .when("/meteragencyauthority", {
            page: "List Meter-Agency Authority",
            section: "portfolio",
            controller: "ListMeterAgencyAuthoritiesController",
            controllerAs: "ctrl",
            templateUrl: "views/listmeteragencyauthorities.html",
          })
          .when("/consultants", {
            page: "List Consultants",
            section: "portfolio",
            controller: "ListConsultantsController",
            controllerAs: "ctrl",
            templateUrl: "views/listconsultants.html",
          })
          .when("/customers", {
            page: "List Customers",
            section: "portfolio",
            controller: "ListCustomersController",
            controllerAs: "ctrl",
            templateUrl: "views/listcustomers.html",
          })
          .when("/customers/:customerId", {
            page: "List Customers",
            section: "portfolio",
            controller: "ListCustomersController",
            controllerAs: "ctrl",
            templateUrl: "views/listcustomers.html",
          })
          .when("/customers/new/:agencyId", {
            page: "List Customers",
            section: "portfolio",
            controller: "ListCustomersController",
            controllerAs: "ctrl",
            templateUrl: "views/listcustomers.html",
          })
          .when("/sites", {
            page: "List Sites",
            section: "portfolio",
            controller: "ListSitesController",
            controllerAs: "ctrl",
            templateUrl: "views/listsites.html",
          })
          .when("/sites/new/:customerId", {
            page: "List Sites",
            section: "portfolio",
            controller: "ListSitesController",
            controllerAs: "ctrl",
            templateUrl: "views/listsites.html",
          })
          .when("/sites/:siteId", {
            page: "List Sites",
            section: "portfolio",
            controller: "ListSitesController",
            controllerAs: "ctrl",
            templateUrl: "views/listsites.html",
          })
          .when("/meters", {
            page: "List Meters",
            section: "portfolio",
            controller: "ListMetersController",
            controllerAs: "ctrl",
            templateUrl: "views/listmeters.html",
          })
          .when("/meters/new/:siteId", {
            page: "List Meters",
            section: "portfolio",
            controller: "ListMetersController",
            controllerAs: "ctrl",
            templateUrl: "views/listmeters.html",
          })
          .when("/meters/:meterId", {
            page: "List Meters",
            section: "portfolio",
            controller: "ListMetersController",
            controllerAs: "ctrl",
            templateUrl: "views/listmeters.html",
          })
          .when("/meters/filtered/:meterFilter", {
            page: "List Meters",
            section: "portfolio",
            controller: "ListMetersController",
            controllerAs: "ctrl",
            templateUrl: "views/listmeters.html",
          })
          .when("/meterreading", {
            page: "Manual Meter Reading",
            section: "assurance",
            controller: "MeterReadingController",
            controllerAs: "ctrl",
            templateUrl: "views/meterreadings.html",
          })
          .when("/bills", {
            page: "List Bills",
            section: "assurance",
            controller: "ListBillsController",
            controllerAs: "ctrl",
            templateUrl: "views/listbills.html",
          })
          .when("/bills/:billId", {
            page: "List Bills",
            section: "assurance",
            controller: "ListBillsController",
            controllerAs: "ctrl",
            templateUrl: "views/listbills.html",
          })
          .when("/bills/add", {
            page: "Add Bill",
            section: "assurance",
            controller: "ListBillsController",
            controllerAs: "ctrl",
            templateUrl: "views/listbills.html",
          })
          .when("/contracts/offer", {
            page: "New Contract Offer",
            section: "contracts",
            controller: "NewContractOfferController",
            controllerAs: "ctrl",
            templateUrl: "views/newcontractoffer.html",
          })
          .when("/exports", {
            page: "List Exports",
            section: "import",
            controller: "ListExportsController",
            controllerAs: "ctrl",
            templateUrl: "views/listexports.html",
          })
          .when("/tenderrequests", {
            page: "Generate Tender Requests",
            section: "contracts",
            controller: "ListTenderRequestsController",
            controllerAs: "ctrl",
            templateUrl: "views/listtenderrequests.html",
          })
          .when("/tenderrequests/:meterId", {
            page: "Generate Tender Requests",
            section: "contracts",
            controller: "ListTenderRequestsController",
            controllerAs: "ctrl",
            templateUrl: "views/listtenderrequests.html",
          })
          .when("/reports/usage", {
            page: "Usage Report",
            section: "reports",
            controller: "ReportUsageController",
            controllerAs: "ctrl",
            templateUrl: "views/reportusage.html",
          })
          .when("/reports/carbon", {
            page: "Carbon Report",
            section: "reports",
            controller: "ReportCarbonController",
            controllerAs: "ctrl",
            templateUrl: "views/reportcarbon.html",
          })
          .when("/reports/efficiency", {
            page: "Efficiency Report",
            section: "reports",
            controller: "ReportEfficiencyController",
            controllerAs: "ctrl",
            templateUrl: "views/reportefficiency.html",
          })
          .when("/reports/comparison", {
            page: "Comparison Report",
            section: "reports",
            controller: "ReportComparisonController",
            controllerAs: "ctrl",
            templateUrl: "views/reportcomparison.html",
          })
          .when("/login-callback", {
            page: "Login Callback",
            templateUrl: "views/login-callback.html",
          })
          .when("/request-email", {
            page: "Request Email",
            templateUrl: "views/request-email.html",
          })
          .when("/otp-setup", {
            page: "OTP Setup",
            templateUrl: "views/otp-setup.html",
          })
          .when("/otp-setup/:welcomeId", {
            page: "OTP Setup",
            templateUrl: "views/otp-setup.html",
          })
          .when("/otp-verification", {
            page: "OTP Verification",
            templateUrl: "views/otp-verification.html",
          })
          .when("/otp-verification/:loginId", {
            page: "OTP Verification",
            templateUrl: "views/otp-verification.html",
          })
          .when("/sms-verification", {
            page: "SMS Verification",
            templateUrl: "views/sms-verification.html",
          })
          .when("/sms-verification/:loginId", {
            page: "SMS Verification",
            templateUrl: "views/sms-verification.html",
          })
          .otherwise({ redirectTo: "/" });
      },
    ])
    .run(function ($rootScope, $injector, $cookies, $location, $http) {
      var history = [];

      $rootScope.$on("$routeChangeSuccess", function () {
        if (history[history.length - 1] !== $location.$$path) {
          history.push($location.$$path);
        }
      });

      var goingBack = false;
      $rootScope.back = function () {
        if (!goingBack) {
          goingBack = true;
          var prevUrl = history.length > 1 ? history.splice(-2)[0] : "/";
          $location.path(prevUrl);
          goingBack = false;
        }
      };

      // listen for route changes.
      $rootScope.$on("$routeChangeStart", function (event, next, current) {
        //if ($location.$$path !== undefined && next.templateUrl !== "unsubscribe.html" && $location.$$path !== "/login") {
        //    if ($cookies.get('access_token') && $http) {
        //        $http.defaults.headers.common.Authorization = 'Bearer ' + $cookies.get('access_token');
        //    }
        //    if ($cookies.get('access_token') === null || $cookies.get('access_token') === undefined || new Date() > Date($cookies.get('access_token_expires'))) {
        //        $location.path("/login");
        //    }
        //}
      });
      //    if (next.templateUrl !== undefined) {
      //        // If the user is not logged in, or the token has expired, let's go back to login.
      //        if ($cookies.loggedUser === null || $cookies.loggedUser === undefined || $cookies.token === null) {
      //            if (next.templateUrl !== "views/login.html" && next.templateUrl !== "unsubscribe.html") {
      //                // redirect
      //                $location.path("/login");
      //            }
      //        }
      //        else if (new Date() > Date($cookies.tokenValidTo)) {
      //            if (next.templateUrl !== "views/login.html") {
      //                $location.path("/login");
      //            }
      //        }
      //    }
      //        // See if we're going to login when we don't need to.
      //    else {
      //        if (next.templateUrl === "views/login.html") {
      //            $location.path("/dashboard");
      //        }
      //    }
      //});
    });
}
