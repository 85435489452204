import { CCLDiscountRateDTO } from "@js/oldmodels/DTOs/CCLDiscountRateDTO.cs.d";
import { ContractBaseDTO } from "@js/oldmodels/DTOs/ContractBaseDTO.cs.d";
import { ContractChargeDTO } from "@js/oldmodels/DTOs/ContractChargeDTO.cs.d";
import { ContractDTO } from "@js/oldmodels/DTOs/ContractDTO.cs.d";
import { ContractOfferChargeDTO } from "@js/oldmodels/DTOs/ContractOfferChargeDTO.cs.d";
import { ContractOfferDTO } from "@js/oldmodels/DTOs/ContractOfferDTO.cs.d";
import { ContractOfferRateDTO } from "@js/oldmodels/DTOs/ContractOfferRateDTO.cs.d";
import { ContractRateDTO } from "@js/oldmodels/DTOs/ContractRateDTO.cs.d";
import { DataAggregatorDTO } from "@js/oldmodels/DTOs/DataAggregatorDTO.cs.d";
import { LegalEntityDTO } from "@js/oldmodels/DTOs/LegalEntityDTO.cs.d";
import { MeterDTO } from "@js/oldmodels/DTOs/MeterDTO.cs.d";
import { OperatorDTO } from "@js/oldmodels/DTOs/OperatorDTO.cs.d";
import { RateBaseDTO } from "@js/oldmodels/DTOs/RateBaseDTO.cs.d";
import { SiteDTO } from "@js/oldmodels/DTOs/SiteDTO.cs.d";
import { SupplierDTO } from "@js/oldmodels/DTOs/SupplierDTO.cs.d";
import { TenderDTO } from "@js/oldmodels/DTOs/TenderDTO.cs.d";
import { VATRateDTO } from "@js/oldmodels/DTOs/VATRateDTO.cs.d";
import { BillingUnitEnum } from "@js/oldmodels/Enums/BillingUnitEnum.cs.d";
import { ContractTypeEnum } from "@js/oldmodels/Enums/ContractTypeEnum.cs.d";
import { RateTypeEnum } from "@js/oldmodels/Enums/RateTypeEnum.cs.d";
import { RateUnitEnum } from "@js/oldmodels/Enums/RateUnitEnum.cs.d";
import { AuthService } from "../services/AuthService";
import { ContractOffersService } from "../services/ContractOffersService";
import { ContractService } from "../services/ContractService";
import { DataAggregatorService } from "../services/DataAggregatorService";
import { LegalEntityService } from "../services/LegalEntityService";
import { MeterService } from "../services/MeterService";
import { OperatorService } from "../services/OperatorService";
import { SiteService } from "../services/SiteService";
import { SupplierService } from "../services/SupplierService";
import { TenderService } from "../services/TenderService";

export class ListMetersController {
  private selectedSection: string;
  private selectedSubSection: ContractTypeEnum = ContractTypeEnum.Supplier;

  private meters: MeterDTO[];
  private selectedMeter: MeterDTO;

  private fetchingMeters: boolean;
  private fetchingMeter: boolean;

  private addingContract: boolean;

  private contracts: ContractDTO[];
  private contractsall: ContractDTO[];
  private currentContract: ContractDTO;
  private selectedContract: ContractDTO;

  private tenders: TenderDTO[];
  private selectedTender: TenderDTO;

  private selectedOffer: ContractOfferDTO;
  private selectingOffer: ContractOfferDTO;

  private fetchingContracts: boolean;
  private fetchingContract: boolean;

  private fetchingTenders: boolean;
  private fetchingTender: boolean;

  private fetchingSuppliers: boolean;
  private fetchingOperators: boolean;
  private fetchingDataAggregators: boolean;

  private matchingDatesContract: ContractDTO;

  sites: SiteDTO[];
  site: SiteDTO;
  fetchingSites: boolean;

  legalentities: LegalEntityDTO[];
  selectedLegalEntity: LegalEntityDTO;

  private meterform: ng.IFormController;
  private contractform: ng.IFormController;
  private contractofferform: ng.IFormController;
  private tenderform: ng.IFormController;

  private performingAction: boolean;

  private today: Date = new Date();

  private filter: string = "";

  private suppliers: SupplierDTO[];
  private dataaggregators: DataAggregatorDTO[];
  private operators: OperatorDTO[];

  private contractEditMode: boolean = false;
  private contractOfferEditMode: boolean = false;

  public static $inject = [
    "$rootScope",
    "$routeParams",
    "AuthService",
    "MeterService",
    "SiteService",
    "ContractService",
    "TenderService",
    "SupplierService",
    "OperatorService",
    "DataAggregatorService",
    "LegalEntityService",
    "ContractOffersService",
  ];

  constructor(
    private $rootScope: ng.IRootScopeService,
    private $routeParams: ng.route.IRouteParamsService,
    private $auth: AuthService,
    private $meterservice: MeterService,
    private $siteservice: SiteService,
    private $contractservice: ContractService,
    private $tenderservice: TenderService,
    private $supplierservice: SupplierService,
    private $operatorservice: OperatorService,
    private $dataAggregatorService: DataAggregatorService,
    private $legalentityservice: LegalEntityService,
    private $contractoffersservice: ContractOffersService,
  ) {
    this.selectedSubSection = ContractTypeEnum.Supplier;

    if ($routeParams.meterFilter) {
      this.filter = $routeParams.meterFilter;
    }

    this.fetchSuppliers();
    this.fetchDataAggregators();
    this.fetchOperators();

    this.$rootScope.$on(
      "selected-customer-updated",
      (event: ng.IAngularEvent) => {
        this.updateMeters();
        this.updateSites();
      },
    );

    this.updateMeters();
    this.updateSites();

    this.$legalentityservice.fetchAll().then((response) => {
      this.legalentities = response;
      this.legalentities.push({ Name: "---" } as LegalEntityDTO);
      this.legalentities.push({
        Name: "New Entity",
      } as LegalEntityDTO);
    });
  }

  private updateSites() {
    this.fetchingSites = true;
    this.$siteservice
      .ListNew()
      .then((response) => {
        this.sites = response;
        this.preSelectedItem();

        // add catch
      })
      .catch((error) => {
        console.error("An error occurred while fetching sites:", error);
      })
      .finally(() => {
        this.fetchingSites = false;
      });
  }

  private updateMeters() {
    this.fetchingMeters = true;
    if ((this.$rootScope as any).selectedCustomer) {
      // Fetch meters for a single company.
      this.fetchingMeters = true;
      this.$meterservice
        .fetchMetersForCustomer(
          (this.$rootScope as any).selectedCustomer.Id,
          this.filter,
        )
        .then((response) => {
          this.meters = response;
          this.preSelectedItem();
        })
        .finally(() => {
          this.fetchingMeters = false;
        });
    } else {
      // Fetch all meters available to the user.
      this.$meterservice
        .ListNew()
        .then((response) => {
          this.meters = response;
          this.preSelectedItem();
        })
        .finally(() => {
          this.fetchingMeters = false;
        });
    }
  }

  private preSelectedItem() {
    if (this.meters && this.$routeParams.meterId) {
      let selectedMeter = this.meters.find((value, index) => {
        return value.Id === parseInt(this.$routeParams.meterId);
      });

      this.selectMeter(selectedMeter);
    }

    if (this.sites && this.$routeParams.siteId) {
      let selectedSite = this.sites.find((value, index) => {
        return value.Id === parseInt(this.$routeParams.siteId);
      });
      if (selectedSite) {
        this.selectedMeter = {
          AssociatedSiteId: selectedSite.Id,
          AssociatedSiteName: selectedSite.Name,
          AssociatedCustomerId: selectedSite.CustomerId,
          AssociatedCustomerName: selectedSite.CustomerName,
        } as MeterDTO;
        this.selectedSection = "details";
      }
    }
  }

  private matchSuppliers(list: ContractBaseDTO[]) {
    if (this.suppliers && list) {
      list.forEach((contract, index) => {
        contract.AssociatedSupplier = this.suppliers.find((value, index) => {
          return value.Id === contract.AssociatedSupplierId;
        });
      });
    }
  }
  private matchOperators(list: ContractDTO[]) {
    if (this.operators && list) {
      list.forEach((contract, index) => {
        contract.AssociatedOperator = this.operators.find((value, index) => {
          return value.Id === contract.AssociatedOperatorId;
        });
      });
    }
  }
  private matchDataAggregator(list: ContractDTO[]) {
    if (this.operators && list) {
      list.forEach((contract, index) => {
        contract.AssociatedDataAggregator = this.dataaggregators.find(
          (value, index) => {
            return value.Id === contract.AssociatedDataAggregatorId;
          },
        );
      });
    }
  }

  private filtercontracts() {
    this.contracts = this.contractsall.filter(
      (x) => x.ContractType === this.selectedSubSection,
    );
  }
  private fetchContracts() {
    this.fetchingContracts = true;
    this.$contractservice
      .fetchContractsForMeter(this.selectedMeter.Id)
      .then((response) => {
        this.contractsall = response;
        this.currentContract = this.contractsall.find((value, index) => {
          return value.DateFrom < this.today && value.DateTo > this.today;
        });
        this.matchSuppliers(this.contractsall);
        //deafalt to first tab for contracts
        this.DefaultForContractsTab();
        this.fetchingContracts = false;
      })
      .catch((response) => {
        this.fetchingContracts = false;
      });
  }

  private DefaultForContractsTab() {
    this.filtercontracts();
  }

  private fetchTenders() {
    this.fetchingTenders = true;
    this.$tenderservice
      .fetchTendersForMeter(this.selectedMeter.Id)
      .then((response) => {
        this.tenders = response;
        this.tenders.forEach((tender, index) => {
          this.matchSuppliers(tender.Offers);
        });
        this.fetchingTenders = false;
      })
      .catch((response) => {
        this.fetchingTenders = false;
      });
  }

  private fetchSuppliers() {
    this.fetchingSuppliers = true;
    this.$supplierservice
      .fetchAll()
      .then((response) => {
        this.suppliers = response;
        this.matchSuppliers(this.contractsall);
        this.fetchingSuppliers = false;
      })
      .catch((response) => {
        this.fetchingSuppliers = false;
      });
  }
  private fetchOperators() {
    this.fetchingOperators = true;
    this.$operatorservice
      .fetchAll()
      .then((response) => {
        this.operators = response;
        this.matchOperators(this.contractsall);
        this.fetchingOperators = false;
      })
      .catch((response) => {
        this.fetchingOperators = false;
      });
  }
  private fetchDataAggregators() {
    this.fetchingDataAggregators = true;
    this.$dataAggregatorService
      .fetchAll()
      .then((response) => {
        this.dataaggregators = response;
        this.matchDataAggregator(this.contractsall);
        this.fetchingDataAggregators = false;
      })
      .catch((response) => {
        this.fetchingDataAggregators = false;
      });
  }

  private selectMeter(meter: MeterDTO) {
    this.selectedMeter = meter;
    this.fetchContracts();

    this.fetchTenders();

    this.selectedSection = "details";
  }

  private createMeter() {
    this.selectedSection = "details";
    this.selectedMeter = {} as MeterDTO;
  }

  cancel() {
    if (!this.selectedMeter.Id) {
      delete this.selectedMeter;
      this.meterform.$setPristine();
      return;
    }

    this.fetchingMeter = true;
    this.$meterservice.fetchNew(this.selectedMeter.Id).then((response) => {
      this.selectedMeter = response;
      this.fetchingMeter = false;
      this.meterform.$setPristine();
      this.meters.filter((meter) => meter.Id === response.Id)[0].Name =
        response.Name; //reset name in the list also incase renamed on screen then cancel
    });
  }

  private save() {
    this.$meterservice
      .addUpdatereturnonlyid(this.selectedMeter)
      .then((response) => {
        this.selectedMeter.Id = response;
        if (!this.meters) {
          this.meters = [];
        }

        let matches: MeterDTO[] = this.meters.filter((value, index) => {
          return value.Id == response;
        });

        if (!matches || matches.length === 0) {
          this.meters.push(this.selectedMeter);
        }

        this.meterform.$setPristine();
      });
  }

  private delete() {
    this.$meterservice.markasdeleted(this.selectedMeter.Id).then((response) => {
      if (response) {
        this.meters.splice(this.meters.indexOf(this.selectedMeter), 1);
        this.meterform.$setPristine();
        delete this.selectedMeter;
      }
    });
  }

  // #region Contracts

  private addContract() {
    delete this.matchingDatesContract;

    this.selectedContract = {
      //AssociatedMeter: this.selectedMeter,
      AssociatedMeterId: this.selectedMeter.Id,
      MeterUtilityType: this.selectedMeter.MeterUtilityType,
      ContractType: this.selectedSubSection,
      Rates: [{} as RateBaseDTO],
    } as ContractDTO;

    this.selectedContract.StandingChargeUnit = BillingUnitEnum.Pounds_per_Month;

    this.contractEditMode = true;
  }

  private removeContract(contract: ContractDTO) {
    this.$contractservice.markasdeleted(contract.Id).then((response) => {
      this.contractsall.splice(
        this.contractsall.indexOf(this.selectedContract),
        1,
      );
      this.contracts.splice(this.contracts.indexOf(this.selectedContract), 1);

      delete this.selectedContract;
    });
  }

  private cancelUpdateContract() {
    delete this.selectedContract;
    delete this.matchingDatesContract;
    this.fetchContracts();
  }

  private updateContract(contract: ContractDTO) {
    this.$contractservice
      .addUpdateReturnByonlyId(contract)
      .then((response) => {
        this.fetchContracts();
        delete this.selectedContract;
      })
      .catch((response) => {
        //delete this.selectedContract;
      });
    delete this.matchingDatesContract;
    // Is this best?
    this.meterform.$setPristine();
  }

  private validateContractDates(contract: ContractDTO) {
    delete this.matchingDatesContract;

    // Only send if both dates are filled in (probably not strictly necessary, but will cut down on roundtrips).
    if (!contract.DateFrom || !contract.DateTo) {
      return;
    }

    this.$contractservice
      .validateContractDates(contract)
      .then((response) => {
        this.matchingDatesContract = response;
      })
      .catch((response) => {
        // Do something?
      });
  }

  // #endregion

  // #region Rates

  private addRate() {
    if (
      !this.selectedContract.Rates ||
      this.selectedContract.Rates.length < 1
    ) {
      this.selectedContract.Rates = [];
    }

    let newRate: ContractRateDTO = {} as ContractRateDTO;
    newRate.RateType = RateTypeEnum.Day;
    newRate.RateChargePerKWHUnit = RateUnitEnum.Pence;
    newRate.RateFromTime = new Date(new Date().setHours(0, 0, 0, 0));
    newRate.RateToTime = new Date(new Date().setHours(0, 0, 0, 0));

    this.selectedContract.Rates.push(newRate);
  }

  private removeRate(rate: ContractRateDTO) {
    this.selectedContract.Rates.splice(
      this.selectedContract.Rates.indexOf(rate),
      1,
    );
  }

  // #endregion

  // #region Charges

  private addCharge(kva: boolean = false) {
    if (!this.selectedContract) {
      return;
    }

    if (!this.selectedContract.Charges) {
      this.selectedContract.Charges = [];
    }
    let newCharge: ContractChargeDTO = {
      ChargeType: "Normal" as any,
    } as ContractChargeDTO;

    if (kva) {
      newCharge.ChargeType = "KVA" as any;
      newCharge.KVAAllowance = this.selectedMeter.DefaultKVA;
    }
    (newCharge.Unit as any) = "Pounds_per_Month";

    this.selectedContract.Charges.push(newCharge);
  }

  private removeCharge(charge: ContractChargeDTO) {
    this.selectedContract.Charges.splice(
      this.selectedContract.Charges.indexOf(charge),
      1,
    );
  }

  // #endregion

  // #region Tenders
  addTender() {
    this.selectedTender = {
      MeterId: this.selectedMeter.Id,
    } as TenderDTO;
  }

  removeTender(tender: TenderDTO) {
    this.$tenderservice.markasdeleted(tender.Id).then((response) => {
      this.tenders.splice(this.tenders.indexOf(this.selectedTender), 1);
      delete this.selectedTender;
    });
  }

  cancelUpdateTender() {
    delete this.selectedTender;
  }

  updateTender(tender: TenderDTO) {
    this.$tenderservice
      .addUpdate(tender)
      .then((response) => {
        if (this.tenders.indexOf(tender) === -1) {
          this.tenders.push(tender);
        }
        delete this.selectedTender;
      })
      .catch((response) => {
        //delete this.selectedTender;
      });
  }

  canAcceptOffer(tender: TenderDTO): boolean {
    let acceptedoffer = tender.Offers.find((offer, index) => {
      return offer.IsAccepted;
    });

    return !acceptedoffer;
  }

  // #endregion

  // #region Offers

  private addOffer(tender: TenderDTO) {
    this.selectedOffer = {
      TenderId: tender.Id,
      TenderMeterId: tender.MeterId,
      OfferUtilityType: this.selectedMeter.MeterUtilityType,
      Rates: [{} as RateBaseDTO],
      DateTo: new Date(),
    } as ContractOfferDTO;
    this.contractOfferEditMode = true;
  }

  private cancelUpdateOffer() {
    delete this.selectedOffer;
  }

  private updateOffer(offer: ContractOfferDTO) {
    this.$contractoffersservice
      .addUpdate(offer)
      .then((response) => {
        this.fetchTenders();
        delete this.selectedOffer;
      })
      .catch((response) => {
        //delete this.selectedOffer;
      });
  }

  // #region Rates

  private addOfferRate() {
    if (!this.selectedOffer.Rates) {
      this.selectedOffer.Rates = [];
    }

    this.selectedOffer.Rates.push({} as ContractOfferRateDTO);
  }

  private removeOfferRate(rate: ContractOfferRateDTO) {
    this.selectedOffer.Rates.splice(this.selectedOffer.Rates.indexOf(rate), 1);
  }

  // #endregion

  // #region Charges

  private addOfferCharge(kva: boolean = false) {
    if (!this.selectedOffer.Charges) {
      this.selectedOffer.Charges = [];
    }
    let newCharge: ContractOfferChargeDTO = {
      ChargeType: "Normal" as any,
    } as ContractOfferChargeDTO;

    if (kva) {
      newCharge.ChargeType = "KVA" as any;
    }

    this.selectedOffer.Charges.push(newCharge);
  }

  private removeOfferCharge(charge: ContractOfferChargeDTO) {
    this.selectedOffer.Charges.splice(
      this.selectedOffer.Charges.indexOf(charge),
      1,
    );
  }

  // #endregion

  private acceptOffer(offer: ContractOfferDTO) {
    delete this.selectingOffer;
    this.performingAction = true;
    this.$contractoffersservice
      .acceptOffer(offer.Id)
      .then((response) => {
        // update tenders.
        this.fetchContracts();
        this.fetchTenders();
      })
      .finally(() => {
        this.performingAction = false;
      });
  }

  private declineOffer(offer: ContractOfferDTO) {
    delete this.selectingOffer;
    this.performingAction = true;
    this.$contractoffersservice
      .declineOffer(offer.Id)
      .then((response) => {
        offer.IsRejected = true;
      })
      .finally(() => {
        this.performingAction = false;
      });
  }

  private deleteOffer(offer: ContractOfferDTO) {
    delete this.selectingOffer;
    this.performingAction = true;
    this.$contractoffersservice
      .markasdeleted(offer.Id)
      .then((response) => {
        offer.IsRejected = true;
      })
      .finally(() => {
        this.performingAction = false;
      });
  }

  private makeliveOffer(offer: ContractOfferDTO, commencementDate: Date) {
    delete this.selectingOffer;
    this.performingAction = true;
    this.$contractoffersservice
      .makeliveOffer(offer.Id, commencementDate)
      .then((response) => {
        offer.IsLive = true;
        this.fetchContracts();
      })
      .finally(() => {
        this.performingAction = false;
      });
  }

  // #endregion

  // #region VAT and CCL Discounts

  private addVATRate() {
    if (
      !this.selectedMeter.VATRates ||
      this.selectedMeter.VATRates.length < 1
    ) {
      this.selectedMeter.VATRates = [];
    }

    var tmprate: VATRateDTO = {} as VATRateDTO;
    //tmprate.DateFrom = new Date((new Date()).setHours(0, 0, 0, 0));
    //tmprate.DateTo = new Date((new Date()).setHours(0, 0, 0, 0));

    this.selectedMeter.VATRates.push(tmprate);
    // this.selectedContract.Rates.push({ } as ContractRateDTO);
  }

  private removeVATRate(rate: VATRateDTO) {
    this.selectedMeter.VATRates.splice(
      this.selectedMeter.VATRates.indexOf(rate),
      1,
    );
  }

  private addCCLDiscountRate() {
    if (
      !this.selectedMeter.CCLDiscountRates ||
      this.selectedMeter.CCLDiscountRates.length < 1
    ) {
      this.selectedMeter.CCLDiscountRates = [];
    }

    var tmprate: CCLDiscountRateDTO = {} as CCLDiscountRateDTO;
    //tmprate.DateFrom = new Date((new Date()).setHours(0, 0, 0, 0));
    //tmprate.DateTo = new Date((new Date()).setHours(0, 0, 0, 0));

    this.selectedMeter.CCLDiscountRates.push(tmprate);
    // this.selectedContract.Rates.push({ } as ContractRateDTO);
  }

  private removeCCLDiscountRate(rate: CCLDiscountRateDTO) {
    this.selectedMeter.CCLDiscountRates.splice(
      this.selectedMeter.CCLDiscountRates.indexOf(rate),
      1,
    );
  }

  // check to see if the associated site has warnings enabled
  checkIfSiteHasWarningsEnabled(id: number) {
    this.site = this.sites.find((s) => s.Id == id);

    return this.site.ContractWarningsEnabled;
  }

  private castDatetoToNewDate = (offer) => {
    offer.DateFrom = new Date(offer.DateFrom);
    offer.DateTo = new Date(offer.DateTo);
    this.selectedOffer = offer;
  };

  // #endregion
}
