import { Button, Stack, TextField, Typography } from "@mui/material";
import { AuthErrorDetails, CCQOTPClient } from "@react/apis/ModularCCQClient";
import { FC, useState } from "react";
import { useIsAuthenticated, useSignIn } from "react-auth-kit";
import {
  BrowserRouter,
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";
import AppRoutes from "../AppRoutes";
import { ErrorMessage } from "../components/ErrorMessage";

const OtpVerificationPageInternal: FC = () => {
  const isAuthenticated = useIsAuthenticated();
  console.log(isAuthenticated());
  const { loginId } = useParams();
  const [otp, setOtp] = useState("");
  const signIn = useSignIn();
  const [error, setError] = useState<Partial<AuthErrorDetails>>();

  const navigate = useNavigate();

  const requestToken = async () => {
    setError(undefined);
    const client = new CCQOTPClient();
    try {
      const authInfo = await client.getTokenFromOTP(otp, Number(loginId));
      const { Token, ExpiresIn, ApplicationUser } = authInfo;
      console.log(Token);
      if (!Token) return;
      signIn({
        token: Token,
        expiresIn: ExpiresIn,
        tokenType: "Bearer",
        authState: ApplicationUser, // Get actual user info from backend
      });

      navigate(AppRoutes.Dashboard);

      console.log("Success", Token);
    } catch (e: unknown) {
      console.log(e);
      setError(e as Partial<AuthErrorDetails>);
    }
  };

  if (!loginId)
    return (
      <Stack
        padding={20}
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <Typography>Please provide a login id</Typography>
      </Stack>
    );
  return (
    <Stack
      padding={20}
      spacing={3}
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <Typography variant="h4">Please enter a TOTP code.</Typography>
      <Stack spacing={3}>
        <TextField
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          label="Enter OTP From Authenticator"
        />
        <Button
          onClick={() => requestToken()}
          disabled={!otp}
          variant="contained"
        >
          Login
        </Button>
      </Stack>

      <Stack spacing={3} alignItems="center">
        <Typography variant="h4">or</Typography>
        <Button
          onClick={() => navigate(`${AppRoutes.SMSVerification}/${loginId}`)}
          variant="contained"
        >
          Use SMS Verification
        </Button>
      </Stack>
      {error && <ErrorMessage error={error} />}
    </Stack>
  );
};

const OtpVerificationPage: FC = () => (
  <BrowserRouter>
    <Routes>
      <Route
        path="/otp-verification/:loginId"
        element={<OtpVerificationPageInternal />}
      />
    </Routes>
  </BrowserRouter>
);

export default OtpVerificationPage;
